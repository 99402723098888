import { GstStateCodes } from 'constants/layout'
import { get, post } from 'helpers/api_helper'
import { INVOICE_CONFIG } from 'helpers/url_helper'
import { useEffect, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Label,
    Row
} from "reactstrap"

const InvoiceSettings = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(0);

    useEffect(() => {
        getInvoice();
    },[])

    const getInvoice = async() => {
        setLoading(1)
        setData(null)
        const res = await get(INVOICE_CONFIG);
        if(res.status === 200){
            setData(res?.response);
        }else{
            setLoading(0)
        }
    }

    const updateInvoiceCall = async() => {
        setLoading(2)
        const res = await post(INVOICE_CONFIG, data);
        if(res.status == 200){
            setLoading(0)
        }
    }

    return (
        <Col>
            <Card>
                <CardBody>
                    <h4>Invoice Settings</h4>

                    <Row>
                        <Col>
                            <Label>Logo</Label>
                            <input className="form-control" type="file" />
                        </Col>
                        <Col>
                            <Label>Company Name</Label>
                            <input className="form-control" type="text" defaultValue={data?.title} onChange={e => {
                                setData(prev => ({...prev, title: e.target.value}))
                            }} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Label>State</Label>
                            <select className="form-control" value={data?.gst_state_code || ""} onChange={e => {
                                var selectedOption = e.target.options[e.target.selectedIndex];
                                setData(prev => ({...prev, state:selectedOption.text, gst_state_code: e.target.value}))
                            }} >
                                {
                                    GstStateCodes.map((v, i) => <option value={v.code} key={`GST_SC_${i+1}`} >{v.state}</option>)
                                }
                            </select>
                            <span>{data?.state?.toUpperCase()} GST State Code: {data?.gst_state_code} </span>
                        </Col>
                        <Col>
                            <Label>GST Number</Label>
                            <input className="form-control" type="text" defaultValue={data?.gst} onChange={e => {
                                setData(prev => ({...prev, gst: e.target.value}))
                            }} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Label>Contact</Label>
                            <input className="form-control" type="number" defaultValue={data?.tel} onChange={e => {
                                setData(prev => ({...prev, tel: e.target.value}))
                            }} />
                        </Col>
                        <Col>
                            <Label>Email</Label>
                            <input className="form-control" type="email" defaultValue={data?.email} onChange={e => {
                                setData(prev => ({...prev, email: e.target.value}))
                            }} />
                        </Col>
                        <Col>
                            <Label>Website</Label>
                            <input className="form-control" type="text" defaultValue={data?.website} onChange={e => {
                                setData(prev => ({...prev, website: e.target.value}))
                            }} />
                        </Col>
                    </Row>

                    <h5 className="mt-4">Back Details</h5>
                    <Row className="mt-2">
                        <Col>
                            <Label>Bank Name</Label>
                            <input className="form-control" type="text" defaultValue={data?.bank?.bank} onChange={e => {
                                setData(prev => ({...prev, bank: ({...prev.bank, bank:e.target.value})}))
                            }} />
                        </Col>
                        <Col>
                            <Label>A/C Number</Label>
                            <input className="form-control" type="text" defaultValue={data?.bank?.account} onChange={e => {
                                setData(prev => ({...prev, bank: ({...prev.bank, account:e.target.value})}))
                            }} />
                        </Col>
                    </Row>
                    <Row className="mt-2">
                        <Col>
                            <Label>IFSC Code</Label>
                            <input className="form-control" type="text" defaultValue={data?.bank?.ifscode} onChange={e => {
                                setData(prev => ({...prev, bank: ({...prev.bank, ifscode:e.target.value})}))
                            }} />
                        </Col>
                        <Col>
                            <Label>Branch</Label>
                            <input className="form-control" type="text" defaultValue={data?.bank?.branch} onChange={e => {
                                setData(prev => ({...prev, bank: ({...prev.bank, branch:e.target.value})}))
                            }} />
                        </Col>
                    </Row>
                    
                    <Row>
                        <Col>
                            <button disabled={loading == 2} onClick={() => updateInvoiceCall()} style={{float: 'right'}} className="btn btn-primary mt-2">Update 
                            {
                                loading == 2 && <i title="loading" className="ms-1 fas fa-spinner fa-spin"/>
                            }
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default InvoiceSettings;