import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Card,
    CardBody,
    Container,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
} from "reactstrap"

import classnames from "classnames"
import Breadcrumbs from "components/Common/Breadcrumb"
import NotificationSettings from "./components/Notification"
import EmailSettings from "./components/email"
import SmsSettings from "./components/sms"
import WPSettings from './components/whatsapp'

const Notifications = () => {
    const [customIconActiveTab, setcustomIconActiveTab] = useState("1")
    
    const toggleIconCustom = tab => {
        if (customIconActiveTab !== tab) {
            setcustomIconActiveTab(tab)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Settings | Notifications</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Settings"
                        breadcrumbItem="Notifications"
                    />

                    <Card>
                        <CardBody>

                        <Nav className="icon-tab nav-justified">
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customIconActiveTab === "1",
                                    })}
                                    onClick={() => toggleIconCustom("1")}
                                >
                                    <span className="d-none d-sm-block"><i className="fas fa-envelope"></i> Email</span>
                                    <span className="d-block d-sm-none"><i className="fas fa-envelope"></i></span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customIconActiveTab === "2",
                                    })}
                                    onClick={() => toggleIconCustom("2")}
                                >
                                    <span className="d-none d-sm-block"><i className="fas fa-comments"></i> SMS</span>
                                    <span className="d-block d-sm-none"><i className="fas fa-comments"></i></span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customIconActiveTab === "3",
                                    })}
                                    onClick={() => toggleIconCustom("3")}
                                >
                                    <span className="d-none d-sm-block"><i className="fab fa-whatsapp"></i> Whatsapp</span>
                                    <span className="d-block d-sm-none"><i className="fab fa-whatsapp"></i></span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    style={{ cursor: "pointer" }}
                                    className={classnames({
                                        active: customIconActiveTab === "4",
                                    })}
                                    onClick={() => toggleIconCustom("4")}
                                >
                                    <span className="d-none d-sm-block"><i className="bx bx-cog"></i> Notification Settings</span>
                                    <span className="d-block d-sm-none"><i className="bx bx-cog"></i></span>
                                </NavLink>
                            </NavItem>
                        </Nav>

                        <TabContent
                            activeTab={customIconActiveTab}
                            className="p-3 text-muted"
                        >
                            <TabPane tabId="1">
                                <EmailSettings />
                            </TabPane>
                            <TabPane tabId="2">
                                <SmsSettings />
                            </TabPane>
                            <TabPane tabId="3">
                                <WPSettings />
                            </TabPane>
                            <TabPane tabId="4">
                                <NotificationSettings />
                            </TabPane>
                        </TabContent>
                        </CardBody>
                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Notifications