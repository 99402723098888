import toastr from "toastr";
import "toastr/build/toastr.min.css";

const Toaster = (toastType = '', data = null) => {
    let positionClass = "toast-top-right";
    toastr.options = {
        positionClass: positionClass,
        timeOut: 500000,
        extendedTimeOut: 10000,
        closeButton: false,
        debug: false,
        progressBar: false,
        preventDuplicates: true,
        newestOnTop: true,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut',
        showDuration: 300,
        hideDuration: 1000
      };
    
      if (toastType === "info") toastr.info(data?.message || '', data?.title);
      else if (toastType === "warning") toastr.warning(data?.message || '', data?.title);
      else if (toastType === "error") toastr.error(data?.message || '', data?.title);
      else toastr.success(data?.message || '', data?.title);
}

export default Toaster;