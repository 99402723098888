import PropTypes from 'prop-types'
import React, { useEffect } from "react"

import { connect } from "react-redux"
import { BrowserRouter as Router, Switch } from "react-router-dom"

// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import { Route } from "react-router-dom"
import { decryptData } from 'services/encription_service'
import NonAuthLayout from "./components/NonAuthLayout"
import VerticalLayout from "./components/VerticalLayout/"
// Import scss
import "./assets/scss/theme.scss"
import Pages404 from './Pages404'

const App = () => {

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  useEffect(async() => {
    
    const filteredData = {};
    
    if(localStorage.getItem('userdetails')){
      const modules = await decryptData(localStorage.getItem('userdetails'))
      
      let menus = {};
      if(modules){
        menus = JSON.parse(JSON.parse(modules))
      }
      for (const key in menus) {
          if (menus.hasOwnProperty(key)) {
              filteredData[key] = Object.fromEntries(
                Object.entries(menus[key]).filter(([subKey, value]) => Object.values(value).includes(true))
              );
          }
      }
    }
  },[])

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={route?.layout ? VerticalLayout : NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
          <Route component={Pages404} />
        </Switch>
      </Router>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any
}

const mapStateToProps = state => {
  return {
    layout: state.Layout,
  }
}

export default connect(mapStateToProps, null)(App)
