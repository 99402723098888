import { useRef } from 'react';
import {
    Modal
} from "reactstrap";


const OTModal = ({isOpen = false, clients = null, isClose = () => {}, onChange = null, onClick}) => {
    const searchRef = useRef(null);

    return (
        <Modal
            size="lg"
            isOpen={isOpen}
            toggle={() => {
                isClose(!isOpen);
                document.body.classList.add("no_padding");
            }}
            centered={true}
        >
            <div className="modal-header">
                <h5 className="modal-title mt-0" > Select Client  </h5>
                <button
                    onClick={() => setClientModelOpen(false)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                >
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div className="modal-body">
                <input ref={searchRef} type="text" className="form-control mb-2" placeholder="search by client name" onChange={onChange} />
                <div className="col-xs-12" style={{height: '350px', overflowY: 'scroll'}}>
                    <table className="table table-hover table-borderd" id="table">
                        <tbody>
                            {
                                clients?.map((v, i) => 
                                    <tr title={`Create Order for ${v.clientname} - ${v.address}`} key={`OCLD_${i}`} onClick={() => onClick(v.code)}>
                                        <td>{v.clientname} {" "} ({v.code}) <i className="fas fa-info-circle"></i></td>
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={() => isClose} >Close</button>
            </div>
        </Modal>
    )
}

export default OTModal;