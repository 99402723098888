import React, { useEffect, useState } from "react"
import {
    Row,
    Col,
    Card,
    CardBody,
    Label
} from "reactstrap"
import Subcription from './subcription'
import { get, post } from 'helpers/api_helper'
import { PROFILE } from 'helpers/url_helper'

const Profile = () => {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(0);

    useEffect(() => {
        getProfile();
    },[])

    const getProfile = async() => {
        setLoading(1)
        setData(null)
        const res = await get(PROFILE);
        if(res.status === 200){
            setData(res?.response);
        }else{
            setLoading(0)
        }
    }

    const updateProfileCall = async() => {
        setLoading(2)
        const res = await post(PROFILE, data);
        if(res.status == 200){
            setLoading(0)
        }
    }

    return (
        <Col>
            <Card>
                <CardBody>
                    <h4>
                        Profile
                        {
                            loading == 1 && !data ? 
                            <i title="loading" className="ms-2 fas fa-spinner fa-spin" style={{fontSize: '18px'}} /> :
                            <i onClick={() => getProfile()} title="reload data" role="button" className="ms-2 mdi mdi-refresh-circle" style={{fontSize: '18px'}} />
                        }
                    </h4>

                    <Row>
                        <Col>
                            <Label>Company Name</Label>
                            <input className="form-control" type="text" defaultValue={data?.title} onChange={(e) => {
                                setData(prev => ({...prev, title: e.target.value}))
                            }}/>
                        </Col>
                        <Col>
                            <Label>Logo</Label>
                            <input className="form-control" type="file" />
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Label>Company Email</Label>
                            <input className="form-control" type="email" defaultValue={data?.email} onChange={(e) => {
                                setData(prev => ({...prev, email: e.target.value}))
                            }}/>
                        </Col>
                        <Col>
                            <Label>Company Mobile</Label>
                            <input className="form-control" type="number" defaultValue={data?.mobile} onChange={(e) => {
                                setData(prev => ({...prev, mobile: e.target.value}))
                            }}/>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <Label>Company Address</Label>
                            <textarea className="form-control" type="text" defaultValue={data?.address} onChange={(e) => {
                                setData(prev => ({...prev, address: e.target.value}))
                            }}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <button disabled={loading == 2} onClick={() => updateProfileCall()} style={{float: 'right'}} className="btn btn-primary mt-2">Update 
                            {
                                loading == 2 && <i title="loading" className="ms-1 fas fa-spinner fa-spin"/>
                            }
                            </button>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Subcription />
        </Col>
    )
}

export default Profile;