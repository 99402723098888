import { get } from 'helpers/api_helper'
import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"


const MiniWidget = () => {
  const [data, setData] = useState(false)
  
  useEffect(async() => {
    setData(false)
    const res = await get(`api/org/gettotals`)
    if(res && res?.status == 200){
      setData(res.response)
    }
  },[])

  if(data){
    return '';
  }

  return (
    <React.Fragment>
      <Row>
        {data && data.map((report, key) => (
          <Col sm="3" key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-items-center mb-3">
                  <div className="avatar-xs me-3">
                    <span className="avatar-title rounded-circle bg-primary bg-soft text-primary font-size-18">
                      <i className={report.icon} />
                    </span>
                  </div>
                  <h5 className="font-size-14 mb-0">{report.title}</h5>
                </div>
                <div className="text-muted mt-4">
                  <h4>
                    {report.value}{" "}
                    <i className="mdi mdi-chevron-up ms-1 text-success" />
                  </h4>
                  <div className="d-flex">
                    <span className="me-1 text-truncate">{report.desc}</span>
                    {" "}
                    <span
                      className={
                        "badge badge-soft-" + report.color + " font-size-12"
                      }
                    >
                      {report.badgeValue}{" "}
                    </span>{" "}
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>
    </React.Fragment>
  )
}

export default MiniWidget
