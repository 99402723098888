import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line, Pie } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


export function RenderdChart({charttype = "Line", data, addoptions = false }) {
  let options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      }
    },
  };

  if(addoptions){
    options = addoptions;
  }

  switch(charttype){
    case 'pie':
      return <Pie options={options} data={data} />;
    default: 
      return <Line options={options} data={data} height={96}/>;
  }
}
