import { post } from 'helpers/api_helper';
import { useState } from "react";
import { Modal } from "reactstrap";

const SubDoctor = ({ data, isOpenModal = false, callback=() =>{}, closeModal }) => {
  const [formData, setFormData] = useState({
      client_id: data.code,
      name: "",
      contact: "",
      designation: "",
    })

    const handleSubmit = async() => {
      const res = await post(`api/clients/addsubdoctor`, formData);
      if(res?.status === 200){
        callback();
      }
    }

    const handleChange = (e) => {
      setFormData({...formData, [e.target.name]: e.target.value })
    }

    return (
        <Modal
          zIndex="9999"
          size={'lg'}
          isOpen={isOpenModal}
          toggle={closeModal}
        >
          <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">Add Sub Doctor</h5>
              <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
              >
                  <span aria-hidden="true">&times;</span>
              </button>
          </div>
          
          <div className="modal-body">
            <div className="row">
              <div className="col-md-12 mb-3">
                Parent Clinic or Doctor : <b>{data.client}</b>
              </div>
              <div className="col-md-12 mb-3">
                <label>Doctor Name</label>
                <input type="text" className="form-control" name="name" onChange={handleChange} />
              </div>
              <div className="col-md-12 mb-2">
                <label>Contact Number</label>
                <input type="text" className="form-control" name="contact" onChange={handleChange} />
              </div>
              <div className="col-md-12 mb-2">
                <label>Designation</label>
                <input type="text" className="form-control" name="designation" onChange={handleChange} />
              </div>
            </div>
          </div>

          <div className="modal-footer">
              <button
                  type="button"
                  onClick={() => closeModal }
                  className="btn btn-secondary "
                  data-dismiss="modal"
              >
                  Close
              </button>
              <button
                  type="button"
                  onClick={() => handleSubmit()}
                  className="btn btn-success "
                  data-dismiss="modal"
              >
                  Submit
              </button>
          </div>
      </Modal>
    )
}

export default SubDoctor