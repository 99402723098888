import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Datatable from "components/Datatables/datatable";
import { reportWorkTypes } from "constants/layout";
import { post } from "helpers/api_helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { clientOptions } from "store/actions";
import { Filters } from "../filters";

const isType = 'Citiwise Orders Report'
const styleSheet = {
    fontStyle: {
        fontWeight: 'bold',
        background: '#424141',
        padding: '0px 15px',
        color: '#fff'
    }
}

const CitiesReport = () => {
    const dispatch = useDispatch()
    const [rowData, setRowData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [filterData, setFilterData] = useState({
        'cities': '',
        'client': '',
        'startdate': new Date(),
        'enddate': new Date(),
        'type': 'all',
        'category': '',
        'producttype': '',
        'product': ''
    });

    useEffect(() => {
        dispatch(clientOptions())
        submitCall()
    },[])

    // table Column Data
    let tableColumns = [
        {
            dataField: 'id',
            text: '#',
            sort: true
        },{
            dataField: 'worktype',
            text: 'Order Type',
            hidden: filterData.type == 'all'? false : true,
            formatter: (cell, row,  rowIndex, extraData) => {
                switch(row.worktype){
                    case 'new':
                        return <span style={styleSheet.fontStyle} className="bg-success">New</span>
                    case 'redo':
                        return <span style={styleSheet.fontStyle} className="bg-danger">Redo</span>
                    case 'corr':
                        return <span style={styleSheet.fontStyle} className="bg-warning">Correction</span>
                }
            }
        },{
            dataField: 'order_number',
            text: 'Order Number',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.order_number}</span>
        },{
            dataField: 'case_no',
            text: 'Case No',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.case_no}</span>
        },{
            dataField: 'client',
            text: 'Client',
            sort: true
        },{
            dataField: 'patient_name',
            text: 'Patient',
            sort: true
        },{
            dataField: 'order_date',
            text: 'Order Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.order_date}</b>
        },{
            dataField: 'due_date',
            text: 'DueDate',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.due_date}</b>
        },{
            dataField: 'order_value',
            text: 'Amount',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{parseFloat(row.order_value).toFixed(2)}</b>
        }
    ];

    const submitCall = async() => {
        setRowData(null)
        setIsLoading(true)
        const res = await post(`api/reports/citiwise`, filterData);
        if(res.status == 200){
            setRowData(res.response);
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItem={isType} />
                    <Filters callback={submitCall} />

                    <Row style={{height: '520px', overflowY: 'auto'}}>
                        <Col>
                            {
                                <Datatable
                                    keyField={'id'}
                                    title={``}
                                    isAdd={false}
                                    handleAddButton=''
                                    columns={tableColumns}
                                    rows={rowData?.data || []}
                                    isTableHead={true}
                                    loading={ false }
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default CitiesReport
