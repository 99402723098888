import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Datatable from "components/Datatables/datatable";
import { reportWorkTypes } from "constants/layout";
import { post } from "helpers/api_helper";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { clientOptions } from "store/actions";

const isType = 'Orders'
const styleSheet = {
    fontStyle: {
        fontWeight: 'bold',
        background: '#424141',
        padding: '0px 15px',
        color: '#fff'
    }
}

const SalesReport = () => {
    const dispatch = useDispatch()
    const { DDOptions } = useSelector(state => state.Master);
    const [rowData, setRowData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [filterData, setFilterData] = useState({
        'client': '',
        'startdate': new Date(),
        'enddate': new Date(),
        'type': 'all',
        'category': '',
        'producttype': '',
        'product': ''
    });

    useEffect(() => {
        dispatch(clientOptions())
        submitCall()
    },[])

    // table Column Data
    let tableColumns = [
        {
            dataField: 'id',
            text: '#',
            sort: true
        },{
            dataField: 'worktype',
            text: 'Order Type',
            hidden: filterData.type == 'all'? false : true,
            formatter: (cell, row,  rowIndex, extraData) => {
                switch(row.worktype){
                    case 'new':
                        return <span style={styleSheet.fontStyle} className="bg-success">New</span>
                    case 'redo':
                        return <span style={styleSheet.fontStyle} className="bg-danger">Redo</span>
                    case 'corr':
                        return <span style={styleSheet.fontStyle} className="bg-warning">Correction</span>
                }
            }
        },{
            dataField: 'order_number',
            text: 'Order Number',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.order_number}</span>
        },{
            dataField: 'case_no',
            text: 'Case No',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.case_no}</span>
        },{
            dataField: 'client',
            text: 'Client',
            sort: true
        },{
            dataField: 'patient_name',
            text: 'Patient',
            sort: true
        },{
            dataField: 'order_date',
            text: 'Order Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.order_date}</b>
        },{
            dataField: 'due_date',
            text: 'DueDate',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.due_date}</b>
        },{
            dataField: 'order_value',
            text: 'Amount',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{parseFloat(row.order_value).toFixed(2)}</b>
        }
    ];

    const submitCall = async() => {
        setRowData(null)
        setIsLoading(true)
        const res = await post(`api/reports/sales`, filterData);
        if(res.status == 200){
            setRowData(res.response);
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItem={isType} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className={'mb-3'}>
                                            <label>Order Type</label>
                                            <select className="form-control" onChange={(e) => {setFilterData(prev => ({...prev, type: e.target.value}))}}>
                                            {
                                                reportWorkTypes?.map((v, i) => <option key={`WT_${i}`} value={v.id}>{v.title}</option>)
                                            }
                                            </select>
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Select Client</label>
                                            <select className="form-control" defaultValue={filterData.client || null} onChange={(e) => {setFilterData(prev => ({...prev, client: e.target.value}))}}>
                                                <option value=""> {DDOptions?.clients ? 'Select Doctor' : 'Loading...'}</option>
                                                {
                                                    DDOptions?.clients?.map( v => <option key={v.value} value={v.value}>{v.label}</option>)
                                                }
                                            </select>
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Start Date</label>
                                            <DatePicker className="form-control" selected={filterData.startdate || new Date()} onChange={(date) => setFilterData(prev => ({...prev, startdate: date}))} />
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>End Date</label>
                                            <DatePicker className="form-control" minDate={filterData.startdate} selected={filterData.enddate || new Date()} onChange={(date) => setFilterData(prev => ({...prev, enddate: date}))} />
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Product Category</label>
                                            <select className="form-control" defaultValue={filterData.category || null} onChange={(e) => {setFilterData(prev => ({...prev, category: e.target.value}))}}>
                                                <option value=""> {DDOptions?.clients ? 'Select Product Category' : 'Loading...'}</option>
                                                {
                                                    DDOptions?.clients?.map( v => <option key={v.value} value={v.value}>{v.label}</option>)
                                                }
                                            </select>
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Product type</label>
                                            <select className="form-control" defaultValue={filterData.producttype || null} onChange={(e) => {setFilterData(prev => ({...prev, producttype: e.target.value}))}}>
                                                <option value=""> {DDOptions?.clients ? 'Select Product Type' : 'Loading...'}</option>
                                                {
                                                    DDOptions?.clients?.map( v => <option key={v.value} value={v.value}>{v.label}</option>)
                                                }
                                            </select>
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Product</label>
                                            <select className="form-control" defaultValue={filterData.product || null} onChange={(e) => {setFilterData(prev => ({...prev, product: e.target.value}))}}>
                                                <option value=""> {DDOptions?.clients ? 'Select Product' : 'Loading...'}</option>
                                                {
                                                    DDOptions?.clients?.map( v => <option key={v.value} value={v.value}>{v.label}</option>)
                                                }
                                            </select>
                                        </Col>
                                        <Col md={1} style={{marginTop: '24px'}}>
                                            <button className="btn btn-primary float-end" onClick={() => submitCall()} > Submit</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row style={{height: '520px', overflowY: 'auto'}}>
                        <Col>
                            {
                                <Datatable
                                    keyField={'id'}
                                    title={``}
                                    isAdd={false}
                                    handleAddButton=''
                                    columns={tableColumns}
                                    rows={rowData?.data || []}
                                    isTableHead={true}
                                    loading={ false }
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default SalesReport
