import { del, get, post } from "helpers/api_helper"
import { call, put, takeEvery } from "redux-saga/effects"

import {
    ADD_RECORD,
    CLIENT_OPTIONS,
    GET_ALL_ROWS,
    MASTER_OPTIONS,
    PRODUCT_OPTIONS,
    REMOVE_RECORD
} from './actionType'

import {
    clientOptionsSuccess,
    getAllRowsSuccess,
    masterOptionsSuccess,
    productOptionsSuccess,
    pushNewRecord,
    updateRecord
} from "./actions"

import { DDClients_URL } from 'helpers/url_helper'

const getAllRows = ({ url }) => get(url)
const removeCall = ({ url }) => del(url)
const addCall = ({ url, Obj }) => post(url, Obj)

function* actionGetAllRows({ payload: { data, history, cb } }) {
    try {
        const res = yield call(getAllRows, data)
        if (res?.response) {
            if (data.isSingle) {
                let data = res.response[0]
                yield call(cb, data)
                yield put(getAllRowsSuccess({ isEdit: true }))
            } else {
                yield put(getAllRowsSuccess({ isEdit: false, data: res.response, isType: data.isType }))
            }
            
        }
    } catch (error) {}
}

function* actionAddRecord({ payload: { data, history, addupdate_callback } }) {
    try {
        const res = data?.id ? yield call(addCall, data) : yield call(addCall, data)
        if (res?.status == 200) {
            yield call(addupdate_callback)

            data?.id ? 
                yield put(updateRecord({ isType: data.isType, data: res.response, isUpdate: true })) :
                yield put(pushNewRecord({ isType: data.isType, data: res.response }))
        }
    } catch (error) {}
}

function* actionRemoveRecord({ payload: { data, cb } }) {
    try {
        const res = yield call(removeCall, data)
        if (res.status == 200) {
            yield call(cb, data.isType)
            yield put(updateRecord({ data: data.data, isType: data.isType }))
        }
    } catch (error) {}
}

/**
 * Show the ddoptions
 */
function* getOptionsCall({payload:{data}}) {
    try {
        const url = data?.type ? `${DDClients_URL}?type=${data.type}` : DDClients_URL;

        const res = yield call(getAllRows, { url: url })
        if (res.status == 200) {
            yield put(clientOptionsSuccess(res.response))
        }
    } catch (error) { }
}

/**
 * Get master Options ddoptions
 */
function* getMasterOptionsCall({ payload: { data }}) {
    try {
        const res = yield call(getAllRows, data)
        if (res.status == 200) {
            yield put(masterOptionsSuccess(res.response))
        }
    } catch (error) { }
}

/**
 * Get master Options ddoptions
 */
function* getProductOptionsCall({ payload: { data }}) {
    try {
        const res = yield call(getAllRows, data)
        if (res.status == 200) {
            yield put(productOptionsSuccess(res))
        }
    } catch (error) { }
}

function* MasterSaga() {
    yield takeEvery(GET_ALL_ROWS, actionGetAllRows)
    yield takeEvery(ADD_RECORD, actionAddRecord)
    yield takeEvery(REMOVE_RECORD, actionRemoveRecord)
    yield takeEvery(CLIENT_OPTIONS, getOptionsCall)
    yield takeEvery(MASTER_OPTIONS, getMasterOptionsCall)
    yield takeEvery(PRODUCT_OPTIONS, getProductOptionsCall)
}

export default MasterSaga
