import {
    ADD_RECORD,
    ADD_RECORD_SUCCESS,
    CLIENT_OPTIONS,
    CLIENT_OPTIONS_SUCCESS,
    GET_ALL_ROWS,
    GET_ALL_ROWS_SUCCESS,
    MASTER_OPTIONS,
    MASTER_OPTIONS_SUCCESS,
    PRODUCT_OPTIONS,
    PRODUCT_OPTIONS_SUCCESS,
    PUSH_NEW_RECORD,
    REMOVE_RECORD,
    UPDATE_RECORD
} from './actionType'

const INIT_STATE = {
    companies: [],
    departments: [],
    designations: [],
    countries: [],
    labdepartments: [],
    states: [],
    cities: [],
    stations: [],
    zones: [],
    sources: [],
    qalifications: [],
    courrectiontemplates: [],
    roles: [],
    locations: [],
    shadeguides: [],
    shades: [],
    products: [],
    product_categorys: [],
    product_warrantys: [],
    product_types: [],
    product_groups: [],
    parentclients: [],
    clientcategorys: [],
    clients: [],
    loading: false,
    DDOptions: [],
    DDMasters: [],
    DDProduct: []
}

const MasterR = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_ALL_ROWS:
        case ADD_RECORD:
            return {
                ...state,
                loading: true,
            }
        case ADD_RECORD_SUCCESS:
            return {
                ...state,
                loading: false,
            }
        case MASTER_OPTIONS:
            return {
               ...state,
                loading: true,
                DDMasters: []
            }
        case MASTER_OPTIONS_SUCCESS:
            return {
               ...state,
                loading: true,
                DDMasters: action.payload
            }
        case PRODUCT_OPTIONS:
            return {
               ...state,
                loading: true,
                DDProduct: []
            }
        case PRODUCT_OPTIONS_SUCCESS:
            return {
               ...state,
                loading: false,
                DDProduct: action.payload.response
            }
        // case PRODUCT_UPDATE_STATUS:
        //     return {
        //        ...state,
        //         loading: false,
        //         // Products: {...state.orders, rows: state.orders.rows.map(v => (payload.id == v.id) ? {...v, status: payload.status} : v)} 
        //     }
        case PUSH_NEW_RECORD:
            let newRecord = [];
            switch (action.payload.data.isType) {
                case 'company':
                    return {
                        ...state,
                        loading: false,
                        companies: [...state.companies, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                    }
                // case 'department':
                //     return {
                //         ...state,
                //         loading: false,
                //         departments: [...state.departments, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'designation':
                //     return {
                //         ...state,
                //         loading: false,
                //         designations: [...state.designations, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'countries':
                //     return {
                //         ...state,
                //         loading: false,
                //         countries: [...state.countries, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'states':
                //     return {
                //         ...state,
                //         loading: false,
                //         states: [...state.states, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'cities':
                //     return {
                //         ...state,
                //         loading: false,
                //         cities: [...state.cities, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'stations':
                //     return {
                //         ...state,
                //         loading: false,
                //         stations: [...state.stations, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'labdepartment':
                //     return {
                //         ...state,
                //         loading: false,
                //         labdepartments: [...state.labdepartments, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'zones':
                //     return {
                //         ...state,
                //         loading: false,
                //         zones: [...state.zones, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'sources':
                //     return {
                //         ...state,
                //         loading: false,
                //         sources: [...state.sources, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'qalifications':
                //     return {
                //         ...state,
                //         loading: false,
                //         qalifications: [...state.qalifications, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'courrectiontemplates':
                //     return {
                //         ...state,
                //         loading: false,
                //         courrectiontemplates: [...state.courrectiontemplates, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'roles':
                //     return {
                //         ...state,
                //         loading: false,
                //         roles: [...state.roles, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'locations':
                //     return {
                //         ...state,
                //         loading: false,
                //         locations: [...state.locations, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'shadeguide':
                //     return {
                //         ...state,
                //         loading: false,
                //         shadeguides: [...state.shadeguides, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'shade':
                //     return {
                //         ...state,
                //         loading: false,
                //         shades: [...state.shades, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'category':
                //     return {
                //         ...state,
                //         loading: false,
                //         product_categorys: [...state.product_categorys, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'warranty':
                //     return {
                //         ...state,
                //         loading: false,
                //         product_warrantys: [...state.product_warrantys, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'type':
                //     return {
                //         ...state,
                //         loading: false,
                //         product_types: [...state.product_types, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                // case 'group':
                //     return {
                //         ...state,
                //         loading: false,
                //         product_groups: [...state.product_groups, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                //     }
                case 'product':
                    return {
                        ...state,
                        loading: false,
                        products: [...state.products, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                    }
                case 'parentclient':
                    return {
                        ...state,
                        loading: false,
                        parentclients: [...state.parentclients, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                    }
                case 'clientcategory':
                    return {
                        ...state,
                        loading: false,
                        clientcategorys: [...state.clientcategorys, action.payload.data.data].map((z, i) => ({ ...z, sr: i + 1 }))
                    }
            }
            return {
                ...state,
                loading: false,
                ...newRecord
            }
        case GET_ALL_ROWS_SUCCESS:
            let data = {};
            if (action.payload.isEdit) return { ...state, loading: false }

            switch (action.payload.isType) {
                case 'company':
                    data = { companies: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
                // case 'department':
                //     data = { departments: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'designation':
                //     data = { designations: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'countries':
                //     data = { countries: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'states':
                //     data = { states: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'cities':
                //     data = { cities: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'stations':
                //     data = { stations: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'labdepartment':
                //     data = { labdepartments: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'zones':
                //     data = { zones: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'sources':
                //     data = { sources: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'qalifications':
                //     data = { qalifications: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'courrectiontemplates':
                //     data = { courrectiontemplates: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'roles':
                //     data = { roles: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'locations':
                //     data = { locations: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'shadeguide':
                //     data = { shadeguides: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                // case 'shade':
                //     data = { shades: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                //     break
                case 'category':
                    data = { product_categorys: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
                case 'warranty':
                    data = { product_warrantys: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
                case 'type':
                    data = { product_types: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
                case 'group':
                    data = { product_groups: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
                case 'Product':
                    data = { products: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
                case 'parentclient':
                    data = { parentclients: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
                case 'clientcategory':
                    data = { clientcategorys: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
                case 'client':
                    data = { clients: (typeof action.payload.data) == 'string' ? [] : action.payload.data.map((z, i) => ({ ...z, sr: i + 1 })) }
                    break
            }
            
            return {
                ...state,
                loading: false,
                ...data
            }
        case REMOVE_RECORD:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_RECORD:
            switch (action.payload.isType) {
                case 'company':
                    return {
                        ...state,
                        loading: false,
                        companies: state.companies.map(z => {
                            if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                                return { ...z, status: action.payload.data.data.status }
                            } else if (action.payload.isUpdate && z.id == action.payload.data.id) {
                                z.title = action.payload.data.title,
                                    z.mobile = action.payload.data.mobile,
                                    z.tel = action.payload.data.tel,
                                    z.email = action.payload.data.email,
                                    z.fax = action.payload.data.fax,
                                    z.pan = action.payload.data.pan,
                                    z.gst = action.payload.data.gst,
                                    z.cin = action.payload.data.cin,
                                    z.website = action.payload.data.website,
                                    z.address = action.payload.data.address,
                                    z.image = action.payload.data.image,
                                    z.added_by = action.payload.data.added_by,
                                    z.status = action.payload.data.status
                            }
                            return z
                        })
                    }
                // case 'department':
                //     return {
                //         ...state,
                //         loading: false,
                //         departments: state.departments.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             } else if (action.payload.isUpdate && z.id == action.payload.data.id) {
                //                 z.title = action.payload.data.title,
                //                     z.code = action.payload.data.code,
                //                     z.status = action.payload.data.status
                //             }
                //             return z
                //         })
                //     }
                // case 'designation':
                //     return {
                //         ...state,
                //         loading: false,
                //         designations: state.designations.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             } else if (action.payload.isUpdate && z.id == action.payload.data.id) {
                //                 z.title = action.payload.data.title,
                //                     z.code = action.payload.data.code,
                //                     z.status = action.payload.data.status
                //             }
                //             return z
                //         })
                //     }
                // case 'countries':
                //     return {
                //         ...state,
                //         loading: false,
                //         countries: state.countries.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             } else if (action.payload.isUpdate && z.id == action.payload.data.id) {
                //                 z.title = action.payload.data.title,
                //                     z.code = action.payload.data.code,
                //                     z.status = action.payload.data.status
                //             }
                //             return z
                //         })
                //     }
                // case 'states':
                //     return {
                //         ...state,
                //         loading: false,
                //         states: state.states.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             } else if (action.payload.isUpdate && z.id == action.payload.data.id) {
                //                 z.title = action.payload.data.title,
                //                 z.code = action.payload.data.code,
                //                 z.country = action.payload.data.country,
                //                 z.status = action.payload.data.status
                //             }
                //             return z
                //         })
                //     }
                // case 'cities':
                //     return {
                //         ...state,
                //         loading: false,
                //         cities: state.cities.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'labdepartment':
                //     return {
                //         ...state,
                //         loading: false,
                //         labdepartments: state.labdepartments.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'zones':
                //     return {
                //         ...state,
                //         loading: false,
                //         zones: state.zones.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'sources':
                //     return {
                //         ...state,
                //         loading: false,
                //         sources: state.sources.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'qalifications':
                //     return {
                //         ...state,
                //         loading: false,
                //         qalifications: state.qalifications.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'courrectiontemplates':
                //     return {
                //         ...state,
                //         loading: false,
                //         courrectiontemplates: state.courrectiontemplates.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'roles':
                //     return {
                //         ...state,
                //         loading: false,
                //         roles: state.roles.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'locations':
                //     return {
                //         ...state,
                //         loading: false,
                //         locations: state.locations.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'shadeguide':
                //     return {
                //         ...state,
                //         loading: false,
                //         shadeguides: state.shadeguides.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'shade':
                //     return {
                //         ...state,
                //         loading: false,
                //         shades: state.shades.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'category':
                //     return {
                //         ...state,
                //         loading: false,
                //         product_categorys: state.product_categorys.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'warranty':
                //     return {
                //         ...state,
                //         loading: false,
                //         product_warrantys: state.product_warrantys.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'type':
                //     return {
                //         ...state,
                //         loading: false,
                //         product_types: state.product_types.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                // case 'group':
                //     return {
                //         ...state,
                //         loading: false,
                //         product_groups: state.product_groups.map(z => {
                //             if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                //                 return { ...z, status: action.payload.data.data.status }
                //             }
                //             return z
                //         })
                //     }
                case 'product':
                    return {
                        ...state,
                        loading: false,
                        products: state.products.map(z => (!action.payload.isUpdate && z.id == action.payload.data.id) ? { ...z, status: action.payload.data.status } : z )
                    }
                case 'parentclient':
                    return {
                        ...state,
                        loading: false,
                        parentclients: state.parentclients.map(z => {
                            if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                                return { ...z, status: action.payload.data.data.status }
                            }
                            return z
                        })
                    }
                case 'clientcategory':
                    return {
                        ...state,
                        loading: false,
                        clientcategorys: state.clientcategorys.map(z => {
                            if (!action.payload.isUpdate && z.id == action.payload.data.data.id) {
                                return { ...z, status: action.payload.data.data.status }
                            }
                            return z
                        })
                    }
                case 'client':
                    return {
                        ...state,
                        loading: false,
                        clients: state.clients.map(z => {
                            if (z.id == action.payload.data.id) {
                                return { ...z, status: action.payload.data.status }
                            }
                            return z
                        })
                    }
            }
            return {
                ...state,
                loading: false
            }
        case CLIENT_OPTIONS:
            return {
                ...state,
                loading: true,
                DDOptions: { clients: [], branches: [] },
            }
        case CLIENT_OPTIONS_SUCCESS:
            const res = (typeof action.payload == 'string') ? [] : action.payload
            return {
                ...state,
                loading: false,
                DDOptions: { clients: res.clients, branches: res.branches},
            }
        default:
            return state
    }
}

export default MasterR