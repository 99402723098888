import {
    GET_ALL_INVOICES,
    GET_ALL_INVOICES_SUCCESS,
    INVOICE_PRINT_DATA,
    INVOICE_PRINT_DATA_SUCCESS
} from './actionTypes'

const INIT_STATE = {
    loading: false,
    invoices: [],
    printdata: null
}

const InvoicesR = (state = INIT_STATE, action) => {
    const { type, payload } = action

    switch (type) {
        case GET_ALL_INVOICES:
            return {
                ...state,
                invoices: [],
                loading: true,
            }
        case GET_ALL_INVOICES_SUCCESS:
            return {
                ...state,
                invoices: payload,
                loading: false
            }
        case INVOICE_PRINT_DATA:
            return {
                ...state,
                loading: true,
                printdata: null
            }
        case INVOICE_PRINT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                printdata: payload,
            }
        default:
            return state
    }
}

export default InvoicesR