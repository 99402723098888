// ToggleSwitch.js
import './ToggleSwitch.css';

const ToggleSwitch = ({ onChange, checked, label = '' }) => {
  return (
    <>
      <label className="switch">
        <input type="checkbox" checked={checked} onChange={onChange}/>
        <span className="slider round"></span>
      </label><br />
      <span>{label}</span>
    </>
  );
};

export default ToggleSwitch;
