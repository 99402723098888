import { Modal } from "reactstrap"

const Rxmodal = ({imgUrl, callback}) => {
    return (<Modal
            size="lg"
            isOpen={ imgUrl != false }
            centered={true}
        >
        <div className="modal-header">
            <b>RX Upload</b>
        </div>
        <div className="modal-body">
            <img src={imgUrl} style={{width: '-webkit-fill-available'}} />
        </div>
        <div className="modal-footer">
            <button 
                type="button"
                title="close modal"
                className="btn btn-secondary"
                onClick={callback} >Close</button>        
        </div>
    </Modal>)
}

export default Rxmodal