import React, { useState } from 'react';
import { debounce } from 'lodash';
import Select from 'react-select';
import { SEARCHCLIENTS } from 'helpers/url_helper';
import { post } from 'helpers/api_helper' 

const ClientDropdown = ({callback}) => {
    const [selectedValue, setSelectedValue] = useState(null)
    const [options, setOptions] = useState([]);

    const ServiceCall = async(data) => {
        const res = await post(SEARCHCLIENTS, data);
        if(res.status == 200){
            setOptions(res.response)
        }
    }

    // Debounce the search function
    const debouncedSearch = debounce(ServiceCall, 500);

    const handleInputChange = (event) => {
        if(event != '' && event.length > 2){
            debouncedSearch({ "qry": "clientname as label, code as value", q: event});
        }
    };
    
    return (
        <>
            <label>Clients</label>
            <Select
                onInputChange={(e) => {
                    handleInputChange(e)
                }}
                onChange={(e) => {
                    setSelectedValue(e)
                    callback(e)
                }}
                isClearable={true}
                value={selectedValue || '' }
                options={options}
                name='clients'
                required={false}
                placeholder={'Select Client'}
            />
        </>
    )
}

export default ClientDropdown