import { useState } from 'react'
import {
    Modal
} from "reactstrap"

import { DeleteHelper } from '../modules/Masters/CallHelper'
import { get } from 'helpers/api_helper'
import Notifications from "components/Notifications";

const DeleteConfirmationModel = ({isOpen, changeState, code, other = false}) => {
    const [checked, setChecked] = useState(false);
    const [inputCode, setInputCode] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const deleteRow = async() => {
        if(code.code == inputCode){
            const res = await !code?.other ? DeleteHelper(code.url, code.q, code.status, checked, code?.isMaster) : get(`${code.url}&visable=${Number(checked)}`)
            if(res){
                code.status == 1 ? Notifications(`record ${checked ? 'prominently deleted' : 'deleted'} successfully.!`, 'error') : Notifications('record restored successfully.!')
                changeState(false)
            }
        }else{
            alert('please enter code');
        }
    }

    return (
        <Modal
            size="md"
            isOpen={isOpen}
            toggle={() => changeState(false)}
            centered={true}
        >
            <div className="modal-body">
                <p>Please Enter <b><u>{code.code}</u></b> for {code.status == 1? 'Delete Confirmation' : 'Activate'}.</p>
                <input name="code" onChange={(e) => setInputCode(e.target.value)} className="form-control" /> <br />
                {
                    code.status == 1 &&
                    <span>
                        <input
                        type="checkbox"
                        checked={checked}
                        onChange={() => setChecked(!checked)} /> Please Check, if delete permanently.
                    </span>
                }
            </div>
            <div className="modal-footer">
                <button 
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => changeState(false)} >Close</button>
                <button 
                    type="button"
                    disabled={ (!isLoading && code.code == inputCode) ? false : true }
                    className={`btn btn-${code.status == 1 ? 'danger' : 'success' } `}
                    onClick={() => {
                        setIsLoading(true);
                        deleteRow()
                    }} >{code.status == 1 ? 'Delete' : 'Activate' } {isLoading && <i className='fas fa-spinner fa-spin'/>}</button>
            </div>
        </Modal>
    )
}

export default DeleteConfirmationModel