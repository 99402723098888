import { combineReducers } from "redux"

// Front
import Layout from "./layout/reducer"

// Authentication
import Login from "./auth/login/reducer"

// Company
import Master from "store/master/reducers"

// Employee
import Employee from 'modules/Employee/store/reducers'

// Orders
import Orders from 'modules/Orders/store/reducers'

// Challans
import Challans from 'modules/Challans/store/reducers'

import Invoices from 'modules/Invoices/store/reducers'

const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Master,
  Employee,
  Orders,
  Challans,
  Invoices
})

export default rootReducer
