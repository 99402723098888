import {
    GET_ALL_ROWS,
    GET_SINGLE_ROW,
    GET_ALL_ROWS_SUCCESS,
    ADD_RECORD,
    REMOVE_RECORD,
    UPDATE_RECORD,
    PUSH_NEW_RECORD,
    CLIENT_OPTIONS,
    CLIENT_OPTIONS_SUCCESS,
    MASTER_OPTIONS,
    MASTER_OPTIONS_SUCCESS,
    PRODUCT_OPTIONS,
    PRODUCT_OPTIONS_SUCCESS,
    PRODUCT_STATUS_UPDATE
} from './actionType'

export const getAllRows = (data, history = false, cb = false) => ({
    type: GET_ALL_ROWS,
    payload: {data, history, cb},
})

export const pushNewRecord = (data) => ({
    type: PUSH_NEW_RECORD,
    payload: {data},
})

export const getAllRowsSuccess = payload => ({
    type: GET_ALL_ROWS_SUCCESS,
    payload: payload,
})

export const getSingleRowSuccess = payload => ({
    type: GET_SINGLE_ROW,
    payload: payload,
})

export const addRecord = (data, history, addupdate_callback) => ({
    type: ADD_RECORD,
    payload: {data, history, addupdate_callback}
})

export const addRecordSuccess = () => ({
    type: ADD_RECORD_SUCCESS,
    payload: {}
})

export const removeRecord = (data, cb) => ({
    type: REMOVE_RECORD,
    payload: {data,cb}
})

export const updateRecord = payload => ({
    type: UPDATE_RECORD,
    payload: payload
})

export const clientOptions = data => {
  return {
    type: CLIENT_OPTIONS,
    payload: {data}
  }
}

export const clientOptionsSuccess = data => ({
  type: CLIENT_OPTIONS_SUCCESS,
  payload: data
})

export const masterOptions = data => ({
  type: MASTER_OPTIONS,
  payload: {data}
})


export const masterOptionsSuccess = data => ({
  type: MASTER_OPTIONS_SUCCESS,
  payload: data
})

export const productOptions = data => ({
  type: PRODUCT_OPTIONS,
  payload: {data}
})


export const productOptionsSuccess = data => {
  return {
    type: PRODUCT_OPTIONS_SUCCESS,
    payload: data
  }
}

export const updateProductStatus = data => {
  return {
    type: PRODUCT_STATUS_UPDATE,
    payload: data
  }
}


