import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import { get } from 'helpers/api_helper'
import { userModuleData, userRole } from 'helpers/basic_helper'
import { EMPLOYEE_URL } from "helpers/url_helper"
import { useDispatch, useSelector } from 'react-redux'
import { addEmployeeRecord, getEmployeeAllRows, removeEmployeeRecord } from 'store/actions'
import Form from "./component/Form"

const Employee = props => {
    const dispatch = useDispatch()
    const { loading, employees } = useSelector(state => state.Employee);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [DDMasters, setDDMasters] = useState([])
    const [isDelete, setIsDelete] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)

    const getData = async() => {
        const role = await userRole();
        if(role == 'Superadmin'){
            const data = {
                edit: true,
                delete: true,
                add: true,
            }
        
            setIsActions(data)
            if(data && (data?.edit || data?.delete)){
                setIsActionColumn(true)
            }
        }else{
            const data =  await userModuleData('employee_master', 'employees')
            setIsActions(data)
            if(data && (data?.edit || data?.delete)){
                setIsActionColumn(true)
            }
        }

        const opts = await get('/api/employee/getOptions');
        if(opts.status === 200){
            setDDMasters(opts.response);
        }
        dispatch(getEmployeeAllRows({url:EMPLOYEE_URL, isSingle: false}, props.history))
    }

    useEffect(() => {
        setDataRows(employees)
    },[employees])    
    useEffect(() => getData(),[])
    
    /**
     * Add or Update Data
     * @param {Row_id} data 
     */
    const submitForm = (data) => {
        setIsLoading(true)

        const Obj = {
            // "image"  : data.target.image.value,
            "id"  : data.id,
            "firstname"  : data.firstname,
            "middlename"  : data.middlename, 
            "lastname"  : data.lastname,
            "gender"  : data.gender,
            "code"  : data.code,
            "mobile"  : data.mobile,
            "phone"  : data.phone,
            "dob"  : data.dob,
            "doj"  : data.doj,
            "email"  : data.email, 
            "role"  : data.role, 
            "shift"  : '', //data.shift, 
            "shiftgroup"  : '', //data.shiftgroup, 
            "designation"  : data.designation, 
            "department"  : data.department, 
            "lab_department"  : data.lab_department, 
            "location"  : '', //data.location, 
            "pan"  : data.pan, 
            "uid"  : data.uid, 
            "address1"  : data.address1, 
            "address2"  : data.address2,
            "branch"  : data.branch
        }

        dispatch(addEmployeeRecord({Obj:Obj, url: EMPLOYEE_URL+`${data?.id ? '?q='+data?.id: '' }`, id: data?.id}, props.history, () => {
            // getData()
            setIsLoading(false)
            setIsEdit(false)
            isOpen(!open)
        }))
    }

    /**
     * Get Single row using url params ?q=?
     * @param {Row_id} q 
     * @method {get} GET 
     */
    const handleEdit = async(q) => {
        const opts = await get(`${EMPLOYEE_URL}?q=${q}`);
        if(opts.status === 200){
            setEditableObj(opts.response);
            setIsEdit(q)
            isOpen(!open)
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {del} DELETE 
     */
    const handleRemove = (data, status) => {
        if(data > 0){
            setIsDelete(data)
            dispatch(removeEmployeeRecord({url:`${EMPLOYEE_URL}?q=${data}&status=${status}`, data: {id:data, status:status}}, () => {
                setIsDelete(false)
            }))
        }
    }

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        }, {
            accessorFn: (row) => `${row.firstname} ${row.middlename} ${row.lastname}`,
            header: 'Employee Name'
        },{
            accessorKey: 'code',
            header: 'Employee Code'
        },{
            accessorKey: 'branch',
            header: 'Branch'
        },{
            accessorKey: 'email',
            header: 'Email'
        },{
            accessorKey: 'mobile',
            header: 'Mobile'
        },{
            accessorFn: (row) => row.gender == 1 ? 'Male' : 'Female',
            header: 'Gender'
        },{
            accessorKey: 'designation',
            header: 'Designation'
        }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags><title>Dashboard | Employees</title></MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={"Employee"} />
                    <Row>
                        <Col xl="12">
                            <Mdatatable
                                enableRowAction={isActionColumn}
                                userModules={isActions}
                                title={`All Employee's`}
                                columns={datatableData}
                                data={dataRows}
                                handleEdit={handleEdit}
                                handleRemove={handleRemove}
                                isEdit={isEdit}
                                isDelete={isDelete}
                                loading={isLoading}
                                handleAddButton={() => {
                                    setEditableObj(null)
                                    isOpen(true)
                                }}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Datatable */}
            {
                open &&
                <Form
                    title={`${editableObj ? 'Edit' : 'Add'} Employee`}
                    Position={"right"}
                    isOpen={open}
                    width={60}
                    DDMasters={DDMasters}
                    length={dataRows?.length}
                    editableObj={editableObj}
                    onDrawerClose={() => {
                        setEditableObj(null)
                        isOpen(!open)
                    }}
                    submitForm={submitForm}
                />
            }
            {/* End Datatable */}
        </React.Fragment>
    )
}

export default Employee
