import {
    CREATE_CHALLAN,
    CREATE_CHALLAN_SUCCESS,
    PRINT_DATA,
    PRINT_DATA_SUCCESS
} from './actionTypes'

const INIT_STATE = {
    loading: false,
    printdata: null
}

const ChallansR = (state = INIT_STATE, action) => {
    const { type, payload } = action

    switch (type) {
        case CREATE_CHALLAN:
            return {
                ...state,
                loading: true,
            }
        case CREATE_CHALLAN_SUCCESS:
            return {
                ...state,
                loading: false
            }
            case PRINT_DATA:
                return {
                ...state,
                loading: true,
                printdata: null
            }
        case PRINT_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                printdata: payload
            }
        default:
            return state
    }
}

export default ChallansR