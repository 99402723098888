import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { Container } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import "react-datepicker/dist/react-datepicker.css";
import { RenderdChart } from "modules/components/chart.jsx"
import { get } from "helpers/api_helper"
import { REPORTINVOICECHART_URL } from "helpers/url_helper";


const isType = 'Charts'
const orderObj = {
    labels : [1,2,3,4,5,6,7,8,9,10],
    datasets: [
      {
        label: '',
        data: [1,2,5,6,7,3,4,8,9,0,],
        borderColor: 'green',
        backgroundColor: 'rgba(42,48,66, 0.5)',
      }
    ],
}


const Charts = () => {
    const [data, setData] = useState({});

    useEffect(() => {
        const a = async() => {
            const res = await get(`${REPORTINVOICECHART_URL}`);
            if(res.status == 200){
                setData(res?.response)
            }
            console.log(res)
        }
        a();
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItem={isType} />
                    
                    <div className="row">
                        <div className="col-6">
                            <div className="card">
                                <div className="card-body">
                                    <h5>Monthly Invoices</h5>
                                    {
                                        Object.keys(data).length ? 
                                        <RenderdChart data={data} addoptions={{
                                            responsive: true,
                                            plugins: {
                                                legend: {
                                                    position: 'bottom',
                                                    display: false
                                                }
                                            },
                                        }}/>: 'Loading'
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Charts
