import React, { useState, useEffect } from "react"
import {
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap"
import { get } from 'helpers/api_helper'
import { SUBSCRIPTION } from 'helpers/url_helper'

const Subcription = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        gstSubcription();
    },[])

    const gstSubcription = async() => {
        const res = await get(SUBSCRIPTION);
        if(res.status === 200){
            setData(res?.response);
        }
    }

    return (
        <Col>
            <Card>
                <CardBody>
                    <h4>Subcription Details</h4>

                    <Row>
                        <Col>
                            <strong>Package</strong>
                            <h4>{data?.package}</h4>
                        </Col>
                        <Col>
                            <strong>Status</strong>
                            <h4 className="text-success">Active</h4>
                        </Col>
                    </Row>
                    <Row className="mt-4">
                        <Col>
                            <strong>Activation Date</strong>
                            <h4 className="text-success">{data?.subscription_date}</h4>
                        </Col>
                        <Col>
                            <strong>Renewal Date</strong>
                            <h4 className="text-warning">{data?.reminder_date}</h4>
                        </Col>
                        <Col>
                            <strong>Exprire Date</strong>
                            <h4 className="text-danger">{data?.renewal_date}</h4>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </Col>
    )
}

export default Subcription;