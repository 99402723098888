import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import Notifications from "components/Notifications"
import DeleteConfirmationModel from "components/delete-confirmation-model"
import { getCode, userModuleData } from 'helpers/basic_helper'
import { MASTER_OPTIONS_URL } from "helpers/url_helper"
import React, { useEffect, useMemo, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from 'react-redux'
import {
    Col,
    Container,
    Row
} from "reactstrap"
import { masterOptions } from 'store/actions'
import { GetHelper, SubmitHelper } from '../CallHelper'
import FormComponent from "../FormComponent"

const isType = 'zones'
const Zone = () => {
    const dispatch = useDispatch()
    const { DDMasters } = useSelector(state => state.Master);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDeleteModel, setIsDeleteModel] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [error, setError] = useState(false)

    useEffect(async() => {
        const data =  await userModuleData('masters', 'zones')
        setIsActions(data)
        if(data && (data?.edit || data?.delete)){
            setIsActionColumn(true)
        }
    }, [])

    /**
     * Get All Rows
     * @param {row_id} ID 
     * @method {get} GET 
     */
    const getData = async(ID = false) => {
        !ID ? setIsLoading(!isLoading) : false
        const res = await GetHelper(`${process.env.REACT_APP_ZONES}`, ID)
        if(ID){
            setEditableObj(...res)
            isOpen(true)
            setIsEdit(false)
        }else{
            setDataRows(res)
        }
        
        setIsLoading(false)
        if(res === undefined){
            setError(true)
        }
    }
    
    useEffect(() => {
        getData()
        dispatch(masterOptions({url:MASTER_OPTIONS_URL}))
    },[])

    /**
     * Submit or Update data Api Call
     * @param {Form Data} data
     * @method {post} POST
     */
    const submitForm = async(data, selectedValue) => {
        const checkCode = !dataRows ? [] : dataRows?.filter(v => (v.code).toUpperCase() == (data.target.code.value).toUpperCase())
        if(data?.target?.id?.value =='' && checkCode.length > 0) {
            Notifications('please enter unique code.!', 'error')
            return;
        }

        const stations = selectedValue?.selectedValue ? (selectedValue?.selectedValue?.station).map( v => v.value) : ''
        const res = await SubmitHelper(`${process.env.REACT_APP_ZONES}`, {
            "title": selectedValue.title,
            "code": selectedValue.code,
            "stations": stations
        }, selectedValue?.id || null);

        if(res){
            getData()
            isOpen(false)
            Notifications('You have successfully added a Zone.!')
        }
    }

    /**
     * Get Single row using url params ?q=?
     * @param {Row_id} q
     * @method {get} GET
     */
    const handleEdit = q => {
        if(q > 0){
            setIsEdit(q)
            getData(q)
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q
     * @param {row Status} status
     * @method {del} DELETE
     */
    const handleRemove = async(q, status, code) => {
        if(q > 0) {
            setIsDeleteModel({url: process.env.REACT_APP_ZONES, q, status, code})
        }
    }

    const hideModel = () => {
        setIsDeleteModel(false)
        getData()
    }


    /**
     * Get All Zones and return as array
     * @returns Array
     */
    const getMulti = () => {
        if(editableObj && editableObj?.stations){
            const x = JSON.parse(editableObj?.stations);
            const a = DDMasters?.stations?.filter(v => x.map(v => v.code).includes(v.code)).map(v => ({'label': v.title, 'value': v.code}))
            return a
        }else{
            []
        }
    }

    /**
     * DataTable with Condition based row formating
     */
    let datatableData =  useMemo( () => [
        {
            accessorKey: 'id',
            header: 'Id'
        }, {
            accessorKey: 'title',
            header: 'Source'
        }, {
            accessorKey: 'code',
            header: 'Code'
        }, {
            accessorKey: 'stations',
            header: 'Station',
            accessorFn: (row) => row.stations !== undefined ? JSON.parse(row.stations).map(v => v.title).join(', '): ""
        }
    ],[])

    /**
     * HTML Form Columns 
     */
    let htmlFormFields = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : '',
            col:12
        },{
            name: "title",
            label: "Source",
            placeholder: "Source Title",
            required: true,
            type: 'text',
            value: editableObj?editableObj.title : '',
            col:12
        },{
            name: "code",
            label: "Code",
            placeholder: "Code",
            required: true,
            type: 'text',
            value: editableObj?editableObj.code: getCode("zones", dataRows?.length),
            col: 12
        },{
            name: "station",
            label: 'Station',
            placeholder: "Station",
            required: false,
            isMulti: true,
            type: 'select',
            options: DDMasters?.stations?.map(v => ({'label': v.title, 'value': v.code})),
            value: getMulti(),
            col: 12
        }
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags><title>Dashboard | {isType}</title></MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />
                    <Row>
                        <Col xl="12">
                            {
                                <Mdatatable
                                    enableRowAction={isActionColumn}
                                    userModules={isActions}
                                    title={`All ${isType}`}
                                    columns={datatableData}
                                    data={dataRows}
                                    handleEdit={handleEdit}
                                    handleRemove={handleRemove}
                                    isEdit={isEdit}
                                    handleAddButton={() => {
                                        setEditableObj(null)
                                        isOpen(true)
                                    }}
                                />
                            }
                        
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Datatable */}
            {
                open &&
                <FormComponent
                    title={`${editableObj ? 'Edit' : 'Add'} Zone`}
                    Position={"right"}
                    isOpen={open}
                    fileds={htmlFormFields}
                    editableObj={editableObj}
                    loading={isLoading}
                    onDrawerClose={() => {
                        setEditableObj(null)
                        isOpen(!open)
                    }}
                    submitForm={submitForm}
                />
            }
            {/* End Datatable */}

            { isDeleteModel && <DeleteConfirmationModel isOpen={true} changeState={hideModel} code={isDeleteModel} /> }
        </React.Fragment>
    )
}

export default Zone
