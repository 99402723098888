import toastr from "toastr";
import "toastr/build/toastr.min.css";


// success, info, warning, error
const Notifications = (message = '', toastType = 'success') => {
    // toast-top-right, toast-bottom-right, toast-bottom-left, toast-top-left, toast-top-full-width, toast-bottom-full-width, toast-top-center, toast-bottom-center
    const position = 'toast-top-right';
    
    const title = "";

    //Close Button
    const closeButton = true;

    //Debug
    const debug = true;

    //Progressbar
    const progressBar = false;

    //Duplicates
    const preventDuplicates = true;

    //Newest on Top
    const newestOnTop = true;

    //position class
    let positionClass = "toast-top-right";

    //Fetch position
    for (let p = 0; p < position.length; p++) {
        if (position[p].checked) positionClass = position[p].value;
    }

    //Show Easing
    const showEasing = 'swing';

    //Hide Easing
    const hideEasing = 'linear';

    //show method
    const showMethod = 'fadeIn';

    //Hide method
    const hideMethod = 'fadeOut';

    //show duration
    const showDuration = 300;

    //Hide duration
    const hideDuration = 1000;

    //timeout
    const timeOut = 5000;

    //extended timeout
    const extendedTimeOut = 1000;

    //Fetch checked Type
    // for (let i = 0; i < ele.length; i++) {
    //     if (ele[i].checked) toastType = ele[i].value;
    // }

    toastr.options = {
        positionClass: positionClass,
        timeOut: timeOut,
        extendedTimeOut: extendedTimeOut,
        closeButton: closeButton,
        debug: debug,
        progressBar: progressBar,
        preventDuplicates: preventDuplicates,
        newestOnTop: newestOnTop,
        showEasing: showEasing,
        hideEasing: hideEasing,
        showMethod: showMethod,
        hideMethod: hideMethod,
        showDuration: showDuration,
        hideDuration: hideDuration
    };

    // setTimeout(() => toastr.success(`Settings updated `), 300)
    //Toaster Types
    if (toastType === "info") toastr.info(message, title);
    else if (toastType === "warning") toastr.warning(message, title);
    else if (toastType === "error") toastr.error(message, title);
    else toastr.success(message, title);
}

export default Notifications