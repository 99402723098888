import { AvField, AvForm } from "availity-reactstrap-validation";
import Dropzone from 'components/Dropzone';
import { useEffect, useRef, useState } from 'react';
import { MultiSelect } from "react-multi-select-component";
import Select from 'react-select';


const CustomForm = ({isOpen, fileds, fileupload = false, isType = "add", submitForm, loading, callback}) => {
    const resetValue = useRef('');
    const [selectedValue, setSelectedValue] = useState(null)
    const [multiSelected, setMultiSelected] = useState([]);

    useEffect(() => {
        if(selectedValue)
            setSelectedValue(null)
    },[isOpen])

    useEffect(() => {
        if(!isType){
            setSelectedValue(null)
            setMultiSelected([])
            resetValue.current.reset();
        }
    },[isOpen])

    return (
        <AvForm
            ref={resetValue}
            className="form-horizontal"
            onValidSubmit={(e, v) => {
                submitForm(e, {...v, selectedValue})
            }}
        >
            {
                fileupload && (
                    <div className="mb-3">
                        <Dropzone />
                    </div>
                )
            }
            <div className='row'>
                {
                    fileds?.map((v, i) => {
                        if(v.divider){
                            return  <div className={`col-md-12 mb-3`} key={`${i}_div`}>
                                <h4 style={{fontWeight: '900'}}>{v.name}</h4>
                            </div>
                        }
                        return v.type === 'select' ?
                            <div className={`col-md-${v.col} mb-3`} key={`${i}_div`}>
                                <label>{v.label} {v.required ? <span className="text-danger fw-bold"> * </span> : ''} </label>
                                {
                                    v?.isMulti ?
                                        <MultiSelect
                                            options={ v.options === undefined ? [] : v.options}
                                            value={(v.value && multiSelected.length == 0) ? setMultiSelected(v.value) : multiSelected }
                                            onChange={(e) => {
                                                setMultiSelected(e)
                                                setSelectedValue(prev => ({...prev, [v.name]:e}))
                                            }}
                                            required={v.required}
                                            labelledBy="Select"
                                        />
                                        :
                                        <Select
                                            onChange={(e) => {
                                                setSelectedValue(prev => ({...prev, [v.name]:e}))
                                                if(v.callback) v.callback(e, v.name)
                                            }}
                                            isClearable={true}
                                            value={selectedValue ? selectedValue[v.name] : (v?.value?.length ? setSelectedValue(prev => ({...prev, [v.name]:v.value[0]})) : '') }
                                            options={v.options}
                                            name={v.name}
                                            required={v.required}
                                            className={v.className}
                                            placeholder={v.placeholder}
                                        />
                                }
                            </div>:
                            // eslint-disable-next-line react/jsx-key
                            <div className={`col-md-${v.col} mb-3`} key={`${i}_div`}>
                                <AvField
                                    key={`${i}_input`}
                                    name={v.name}
                                    label={v.label ? <div dangerouslySetInnerHTML={{__html: `${v.label} ${v.required ? `<span class="text-danger fw-bold"> * </span>` : ''}` } } /> : ''}
                                    className="form-control"
                                    disabled={v.disable}
                                    placeholder={v.placeholder}
                                    type={v.type}
                                    defaultValue={ v.value || null }
                                    readOnly={(isType && v.name) == 'code' ? true : false}
                                    required={v.required}
                                    />
                            </div>
                    })
                }
            </div>

            <div className="mt-3 d-grid">
                <button
                    className="btn btn-primary btn-block "
                    type="submit"
                > {loading && <i className="fas fa-spinner fa-spin" />}{" "}{isType == false ? 'Submit' : 'Update'}</button>
            </div>
        </AvForm>
    )
}

export default CustomForm