import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Media,
  Row
} from "reactstrap"

//Import Images
import { colors } from 'constants/layout'
import { get } from "helpers/api_helper"
import digiLogo from "../../assets/images/digi-logo.png"

function CardUser(props) {
  const [settingsMenu, setSettingsMenu] = useState(false)
  const [profile, setProfile] = useState(null)
  
  //Setting Menu
  const toggleSettings = () => {
    setSettingsMenu(settingsMenu)
  }

  useEffect(async() => {
    const res = await get(`api/org`);
    if(res.status == 200){
      const user = JSON.parse(localStorage.getItem('authUser'))
      setProfile({...res.response.org, ...res.response, ...user})
    }
  }, [])

  return (
    <React.Fragment>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody>
              <Row>
                <Col lg="3">
                  <Media>
                    <div className="me-3" style={{border: '5px solid #2a3042', borderRadius: '50px'}}>
                      {
                        profile ?
                        <div
                          style={{border: `5px solid ${colors[profile?.subscription.package]}`, borderRadius: '50px'}}
                          >
                          <img
                            style={{border: '3px solid #2a3042', borderRadius: '50px'}}
                            src={profile?.image || digiLogo }
                            alt={profile?.username}
                            className="avatar-md rounded-circle"
                          />
                        </div>:
                        <img
                          src={digiLogo}
                          alt={profile?.title}
                          className="avatar-md rounded-circle "
                        />
                      }
                    </div>
                    <Media className="align-self-center" body>
                      <div className="text-muted">
                        <p className="mb-2">Welcome to {profile?.username || <i className="fas fa-spinner fa-spin" />}</p>
                        <h5 className="mb-1">{(profile?.username)?.toUpperCase() || <i className="fas fa-spinner fa-spin" />}</h5>
                        <p className="mb-0">{(profile?.user_role)?.toUpperCase() || <i className="fas fa-spinner fa-spin" />}</p>
                        <b>{profile?.subscription.days} {profile?.subscription.days > 1 ? 'Days' : 'Days'} left</b>
                      </div>
                    </Media>
                  </Media>
                </Col>

                <Col lg="9" className="align-self-center">
                  <div className="text-lg-center mt-4 mt-lg-0">
                    <Row>
                    <Col xs="2">
                      {
                        profile ? <h3 className="mb-2">{profile && parseInt(profile?.clients)}</h3> : <i className="mb-3 fas fa-spinner fa-spin" />
                      }
                      <p className="text-muted text-truncate mb-2">Clients</p>
                    </Col>
                    <Col xs="2">
                      {
                        profile ? <h3 className="mb-2">{profile && parseInt(profile?.employees)}</h3> : <i className="mb-3 fas fa-spinner fa-spin" />
                      }
                      <p className="text-muted text-truncate mb-2">Employees</p>
                    </Col>
                    <Col xs="2">
                      {
                        profile ? <h3 className="mb-2">{profile && parseInt(profile?.orders.new)}</h3> : <i className="mb-3 fas fa-spinner fa-spin" />
                        }
                        <p className="text-muted text-truncate mb-2">Total New Orders</p>
                      </Col>
                      <Col xs="2">
                        {
                          profile ? <h3 className="mb-2">{profile && parseInt(profile?.orders.redo)}</h3> : <i className="mb-3 fas fa-spinner fa-spin" />
                        }
                        <p className="text-muted text-truncate mb-2">Total Redo Orders</p>
                      </Col>
                      <Col xs="2">
                        {
                          profile ? <h3 className="mb-2">{profile && parseInt(profile?.orders.corr)}</h3> : <i className="mb-3 fas fa-spinner fa-spin" />
                          }
                          <p className="text-muted text-truncate mb-2">Total Currection Orders</p>
                      </Col>
                    </Row>
                  </div>
                </Col>

                {/* <Col lg="4" className="d-none d-lg-block">
                  <div className="clearfix mt-4 mt-lg-0">
                    <Dropdown
                      isOpen={settingsMenu}
                      toggle={toggleSettings}
                      className="float-end"
                    >
                      <DropdownToggle tag="button" className="btn btn-primary">
                        <i className="bx bxs-cog align-middle me-1" /> Setting
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem href="#">Action</DropdownItem>
                        <DropdownItem href="#">Another action</DropdownItem>
                        <DropdownItem href="#">Something else</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>
                </Col> */}
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default CardUser
