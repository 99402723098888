import { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Col,
    Label,
    Row
} from "reactstrap";
import Template from "./template";
import { MAIL_URL } from 'helpers/url_helper'
import { post, get } from 'helpers/api_helper'

const EmailSettings = () => {
    const [isOpen, setIsOpen] = useState();
    const [objectData, setObjectData] = useState(null);
    const [data, setData] = useState(null);
    const [testMail, setTestMail] = useState(null);

    const fetchData = async() => {
        const res = await get(MAIL_URL);
        if(res.status == 200){
            setData(res.response);
        }
    }

    useEffect(() => {
        fetchData();
    }, [])

    const saveCreds = async() => {
        if(data?.id){
            objectData.id = data.id
        }
        const res = await post(MAIL_URL, objectData);
        if(res.status == 200) {
            fetchData();
        }
    }

    const testMailApi = async() => {
        const res = await post(`${MAIL_URL}/testmail`, {email: testMail});
        if(res.status == 200){
            setData(res.response);
        }
    }

    return (
        <Col>
            <Card>
                <CardBody>
                    <h4>Email Settings</h4>

                    <Row>
                        <Col>
                            <Row className="mt-2">
                                <strong>SMTP Configuration</strong>
                                <Col>
                                    <Label>Host</Label>
                                    <input className="form-control" type="text" defaultValue={data?.host} onChange={(e) => setObjectData(prev => ({...prev, smtphost: e.target.value}))} />
                                </Col>
                                <Col>
                                    <Label>SMTP Post</Label>
                                    <input className="form-control" type="number" defaultValue={data?.port} onChange={(e) => setObjectData(prev => ({...prev, smtpport: e.target.value}))} />
                                </Col>
                                <Col>
                                    <Label>Encryption</Label>
                                    <select className="form-control" value={objectData?.smtpenc || data?.enc_type} onChange={(e) => setObjectData(prev => ({...prev, smtpenc: e.target.value}))}>
                                        <option value="">Select Encryption Types</option>
                                        <option value="TLS">Standard encryption (TLS)</option>
                                        <option value="SSL">Secure encryption (SSL)</option>
                                        <option value="PWMTA">PowerMTA Server</option>
                                        <option value="STARTTLS">STARTTLS</option>
                                        <option value="none">None or No SSL</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Label>Username</Label>
                                    <input className="form-control" defaultValue={data?.username} type="text" onChange={(e) => setObjectData(prev => ({...prev, username: e.target.value}))} />
                                </Col>
                                <Col>
                                    <Label>Password</Label>
                                    <input className="form-control" type="text" defaultValue={data?.password} onChange={(e) => setObjectData(prev => ({...prev, password: e.target.value}))} />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Label>From Address</Label>
                                    <input className="form-control" type="text" defaultValue={data?.from_mail} onChange={(e) => setObjectData(prev => ({...prev, fromaddress: e.target.value}))} />
                                </Col>
                                <Col>
                                    <Label>From Name</Label>
                                    <input className="form-control" type="text" defaultValue={data?.name} onChange={(e) => setObjectData(prev => ({...prev, fromname: e.target.value}))} />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <button style={{float:'right'}} className="btn btn-primary" onClick={() => saveCreds()}> {data?.id ? "Update" :"Save"} </button>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <strong>Test Configuration</strong>
                                <Col>
                                    <div className="input-group">
                                        <input type="text" className="form-control" onChange={e => setTestMail(e.target.value)} placeholder="Put Your Email where you will received a test mail from your mail configuration settings"/>
                                        <button className="btn btn-primary" type="button" onClick={() => testMailApi()}>Test</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row>
                                <Row className="mb-2">
                                    <Col>
                                        <h4 className="mt-2 mb-2">Email Templates</h4>
                                    </Col>
                                    <Col>
                                        <button style={{float: 'right'}} className="btn btn-primary" onClick={() => setIsOpen(true)}> Add Template</button>
                                    </Col>
                                </Row>
                                <Col>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Template</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Template isOpen={isOpen} callback={() => setIsOpen(false)} type={1} />
        </Col>
    )
}

export default EmailSettings;