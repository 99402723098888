import { Redirect } from "react-router-dom"


// Authentication related pages
import Login from "../modules/Authentication/Login"

// Dashboard
import Dashboard from "../modules/Dashboard/index"

// Masters
import Cities from "../modules/Masters/Cities"
import Company from "../modules/Masters/Company"
import Countries from "../modules/Masters/Countries"
import CourrectionTemplates from "../modules/Masters/CourrectionTemplates"
import Department from "../modules/Masters/Department"
import Designation from "../modules/Masters/Designation"
import LabDepartment from "../modules/Masters/LabDepartment"
import Qalification from "../modules/Masters/Qalifications"
import Roles from "../modules/Masters/Role"
import Source from "../modules/Masters/Sources"
import States from "../modules/Masters/States"
import Stations from "../modules/Masters/Stations"
import Zone from "../modules/Masters/Zone"
// import Privilizes from "../modules/Masters/Privilizes"
import Locations from "../modules/Masters/Locations"

// Product
import Category from "modules/Product/category"
import Group from "modules/Product/group"
import Product from "modules/Product/product"
import Type from "modules/Product/type"
import Warranty from "modules/Product/warranty"

// Shade Guide
import Shade from "modules/Masters/Job/shade"
import ShadeGuide from "modules/Masters/Job/shadeguide"


// Employee
import Employee from "modules/Employee/employees"


// Clinets
import NewChallan from "modules/Challans"
import Challans from "modules/Challans/challans"
import Orderform from "modules/Challans/orderform"
import CreateInvoices from "modules/Invoices"
import Invoices from "modules/Invoices/Invoices"
import Clients from "modules/Masters/Clients/clients"
import ParentClient from "modules/Masters/Clients/parent"
import ClientPriority from "modules/Masters/Clients/priority"
import Orders from "modules/Orders"

import Blocked from "modules/Masters/Clients/blocked"

//Pages
import labslip from "modules/Orders/labslip"
import Pages404 from "../modules/Utility/pages-404"
import Pages500 from "../modules/Utility/pages-500"
import PagesComingsoon from "../modules/Utility/pages-comingsoon"
import PagesMaintenance from "../modules/Utility/pages-maintenance"

// reports
import InvoicePdfview from "components/invoidePDF"
import Payment from "modules/Accounts/PayModule"
import Transections from "modules/Accounts/transections"
import ChallanPdfPrint from "modules/Challans/challan_pdf_page"
import OrderformPdfPrint from "modules/Challans/orderform_pdf_page"
import OrderReport from "modules/Reports/Genral/orders"
import SalesReport from "modules/Reports/Genral/sales"
import ChallanReport from "modules/Reports/challan"
import PendingChallanReport from "modules/Reports/Genral/pending_challans"
import InvloceReport from "modules/Reports/invoices"
import Companysettings from "../modules/Settings/company"
import Notifications from "../modules/Settings/notifications"

// General Reports
import CitiesReport from 'modules/Reports/Genral/cities'

import Branch from "modules/Masters/Branch"

import Payments from "modules/Accounts"
import PayModule from "modules/Accounts/PayModule"

import Processing from "processing"
import AllInvoices from "modules/Invoices/allInvoices"
import Charts from "modules/Reports/charts"
import OrderformReport from "modules/Reports/Genral/orderform"
import OrderformNLReport from "modules/Reports/Genral/orderform_nolayout"

const authProtectedRoutes = [
  { path: "/logout", component: () => {
    localStorage.clear()
    return <Redirect to="/login" />
  }},
  { path: "/reports/charts", component: Charts, layout: true},
  { path: "/branches", component: Branch, layout: true},
  { path: "/settings/notifications", component: Notifications, layout: true},
  { path: "/settings/company", component: Companysettings, layout: true},
  { path: "/account/Payment", component: Payment, layout: true},
  { path: "/account/transections", component: Transections, layout: true},
  { path: "/dashboard", component: Dashboard, layout: true},
  { path: "/company", component: Company, layout: true},
  { path: "/department", component: Department, layout: true},
  { path: "/designation", component: Designation, layout: true},
  { path: "/lab-department", component: LabDepartment, layout: true},
  { path: "/countries", component: Countries, layout: true},
  { path: "/states", component: States, layout: true},
  { path: "/cities", component: Cities, layout: true},
  { path: "/stations", component: Stations, layout: true},
  { path: "/zones", component: Zone, layout: true},
  { path: "/source", component: Source, layout: true},
  { path: "/qalifications", component: Qalification, layout: true},
  { path: "/correction-template", component: CourrectionTemplates, layout: true},
  { path: "/roles", component: Roles, layout: true},
  // { path: "/privilizes", component: Privilizes, layout: true},
  { path: "/locations", component: Locations, layout: true},
  { path: "/job/shade-guide", component: ShadeGuide, layout: true},
  { path: "/job/shade", component: Shade, layout: true},
  { path: "/Product/category", component: Category, layout: true},
  { path: "/product/warranty", component: Warranty, layout: true},
  { path: "/product/type", component: Type, layout: true},
  { path: "/product/group", component: Group, layout: true},
  { path: "/products", component: Product, layout: true},
  { path: "/employees", component: Employee, layout: true},
  { path: "/clients", component: Clients, layout: true},
  { path: "/client/parents", component: ParentClient, layout: true},
  { path: "/client/priority", component: ClientPriority, layout: true},
  { path: "/orders", component: Orders, layout: true},
  { path: "/orderform/create", component: NewChallan, layout: true},
  { path: "/orderforms", component: Orderform, layout: true},
  { path: "/challans", component: Challans, layout: true},
  { path: "/invoices/create", component: CreateInvoices, layout: true},
  { path: "/invoices/proforma", component: Invoices, layout: true},
  { path: "/invoices", component: Invoices, layout: true},
  { path: "/clients/blocked", component: Blocked, layout: true},
  { path: "/labslip/:id", component: labslip, layout: false },
  { path: "/processing", component: Processing, layout: false },
  { path: "/invoice/all", component: AllInvoices, layout: false },
  
  // Accounts
  { path: "/payments", component: PayModule, layout: true },
  { path: "/duecollection", component: Payments, layout: true },

  
  // Genral Reports
  { path: "/reports/orders", component: OrderReport, layout: true },
  { path: "/reports/sales", component: SalesReport, layout: true },
  { path: "/reports/orderform", component: OrderformReport, layout: true },
  { path: "/reports/orderform-print", component: OrderformNLReport, layout: false },
  { path: "/reports/citiwise", component: CitiesReport, layout: true },
  { path: "/reports/challans", component: ChallanReport, layout: true },
  { path: "/reports/pending-challans", component: PendingChallanReport, layout: true },
  { path: "/reports/invoices", component: InvloceReport, layout: true },
  { path: "/challan/print", component: ChallanPdfPrint, layout: false },
  { path: "/orderform/print", component: OrderformPdfPrint, layout: false },
  { path: "/", exact: true, layout: true, component: () => <Redirect to="/dashboard" /> }
]

const publicRoutes = [
  { path: "/invoice/print", component: InvoicePdfview, layout: false },
  { path: "/login", component: Login },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
]

export { authProtectedRoutes, publicRoutes }

