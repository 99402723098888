import { get, post } from 'helpers/api_helper';
import { NSC_URL } from 'helpers/url_helper';
import { useEffect, useState } from 'react';
import { Table } from 'reactstrap';
import ToggleSwitch from '../../../components/Common/ToggleSwitch';

const obj = [
    {
        "id":1,
        "action": "Client Create",
        "email": false,
        "sms": false,
        "whatsapp": false
    }, {
        "id":2,
        "action": "Challan Create",
        "email": false,
        "sms": false,
        "whatsapp": false
    }, {
        "id":3,
        "action": "Order Create",
        "email": false,
        "sms": false,
        "whatsapp": false
    }, {
        "id":4,
        "action": "Order Dispatch",
        "email": false,
        "sms": false,
        "whatsapp": false
    }, {
        "id":5,
        "action": "Offer Send",
        "email": false,
        "sms": false,
        "whatsapp": false
    }, {
        "id":6,
        "action": "Invoice Create",
        "email": false,
        "sms": false,
        "whatsapp": false
    }
];

const NotificationSettings = () => {
    const [notificationObject, setNotificationObject] = useState(obj);
    
    const getData = async() => {
        const res = await get(NSC_URL);
        if(res.status === 200){
            setNotificationObject(res?.response)
        }
    }

    useEffect(() => {
        getData();
    }, [])

    const saveData = async() => {
        const res = await post(NSC_URL, {"object":notificationObject});
        if(res.status == 200){
            getData()
        }
    }

    return (
        <>
            <button className='btn btn-primary' onClick={saveData}>Save</button>
            <Table>
                <thead>
                    <tr>
                    <th>Title</th>
                    <th>Email</th>
                    <th>SMS</th>
                    <th>WhatsApp</th>
                    </tr>
                </thead>
                <tbody>
                    {
                    obj.map((v, i) => (
                        <tr key={i}>
                        <td>{v.action}</td>
                        <td>
                            <ToggleSwitch
                                id={`emailSwitch${i}`}
                                label={v.email ? 'On' : 'Off'}
                                checked={v.email}
                                onChange={() => {
                                    setNotificationObject(prev => ({
                                    ...prev,
                                    [i]: { ...prev[i], email: !prev[i].email }
                                    }));
                                }}
                            />
                        </td>
                        <td>
                            <ToggleSwitch
                            id={`smsSwitch${i}`}
                            label={v.sms ? 'On' : 'Off'}
                            checked={v.sms}
                            onChange={() => {
                                setNotificationObject(prev => ({
                                ...prev,
                                [i]: { ...prev[i], sms: !prev[i].sms }
                                }));
                            }}
                            />
                        </td>
                        <td>
                            <ToggleSwitch
                            id={`whatsappSwitch${i}`}
                            label={v.whatsapp ? 'On' : 'Off'}
                            checked={v.whatsapp}
                            onChange={() => {
                                setNotificationObject(prev => ({
                                ...prev,
                                [i]: { ...prev[i], whatsapp: !prev[i].whatsapp }
                                }));
                            }}
                            />
                        </td>
                        </tr>
                    ))
                    }
                </tbody>
            </Table>
        </>
    );
};

export default NotificationSettings;