import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row,
    Card,
    CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import { deliveryModeOptions } from 'constants/layout'
import { get } from "helpers/api_helper"
import { CHALLAN_URL } from 'helpers/url_helper'
import "react-datepicker/dist/react-datepicker.css"
import { useSelector } from "react-redux"
import { customFormatDate } from 'helpers/basic_helper'
import Datatable from "components/Datatables/datatable";
import Select from 'react-select';
import Flatpickr from "react-flatpickr"
import ClientDropdown from "utils/client_dropdown";
import { sortDateFilters } from 'constants/layout'
import Timezone from "utils/timezone";

const isType = 'challan'
const Challans = () => {
    const {loading} =  useSelector(state => state.Challans);
    const [challans, setChallans] = useState([]);
    const [challansLoad, setChallansLoad] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [selectedRangeValue, setSelectedRangeValue] = useState(null);
    const [filters, setFilters] = useState({
        'search': null,
        'client': null,
        'bydate': null,
    });

    const setPaginationState = (data) => {
        if (data?.page) {
            setSizePerPage(data.sizePerPage);
            setPage(data.page);
        }
    };
    
    const fetchChallans = async (url) => {
        try {
            setChallansLoad(true);
            const res = await get(url);
            if (res.status === 200) {
                setChallans(res.response);
            }
        } catch (error) {
            console.error("Failed to fetch challans:", error);
        } finally {
            setChallansLoad(false);
        }
    };
    
    const fetch = async (data = null) => {
        setPaginationState(data);
    
        const url = `${CHALLAN_URL}?limit=${data?.sizePerPage || sizePerPage}&page=${data?.page || page}`;
        await fetchChallans(url);
    };
    
    const filterOrderHandler = async (data = null) => {
        const filterObj = {
            search: filters?.search || '',
            _startdate: filters?.bydate?.[0] || '',
            _enddate: filters?.bydate?.[1] || '',
            client: filters?.client?.value || ''
        };
    
        setPaginationState(data);
    
        const queryParams = new URLSearchParams(filterObj).toString();
        const url = `${CHALLAN_URL}?${queryParams}&limit=${data?.sizePerPage || sizePerPage}&page=${data?.page || page}`;
        await fetchChallans(url);
    };

    useEffect(() => {
        fetch()
    }, [])

    /**
     * DataTable with Condition based row formatting
     */
    let datatableData = [
        {
            dataField: 'sr_no',
            text: 'Id'
        }, {
            dataField: 'challan_number',
            text: 'Challan Number',
            formatter: (cell, row) => {
                return  <>
                    <a title="Click to view challan" href={`/challan/print?_challan=${row.challan_number}`} target="_blank" rel="noreferrer">
                        # {row.challan_number}
                    </a><br />
                    <span className={`badge badge-soft-${row.is_dispath == 1 ? 'success' : 'danger'} font-size-11`}>{row.is_dispath == 1 ? 'Dispatched' : 'Pending'}</span>
                    <span className={`ms-2 badge badge-soft-warning font-size-11`}>{deliveryModeOptions?.filter(v => v.value == row.delivery_mode)[0]?.label}</span>
                </>
            }
        }, {
            dataField: 'challan_date',
            text: 'Challan Date',
            formatter: (cell, row) => customFormatDate(row?.challan_date, 'DD MMM, YY')
        }, {
            dataField: 'clientname',
            text: '#Client'
        }, {
            dataField: 'order_number',
            text: 'Order Number'
        }, {
            dataField: 'order_date',
            text: 'Order Date',
            formatter: (cell, row) => customFormatDate(row?.order_date, 'DD MMM, YY')
        }, {
            dataField: 'due_date',
            text: 'Due Date',
            formatter: (cell, row) => customFormatDate(row?.due_date, 'DD MMM, YY')
        }
    ];

    const printPdf = (challanNumber) => {
        const CN = challanNumber.challan_number;
        window.open(`/challan/print?_challan=${CN}`, '_blank');
    };
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>{isType} | KS-Lab</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />

                    <Row>
                        <Col xl='12'>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xl="3">
                                            <label>Search by Challan, OrderForm or Modal Number</label>
                                            <input type="number" className="form-control" onChange={(e) => setFilters(prev => ({...prev, search: e.target.value}))} placeholder="Please Search using Order or Modal Number" />
                                        </Col>
                                        <Col xl="2">
                                            <ClientDropdown callback={(e) => setFilters(prev => ({...prev, client: e})) } />
                                        </Col>
                                        <Col xl={2}>
                                            <label>Day wise Filter</label>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedRangeValue(e)
                                                    handleFilterChange(e.value)
                                                }}
                                                isClearable={true}
                                                value={selectedRangeValue || '' }
                                                options={sortDateFilters || []}
                                                name='clients'
                                                required={false}
                                                placeholder={'Select Filter'}
                                            />
                                        </Col>
                                        <Col xl={4}>
                                            <label>Date Range</label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                onChange={
                                                    (e) => {
                                                        const dates = e.map(dateString => Timezone(dateString));
                                                        setFilters(prev => ({...prev, bydate: dates}))
                                                    }
                                                }
                                            />
                                        </Col>
                                        <Col className="text-end">
                                            <button className="btn btn-primary mt-4" onClick={filterOrderHandler}>Filter Challan</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    {/* Datatables */}
                    <Row>
                        <Col>
                            <Datatable
                                keyField={'id'}
                                title={`All Challans`}
                                isAdd={false}
                                isSearch={false}
                                columns={datatableData}
                                rows={challans?.rows || []}
                                rowsLength={challans?.total_rows || 0}
                                loading={challansLoad}
                                ssr={fetch}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Challans
