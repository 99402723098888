import CryptoJS from 'crypto-js';

// Function to encrypt data in JavaScript
function encryptData(data) {
    const key = CryptoJS.enc.Base64.parse('r/GYwrGH/4DjRJ1U4/E+RUkzJVevrVqhOBhVk5l81lE=');
    const iv = CryptoJS.enc.Base64.parse('q7t88PVqq54n1r9XnRYzIw==');

    const cipher = CryptoJS.AES.encrypt(JSON.stringify(data), key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return data? cipher.toString(): [];
}

// Function to decrypt data in JavaScript
async function decryptData(data) {
    if(typeof data === 'string'){
        const iv = CryptoJS.enc.Base64.parse('q7t88PVqq54n1r9XnRYzIw==');
        const key = CryptoJS.enc.Base64.parse('r/GYwrGH/4DjRJ1U4/E+RUkzJVevrVqhOBhVk5l81lE=');
        
        const cipher = CryptoJS.AES.decrypt(data, key, { iv: iv });
        return cipher.toString(CryptoJS.enc.Utf8);
    }
    return data;
}


export { decryptData, encryptData };

