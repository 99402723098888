import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import { useDispatch, useSelector } from "react-redux";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody
} from "reactstrap"
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Datatable from "components/Datatables/datatable"
import { clientOptions } from "store/actions"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { dispath } from "constants/layout"
import { post } from "helpers/api_helper"

const isType = 'Challans'
const styleSheet = {
    fontStyle: {
        fontWeight: 'bold',
        background: '#424141',
        padding: '0px 15px',
        color: '#fff'
    }
}

const ChallanReport = () => {
    const dispatch = useDispatch()
    const { DDOptions } = useSelector(state => state.Master);
    const [rowData, setRowData] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [filterData, setFilterData] = useState({
        'client': '',
        'startdate': new Date(),
        'enddate': new Date(),
        'type': 0
    });

    useEffect(() => {
        dispatch(clientOptions({type: 'challan&_gh=1'}))
    },[])

    // table Column Data
    let tableColumns = [
        {
            dataField: 'id',
            text: '#',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => rowIndex+1
        },{
            dataField: 'challan_number',
            text: 'Challan Number',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.challan_number}</span>
        },{
            dataField: 'order_number',
            text: 'Order No',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.order_number}</span>
        },{
            dataField: 'case_no',
            text: 'Case No',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span style={styleSheet.fontStyle}>{row.case_no}</span>
        },{
            dataField: 'client',
            text: 'Client',
            sort: true
        },{
            dataField: 'order_date',
            text: 'Order Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.order_date}</b>
        },{
            dataField: 'challan_date',
            text: 'DueDate',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.challan_date}</b>
        },{
            dataField: 'is_dispath',
            text: 'Dispathed',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <b>{row.is_dispath == 0 ? 'Yes' : 'No'}</b>
        },{
            dataField: 'actions',
            text: "Actions",
            isDummyField: true,
            formatter: (cell, row,  rowIndex, extraData) => {
                return <>
                    <span
                    onClick={() => getChallanData(row.challan_number)}
                    className="btn btn-outline-secondary btn-sm edit" 
                    title="View Challan" >
                    <i className="fas fa-download"></i>
                    </span>
                </>
            }
        }
    ];

    const getChallanData = async(id) => {
        const res = await get(`api/challans/printChallan/${id}`);
        if(res.status == 200){
            localStorage.setItem('printinvoice', JSON.stringify(res.response))
            const win = window.open(`/challan/print?cno=${id}`, "_blank");
            win.focus();
        }
    }

    const submitCall = async() => {
        setRowData(null)
        setIsLoading(true)
        const res = await post(`api/reports/challans`, filterData);
        if(res.status == 200){
            setRowData(res.response);
            setIsLoading(false)
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Reports" breadcrumbItem={isType} />
                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col className={'mb-3'}>
                                            <label>Select Client</label>
                                            <select className="form-control" defaultValue={filterData.client || null} onChange={(e) => {setFilterData(prev => ({...prev, client: e.target.value}))}}>
                                                <option value=""> {DDOptions?.clients ? 'Select Doctor' : 'Loading...'}</option>
                                                {
                                                    DDOptions?.clients?.map( v => <option key={v.value} value={v.value}>{v.label}</option>)
                                                }
                                            </select>
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>Start Date</label>
                                            <DatePicker className="form-control" selected={filterData.startdate || new Date()} onChange={(date) => setFilterData(prev => ({...prev, startdate: date}))} />
                                        </Col>
                                        <Col className={'mb-3'}>
                                            <label>End Date</label>
                                            <DatePicker className="form-control" minDate={filterData.startdate} selected={filterData.enddate || new Date()} onChange={(date) => setFilterData(prev => ({...prev, enddate: date}))} />
                                        </Col>
                                        {/* <Col className={'mb-3'}>
                                            <label>Delivery Mode</label>
                                            <select className="form-control" onChange={(e) => {setFilterData(prev => ({...prev, type: e.target.value}))}}>
                                            {
                                                dispath?.map((v, i) => <option key={`WT_${i}`} value={v.id}>{v.title}</option>)
                                            }
                                            </select>
                                        </Col> */}
                                        <Col md={1} style={{marginTop: '24px'}}>
                                            <button className="btn btn-primary float-end" onClick={() => submitCall()} disabled={isLoading} > Submit</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row style={{height: '520px', overflowY: 'auto'}}>
                        <Col>
                            {
                                <Datatable
                                    keyField={'id'}
                                    title={`All ${isType}`}
                                    isAdd={false}
                                    handleAddButton=''
                                    columns={tableColumns}
                                    rows={rowData?.data || []}
                                    isTableHead={false}
                                    loading={ false }
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default ChallanReport
