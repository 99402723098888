import { DashboardReportTypes } from 'constants/layout'
import { get } from 'helpers/api_helper'
import { useEffect, useState } from "react"
import { Card, CardBody, Col, Row } from "reactstrap"

const TopViews = () => {
    const [type, setType] = useState(DashboardReportTypes[0])
    const [data, setData] = useState(null)
  
    useEffect(async() => {
      setData(null)
      const res = await get(`api/org/topclients/${type.value}`)
      if(res.status == 200){
        const r = res.response;
        
        setData(
            Array.from({ length: 10 }, (_, index) => {
                const order = r?.orders[index] !== undefined ? r?.orders[index] : '---';
                const invoice = r?.invoice[index] !== undefined ? r?.invoice[index] : '---';

                return <tr key={`TCL_${index}`}>
                    <td>{index+1}</td><td>{ order === '---' ? order : (<>{order.clientname}{' '}<b>({parseFloat(order.orders).toFixed(2)})</b></>)}</td>
                    <td>{invoice?.clientname}  { invoice === '---' ? invoice: <b>( ₹{parseFloat(invoice.amount).toFixed(2)} )</b>}</td>
                </tr>
            })
        )
      }
    }, [type])

    return (
        <Row>
            <Col md="12">
                <Card>
                    <CardBody>
                        <Row className="mb-2">
                            <Col>
                                <h3>{type.title} Report</h3>
                            </Col>
                            <Col md="5">
                                <select onChange={(e) => setType(JSON.parse(e.target.value))} className="form-control" name="fmytd">
                                    {
                                        DashboardReportTypes.map((v,i) => <option value={JSON.stringify(v)} key={`TPD_${i}${v.value}`}>{v.title}</option>)
                                    }
                                </select>
                            </Col>
                        </Row>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>By Order</th>
                                    <th>By Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                { data }
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default TopViews;