import {
    GET_ALL_INVOICES,
    GET_ALL_INVOICES_SUCCESS,
    INVOICE_PRINT_DATA,
    INVOICE_PRINT_DATA_SUCCESS
} from './actionTypes'

export const getInvoices = data => ({
    type: GET_ALL_INVOICES,
    payload: data
})

export const GetInvoiceSuccess = data => ({
    type: GET_ALL_INVOICES_SUCCESS,
    payload: data
})

export const printInvoiceDataAction = data => ({
    type: INVOICE_PRINT_DATA,
    payload: data
})

export const printInvoiceDataActionSuccess = data => ({
    type: INVOICE_PRINT_DATA_SUCCESS,
    payload: data
})