import React from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row
} from "reactstrap"

import Mdatatable from 'components/Datatables/Mdatatables'

import BreadcrumbTwo from "components/Common/BreadcrumbTwo"

const Transections = () => {

    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        }, {
            accessorKey: 'challan_number',
            header: 'Invoice'
        }, {
            accessorKey: 'order_date',
            header: 'Date'
        }, {
            accessorKey: 'due_date',
            header: 'Amount'
        }, {
            accessorKey: 'challan_date',
            header: 'balance'
        }
    ];

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Payments | KS-Lab</title> </MetaTags>
                <Container fluid>
                    <BreadcrumbTwo title="Payments" breadcrumbItems={[{'title':'Accounts', "url": '/account'},{'title':'Payments', "url": '/account/payments'}]} />
                    <Row style={{ height: '520px', overflowY: 'auto' }}>
                        <Col>
                            <Mdatatable
                                title={`All Products`}
                                columns={datatableData}
                                data={[]}
                                isAdd={false}
                                loading={false}
                                btnloading={false}
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Transections
