import { DashboardReportTypes } from 'constants/layout'
import { get } from 'helpers/api_helper'
import { RenderdChart } from "modules/components/chart.jsx"
import { useEffect, useState } from 'react'

import {
    Card,
    CardBody,
    Col,
    Row
} from "reactstrap"

const orderObj = {
    labels : null,
    datasets: [
      {
        label: 'All',
        data: [],
        borderColor: 'rgb(42,48,66)',
        backgroundColor: 'rgba(42,48,66, 0.5)',
      },
      {
        label: 'New',
        data: [],
        borderColor: 'rgb(52,195,143)',
        backgroundColor: 'rgba(52,195,143, 0.5)',
      },
      {
        label: 'Redo',
        data: [],
        borderColor: 'rgb(243,180,109)',
        backgroundColor: 'rgba(243,180,109, 0.5)',
      },
      {
        label: 'Currection',
        data: [],
        borderColor: 'rgb(255, 99, 132)',
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      }
    ],
}

const invoiceObj = {
    labels : null,
    datasets: [{
        label: 'All',
        data: [],
        borderColor: 'rgb(42,48,66)',
        backgroundColor: 'rgba(42,48,66, 0.5)',
    }],
}

const ChartJsCard = ({type = "Order"}) => {
    const [reportType, setReportType] = useState(DashboardReportTypes[0])
    const [data, setData] = useState(type == 'Order' ? orderObj : invoiceObj)

    useEffect(async() => {
        setData(type == 'Order' ? orderObj : invoiceObj)
        if(type == 'Order'){
            const res = await get(`api/org/orders/${reportType.value}`)
            if(res.status == 200){
                setData((prevState) => ({
                    ...prevState,
                    labels: res.response.label,
                    datasets: [
                        {
                            ...prevState.datasets[0],
                            data: res.response.data.map(item => item.redo + item.new + item.corr ),
                        },
                        {
                            ...prevState.datasets[1],
                            data: res.response.data.map(v => v.new),
                        },
                        {
                            ...prevState.datasets[2],
                            data: res.response.data.map(v => v.redo),
                        },
                        {
                            ...prevState.datasets[3],
                            data: res.response.data.map(v => v.corr),
                        },
                    ],
                }))
            }
        }else{
            const res = await get(`api/org/invoices/${reportType.value}`)
            if(res.status == 200){
                setData((prevState) => ({
                    ...prevState,
                    labels: res.response.label,
                    datasets: [{
                        ...prevState.datasets[0],
                        data: res.response.data,
                    }]
                }))
            }
        }
    }, [reportType])

    return (
        <Row>
            <Col md="12">
                <Card>
                    <CardBody>
                        <Row className="mb-2">
                            <Col>
                                <h3>{reportType.title} {type} Report</h3>
                            </Col>
                            <Col md="5">
                                <select onChange={(e) => setReportType(JSON.parse(e.target.value))} className="form-control">
                                    {
                                        DashboardReportTypes.map((v,i) => <option value={JSON.stringify(v)} key={`TPD_${i}${v.value}${type}`}>{v.title}</option>)
                                    }
                                </select>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <RenderdChart data={data}/>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default ChartJsCard