import { del, get, post } from 'helpers/api_helper'
import { MASTER_URL } from "helpers/url_helper"

export const SubmitHelper = async(type, Obj, ID = false) => {
    const res = await post(`${MASTER_URL}${type}${ID ? `&q=${ID}`: '' }`, Obj)
    if(res.status == 200) return res.response
}

export const GetHelper = async(type, ID = false) => {
    const res = await get(`${MASTER_URL}${type}${ID ? `&q=${ID}` : '' }`)
    if(res.status == 200) return res.response
}

export const DeleteHelper = async(type, q, status, isVisable, isMaster = true) => {
    const res = await del(`${isMaster ? `${MASTER_URL}${type}&` : `${type}?`}q=${q}&status=${status}&visable=${Number(isVisable)}`)
    if(res.status == 200) return true
}