import { all, fork } from "redux-saga/effects"

//public
import AuthSaga from "./auth/login/saga"
import LayoutSaga from "./layout/saga"

// Company
import MasterSaga from 'store/master/saga';

// Employee
import EmployeeSaga from "modules/Employee/store/saga";

// Orders
import OrderSaga from "modules/Orders/store/saga";

// Challans
import ChallansSaga from "modules/Challans/store/saga";

import InvoicesSaga from "modules/Invoices/store/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(AuthSaga),
    fork(LayoutSaga),
    fork(MasterSaga),
    fork(EmployeeSaga),
    fork(OrderSaga),
    fork(ChallansSaga),
    fork(InvoicesSaga)
  ])
}
