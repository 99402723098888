import { PRODUCT_URL } from "helpers/url_helper";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllOptions
} from "store/actions";

import Teeth from "./teeth";

const theeths = ['18','17','16','15','14','13','12','11','21','22','23','24','25','26','27','28','48','47','46','45','44','43','42','41','31','32','33','34','35','36','37','38']
const AddProduct = ({products, clientData}) => {
    const dispatch = useDispatch()
    const { loading, categories, category_types, product_list } = useSelector(state => state.Orders);

    // OPtions State
    const [category, setCategory] = useState("")
    const [categoryTitle, setCategoryTitle] = useState("")

    const [categoryType, setCategoryType] = useState("")
    const [categoryTypeTitle, setCategoryTypeTitle] = useState("")
    
    const [productList, setProductList] = useState("")
    const [productListTitle, setProductListTitle] = useState("")
    
    const [productUnitPrice, setProductUnitPrice] = useState(0)
    
    const [tempData, setTempData] = useState([])
    const [fullMouth, setFullMouth] = useState(false)
    
    const [aligners, setAligners] = useState({
        upperSet: 0,
        lowerSet: 0
    })

    useEffect(() => {
        dispatch(getAllOptions({"url": `${PRODUCT_URL}/options`, "isType": false}))
    }, [])

    useEffect(() => {
        if(category != "") {
            setCategoryType( c => "")
            dispatch(getAllOptions({"url": `${PRODUCT_URL}/options/types?q=${category}`, "isType": "types"}))
        }
    }, [category])
    
    useEffect(() => {
        if(categoryType != "") {
            setProductList(c => "")
            dispatch(getAllOptions({"url": `${PRODUCT_URL}/options/product?q=${categoryType}&c=${clientData.code}`, "isType": "product"}))
        }
    }, [categoryType])

       // Teeth
    const [teeth, setTeeth] = useState([])

    const add_teeth = data => {
        setTeeth([...teeth, data])
    }

    const finalData = () => {
        if(category && categoryType && productList) {
            const units = (categoryTitle != 'Aligner' && categoryTitle != 'Aligners') ? teeth.length: Object.values(aligners).reduce((acc, value) => acc + parseInt(value), 0)

            let obj = {
                "aligners": aligners,
                "units": parseInt(units),
                "teeth": teeth,
                'category': category,
                'categoryTitle': categoryTitle,
                'category_type': categoryType,
                'categoryTypeTitle': categoryTypeTitle,
                'product_id': productList,
                'productListTitle': productListTitle,
                'unitPrice': productUnitPrice[0]
            }

            setTempData([...tempData, obj])
            setProductList("")
            setCategory("")
            setCategoryType("")
            setTeeth([] )
            setAligners({
                upperSet: 0,
                lowerSet: 0
            })
            setFullMouth(false)
    
            products(obj)
        }
    }

    const  remove = (array, element) =>  {
        setTeeth( () => array.filter(e => e !== element))
    };

    
    const getSets = (type) => {
        const buttons = [];
        for (let i = 1; i <= 27; i++) {
            if (i === 27) {
                // Create an input element for the last button
                buttons.push(
                <input
                    type="text"
                    className={`setBtnInput ${aligners[type] === i ? (type === 'upperSet' ? 'setBtnSelUpper' : 'setBtnSelLower') : ''}`}
                    key={`sb_${type}_${Math.round(Math.random() * 100)}`}
                    value={aligners[type] || null}
                    placeholder="Other"
                    onChange={(e) => {
                    const value = parseInt(e.target.value) || 0;
                    setAligners((prev) => ({ ...prev, [type]: value }));
                    }}
                />
                );
            } else {
                // Create a regular button
                buttons.push(
                <button
                    className={`setBtn ${aligners[type] === i && (type === 'upperSet' ? 'setBtnSelUpper' : 'setBtnSelLower')}`}
                    key={`sb_${type}_${Math.round(Math.random() * 100)}`}
                    onClick={() => {
                    setAligners((prev) => ({ ...prev, [type]: i }));
                    }}
                >
                    {i}
                </button>
                );
            }
        }
        return buttons;
    }
    
    return (
        <>
            <div className="row">
                <div className="form-group col-md-4 col-sm-4 col-xs-4">
                    <label>Product Category</label>
                    <select className="form-control"
                        value={category}
                        required={true}
                        onChange={ e => {
                            setCategoryType(e => "")
                            setProductList(e => "")
                            setCategoryTitle( categories.filter( v=> v.code === e.target.value).map(v => v.title)[0])
                            setCategory(c => e.target.value)
                        }}>
                        <option value=""> --- </option>
                        {
                            categories?.map( v => <option value={v.code} key={`C_${v.code}`}> {v.title} </option>)
                        }
                    </select>
                </div>
                <div className="form-group col-md-4 col-sm-4 col-xs-4">
                    <label>Product Type</label>
                    <select
                        className="form-control"
                        value={categoryType}
                        required={true}
                        onChange={ e =>{
                            setCategoryTypeTitle( category_types.filter( v=> v.code === e.target.value).map(v => v.title)[0])
                            setCategoryType(e.target.value)
                        }}>
                        <option value="">
                            {
                                loading && categoryType == "" ? "loading..." : "---"
                            }
                        </option>
                        {
                            category_types?.map( v => <option data-title={v.title} value={v.code} key={`CT_${v.code}`}> {v.title} </option>)
                        }
                    </select>
                </div>
                <div className="form-group col-md-4 col-sm-4 col-xs-4">
                    <label>Product List</label>
                        <select
                        value={productList}
                        required={true}
                        className="form-control"
                        onChange={ e =>{
                            setProductList(e.target.value)
                            setProductListTitle( product_list.filter( v=> v.code === e.target.value).map(v => v.title)[0])
                            setProductUnitPrice( product_list.filter( v=> v.code === e.target.value).map(v => v.final_price))
                        }}>
                        <option value="">---</option>
                        {
                            product_list?.map( (v, i) => <option value={v.code} key={`PL_${v.code}_${i}`}> {`${v.title} ( ${v.final_price} )`} </option>)
                        }
                    </select>
                </div>
            </div>

            <div className="row" style={{marginTop:'40px'}}>
                <div className="table-responsive">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <div dir="ltr" style={{marginBottom: "5px"}}>
                            {
                                categoryTitle != 'Aligner' ?
                                <div>
                                    <span style={{fontSize: '15px', paddingTop: '7px'}}> Full Mouth</span>
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            value=""
                                            checked={fullMouth}
                                            onChange={ e => {
                                                setFullMouth( c => !fullMouth )
                                                setTeeth(() => !fullMouth ? theeths: [])
                                            }} />
                                </div> :
                                <div>
                                    <span style={{fontSize: '15px', paddingTop: '7px'}}> Upper</span>
                                    <table style={{width: '100%'}}>
                                        <tr>
                                            {getSets('upperSet', aligners.upperSet).map((button, index) => (
                                                <td key={index}>{button}</td>
                                            ))}
                                        </tr>
                                    </table>

                                    <span style={{fontSize: '15px', paddingTop: '7px'}}> Lower</span>
                                    <table style={{width: '100%'}}>
                                        <tr>
                                            {getSets('lowerSet', aligners.lowerSet).map((button, index) => (
                                                <td key={index}>{button}</td>
                                            ))}
                                        </tr>
                                    </table>
                                </div>
                            }
                        </div>
                        
                        {
                            (categoryTitle != 'Aligner' && categoryTitle != 'Aligners') &&
                            <table className="table table-bordered" id="teeth" style={{textAlign: 'center'}}>
                                <tbody className="teeth_td">
                                    <tr key="p1">
                                        <td key={`PAF_18`}><button onClick={ () => {teeth.includes("18") ? remove(teeth, "18"): add_teeth("18")}} className={`teeth_bg ${ teeth.includes("18") && 'active'}`}>18</button></td>
                                        <td key={`PAF_17`}><button onClick={ () => {teeth.includes("17") ? remove(teeth, "17"): add_teeth("17")}} className={`teeth_bg ${ teeth.includes("17") && 'active'}`}>17</button></td>
                                        <td key={`PAF_16`}><button onClick={ () => {teeth.includes("16") ? remove(teeth, "16"): add_teeth("16")}} className={`teeth_bg ${ teeth.includes("16") && 'active'}`}>16</button></td>
                                        <td key={`PAF_15`}><button onClick={ () => {teeth.includes("15") ? remove(teeth, "15"): add_teeth("15")}} className={`teeth_bg ${ teeth.includes("15") && 'active'}`}>15</button></td>
                                        <td key={`PAF_14`}><button onClick={ () => {teeth.includes("14") ? remove(teeth, "14"): add_teeth("14")}} className={`teeth_bg ${ teeth.includes("14") && 'active'}`}>14</button></td>
                                        <td key={`PAF_13`}><button onClick={ () => {teeth.includes("13") ? remove(teeth, "13"): add_teeth("13")}} className={`teeth_bg ${ teeth.includes("13") && 'active'}`}>13</button></td>
                                        <td key={`PAF_12`}><button onClick={ () => {teeth.includes("12") ? remove(teeth, "12"): add_teeth("12")}} className={`teeth_bg ${ teeth.includes("12") && 'active'}`}>12</button></td>
                                        <td key={`PAF_11`}><button onClick={ () => {teeth.includes("11") ? remove(teeth, "11"): add_teeth("11")}} className={`teeth_bg ${ teeth.includes("11") && 'active'}`}>11</button></td>
                                        <td key={`PAF_21`}><button onClick={ () => {teeth.includes("21") ? remove(teeth, "21"): add_teeth("21")}} className={`teeth_bg ${ teeth.includes("21") && 'active'}`}>21</button></td>
                                        <td key={`PAF_22`}><button onClick={ () => {teeth.includes("22") ? remove(teeth, "22"): add_teeth("22")}} className={`teeth_bg ${ teeth.includes("22") && 'active'}`}>22</button></td>
                                        <td key={`PAF_23`}><button onClick={ () => {teeth.includes("23") ? remove(teeth, "23"): add_teeth("23")}} className={`teeth_bg ${ teeth.includes("23") && 'active'}`}>23</button></td>
                                        <td key={`PAF_24`}><button onClick={ () => {teeth.includes("24") ? remove(teeth, "24"): add_teeth("24")}} className={`teeth_bg ${ teeth.includes("24") && 'active'}`}>24</button></td>
                                        <td key={`PAF_25`}><button onClick={ () => {teeth.includes("25") ? remove(teeth, "25"): add_teeth("25")}} className={`teeth_bg ${ teeth.includes("25") && 'active'}`}>25</button></td>
                                        <td key={`PAF_26`}><button onClick={ () => {teeth.includes("26") ? remove(teeth, "26"): add_teeth("26")}} className={`teeth_bg ${ teeth.includes("26") && 'active'}`}>26</button></td>
                                        <td key={`PAF_27`}><button onClick={ () => {teeth.includes("27") ? remove(teeth, "27"): add_teeth("27")}} className={`teeth_bg ${ teeth.includes("27") && 'active'}`}>27</button></td>
                                        <td key={`PAF_28`}><button onClick={ () => {teeth.includes("28") ? remove(teeth, "28"): add_teeth("28")}} className={`teeth_bg ${ teeth.includes("28") && 'active'}`}>28</button></td>
                                    </tr>
                                    <tr key="p2">
                                        <td key={`PAF_48`}><button onClick={ () => { teeth.includes("48") ? remove(teeth, "48"): add_teeth("48") }} className={`teeth_bg ${ teeth.includes("48") && 'active'}`}>48</button></td>
                                        <td key={`PAF_47`}><button onClick={ () => { teeth.includes("47") ? remove(teeth, "47"): add_teeth("47") }} className={`teeth_bg ${ teeth.includes("47") && 'active'}`}>47</button></td>
                                        <td key={`PAF_46`}><button onClick={ () => { teeth.includes("46") ? remove(teeth, "46"): add_teeth("46") }} className={`teeth_bg ${ teeth.includes("46") && 'active'}`}>46</button></td>
                                        <td key={`PAF_45`}><button onClick={ () => { teeth.includes("45") ? remove(teeth, "45"): add_teeth("45") }} className={`teeth_bg ${ teeth.includes("45") && 'active'}`}>45</button></td>
                                        <td key={`PAF_44`}><button onClick={ () => { teeth.includes("44") ? remove(teeth, "44"): add_teeth("44") }} className={`teeth_bg ${ teeth.includes("44") && 'active'}`}>44</button></td>
                                        <td key={`PAF_43`}><button onClick={ () => { teeth.includes("43") ? remove(teeth, "43"): add_teeth("43") }} className={`teeth_bg ${ teeth.includes("43") && 'active'}`}>43</button></td>
                                        <td key={`PAF_42`}><button onClick={ () => { teeth.includes("42") ? remove(teeth, "42"): add_teeth("42") }} className={`teeth_bg ${ teeth.includes("42") && 'active'}`}>42</button></td>
                                        <td key={`PAF_41`}><button onClick={ () => { teeth.includes("41") ? remove(teeth, "41"): add_teeth("41") }} className={`teeth_bg ${ teeth.includes("41") && 'active'}`}>41</button></td>
                                        <td key={`PAF_31`}><button onClick={ () => { teeth.includes("31") ? remove(teeth, "31"): add_teeth("31") }} className={`teeth_bg ${ teeth.includes("31") && 'active'}`}>31</button></td>
                                        <td key={`PAF_32`}><button onClick={ () => { teeth.includes("32") ? remove(teeth, "32"): add_teeth("32") }} className={`teeth_bg ${ teeth.includes("32") && 'active'}`}>32</button></td>
                                        <td key={`PAF_33`}><button onClick={ () => { teeth.includes("33") ? remove(teeth, "33"): add_teeth("33") }} className={`teeth_bg ${ teeth.includes("33") && 'active'}`}>33</button></td>
                                        <td key={`PAF_34`}><button onClick={ () => { teeth.includes("34") ? remove(teeth, "34"): add_teeth("34") }} className={`teeth_bg ${ teeth.includes("34") && 'active'}`}>34</button></td>
                                        <td key={`PAF_35`}><button onClick={ () => { teeth.includes("35") ? remove(teeth, "35"): add_teeth("35") }} className={`teeth_bg ${ teeth.includes("35") && 'active'}`}>35</button></td>
                                        <td key={`PAF_36`}><button onClick={ () => { teeth.includes("36") ? remove(teeth, "36"): add_teeth("36") }} className={`teeth_bg ${ teeth.includes("36") && 'active'}`}>36</button></td>
                                        <td key={`PAF_37`}><button onClick={ () => { teeth.includes("37") ? remove(teeth, "37"): add_teeth("37") }} className={`teeth_bg ${ teeth.includes("37") && 'active'}`}>37</button></td>
                                        <td key={`PAF_38`}><button onClick={ () => { teeth.includes("38") ? remove(teeth, "38"): add_teeth("38") }} className={`teeth_bg ${ teeth.includes("38") && 'active'}`}>38</button></td>
                                    </tr>
                                </tbody>
                            </table>
                        }
                        
                        <div className="row">
                            <div className="col-md-12">
                                <button onClick={ () => finalData()} className="btn btn-primary w-100">Add Product</button>
                                <br />
                                <br />
                            </div>
                        </div>
                        
                        
                        <table className="table table-striped table-bordered">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Category</th>
                                    <th>Category Type</th>
                                    <th>Product</th>
                                    <th>Teeth</th>
                                    <th>Units</th>
                                    <th>Unit rate</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    tempData.map( (v, i) =>
                                        <tr key={`R_${i}`}>
                                            <td><strong>{i+1}</strong></td>
                                            <td><strong>{v.categoryTitle}</strong></td>
                                            <td><strong>{v.categoryTypeTitle}</strong></td>
                                            <td><strong>{v.productListTitle}</strong></td>
                                            <td style={{width: '100px'}}>
                                                {
                                                    (v.categoryTitle != 'Aligner' && v.categoryTitle != 'Aligners') ? <Teeth values={v.teeth} /> : 
                                                    <table key={`alg_${i+1}`} className={`teeth_table`}>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    Upper Alinger
                                                                </td>
                                                                <td>
                                                                    {v.aligners.upperSet}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    Lower Alinger
                                                                </td>
                                                                <td>
                                                                    {v.aligners.lowerSet}
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                }
                                            </td>
                                            <td style={{textAlign: 'right'}}><strong>{v.units}</strong></td>
                                            <td style={{textAlign: 'right'}}><strong>{v.unitPrice}</strong></td>
                                            <td style={{textAlign: 'right'}}><strong>{(v.unitPrice * v.units)}</strong></td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
        </>
    )
}

export default AddProduct