import React, { useEffect, useState } from "react"
import {
    Container,
    Row,
    Col
} from "reactstrap"
//Import Breadcrumb
import Datatable from "components/Datatables/datatable"
import "react-datepicker/dist/react-datepicker.css";
import { post } from "helpers/api_helper"
import moment from "moment";
import { useLocation } from 'react-router-dom';

const OrderformNLReport = () => {
    const location = useLocation();

    const queryParams = new URLSearchParams(location.search);
    const client = queryParams.get('client');
    const month = queryParams.get('month');
    let total = 0;
    
    const [rowData, setRowData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const fatchdata = async () => {
        setIsLoading(true)
        setRowData([])
        const res = await post(`api/reports/orderforms?pagination=false`, {client: client, month: month});
        if(res.status == 200){
            setRowData(res.response?.data);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        fatchdata();
    }, [])

    return (
        <React.Fragment>

            {
                isLoading ? "Loadingdata...":
                <table 
                    style={{ 
                        width: '100%', 
                        margin: '0 auto', 
                        borderCollapse: 'collapse' 
                    }}
                >
                    <thead>
                        <tr>
                            <th style={{ border: '1px solid black' }}>#</th>
                            <th style={{ border: '1px solid black' }}>Client Name</th>
                            <th style={{ border: '1px solid black' }}>Date #</th>
                            <th style={{ border: '1px solid black' }}>Order Form #</th>
                            <th style={{ border: '1px solid black' }}>Order #</th>
                            <th style={{ border: '1px solid black' }}>Order Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rowData?.map((v, i) => {
                                total += (parseFloat(v.order_value) * 1.05);
                                return (
                                    <tr key={`LDT_${i + 1}`}>
                                        <td style={{ border: '1px solid black' }}>{v.sr_no}</td>
                                        <td style={{ border: '1px solid black' }}>{v.clientname} ({v.client})</td>
                                        <td style={{ border: '1px solid black' }}>{moment(v.orderform_date).format('DD-MM-YYYY')}</td>
                                        <td style={{ border: '1px solid black' }}>{v.orderform_number}</td>
                                        <td style={{ border: '1px solid black' }}>{v.order_id}</td>
                                        <td style={{ border: '1px solid black' }}>{(parseFloat(v.order_value) * 1.05).toFixed(2)}</td>
                                    </tr>
                                );
                            })
                        }
                        <tr key={`LDT_total`}>
                            <td colSpan={3}></td>
                            <td colSpan={2} style={{ textAlign: 'right' }}><h4>Total Order Form Value</h4></td>
                            <td style={{ border: '1px solid black' }}><h4>{total.toFixed(2)}</h4></td>
                        </tr>
                    </tbody>
                </table>
            }


        </React.Fragment>
    )
}

export default OrderformNLReport
