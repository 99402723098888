import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"

// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { Link, withRouter } from "react-router-dom"

//i18n
import { withTranslation } from "react-i18next"
import { decryptData } from "services/encription_service"

const genralReports = [
  {
    url: '/reports/charts',
    title: 'Chart Report'
  },
  {
    url: '/reports/orders',
    title: 'Orders Report'
  },
  {
    url: '/reports/sales',
    title: 'Sales Reports'
  },
  {
    url: '/reports/orderform',
    title: 'Orderform Report'
  },
  {
    url: '/reports/payment',
    title: 'Payment Reports'
  },
  {
    url: '/reports/invoices',
    title: 'Invoice Reports'
  },
  {
    url: '/reports/citiwise',
    title: 'Citiwise Reports'
  },
  {
    url: '/reports/zonewise',
    title: 'Zonewise Reports'
  },
  {
    url: '/reports/redo-orders',
    title: 'Redo Orders Reports'
  },
  {
    url: '/reports/currection-orders',
    title: 'Currection Order'
  },
  {
    url: '/reports/zonewise-sales',
    title: 'Zonewise Sales'
  },
  {
    url: '/reports/citiwise-sales',
    title: 'Citiwise Sales'
  },
  {
    url: '/reports/lab-slip',
    title: 'Lab Slip Reports'
  },
  {
    url: '/reports/target-archived',
    title: 'Target Archived'
  },
  {
    url: '/reports/daywise-target-archived',
    title: 'Daywise Target'
  },
  {
    url: '/reports/monthly-target-archived',
    title: 'Monthly Target'
  },
  {
    url: '/reports/no-order-clinets',
    title: 'No Order Clinets'
  },
  {
    url: '/reports/lmsd-report',
    title: 'LMSD Reports'
  },
  {
    url: '/reports/out-standing-report',
    title: 'Out-standing Report'
  },
  {
    url: '/reports/orcp-report',
    title: 'ORCP Reports'
  },
  {
    url: '/reports/pending-challans',
    title: 'Pending Challans'
  },
]

const analyticReports = [
  {
    url: '',
    title:'Analyse Reports'
  },{
    url: '',
    title:'Day wise Dispatch'
  },{
    url: '',
    title:'Daily Analyse'
  },{
    url: '',
    title:'Monthly Analyse'
  },{
    url: '',
    title:'MA For Stations'
  },{
    url: '',
    title:'Monthly Productivity'
  },{
    url: '',
    title:'Product Analyze'
  },{
    url: '',
    title:'Doctor Ranking'
  },{
    url: '',
    title:'Source Wise Reports'
  },{
    url: '',
    title:'BenchMark Reports'
  }
]


const SidebarContent = props => {
  const ref = useRef()
  const [isAdmin, setIsAdmin] = useState(false);
  const [userModules, setUserModules] = useState(false);

  useEffect(async() => {
    const state = JSON.parse(localStorage.getItem('authUser'))
    
    if(state.user_role == 'owner'){
      setIsAdmin('owner');
    }
    if(state.role == 'Superadmin'){
      setIsAdmin('superadmin');
    }

    const modules = await decryptData(localStorage.getItem('userdetails'))
    
    let menus = {};
    if(modules){
      menus = JSON.parse(JSON.parse(modules))
    }
    
    const filteredData = {};
    for (const key in menus) {
        if (menus.hasOwnProperty(key)) {
            filteredData[key] = Object.fromEntries(
                Object.entries(menus[key]).filter(([subKey, value]) => Object.values(value).includes(true))
            );
        }
    }
    setUserModules(filteredData)
  },[])
  
  useEffect(() => {
    if(userModules){
        ref.current.recalculate()
        const pathName = props.location.pathname
    
        const initMenu = () => {
          new MetisMenu("#side-menu")
          let matchingMenuItem = null
          const ul = document.getElementById("side-menu")
          const items = ul.getElementsByTagName("a")
          for (let i = 0; i < items.length; ++i) {
            if (pathName === items[i].pathname) {
              matchingMenuItem = items[i]
              break
            }
          }
          if (matchingMenuItem) {
            activateParentDropdown(matchingMenuItem)
          }
        }
        initMenu()
    }
  }, [userModules])

  
  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false
    }
    scrollElement(item);
    return false
  }

  return (
    <React.Fragment>
        <SimpleBar className="h-100" ref={ref}>
          <div id="sidebar-menu">
            <ul className="metismenu list-unstyled" id="side-menu">
              <li className="menu-title">{props.t("Menu")} </li>
              <li>
                <Link to="/dashboard" className="">
                  <i className="bx bx-home-circle"></i>
                  <span>{props.t("Dashboards")}</span>
                </Link>
              </li>

              <li className="menu-title">{props.t("Masters")}</li>

              {
                (isAdmin == 'owner') && (
                  <>
                    <li><Link to="/company">{props.t("Company")}</Link></li>
                  </>
                )
              }
              
              { 
                (isAdmin == 'superadmin') && (
                    <>
                      <li><Link to="/branches">{props.t("Branchs")}</Link></li>
                      <li><Link to="/employees">{props.t("Employees")}</Link></li>
                      <li><Link to="/roles">{props.t("Roles")}</Link></li> 
                    </>
                  )
              }

              {
                (userModules?.hasOwnProperty('masters')) &&
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-layout"></i>
                    <span>{props.t("Masters")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">

                    {
                      (userModules?.masters?.hasOwnProperty('department')) && 
                      <li><Link to="/department">{props.t("Department")}</Link></li>
                    }

                    {
                      (userModules?.masters?.hasOwnProperty('designation')) && 
                      <li><Link to="/designation">{props.t("Designation")}</Link></li>
                    }
    
                    {
                      (userModules?.masters?.hasOwnProperty('lab_department')) && 
                      <li><Link to="/lab-department">{props.t("Lab Department")}</Link></li>
                    }
      
                    {
                      (userModules?.masters?.hasOwnProperty('countries')) && 
                      <li><Link to="/countries">{props.t("Countries")}</Link></li>
                    }
        
                    {
                      (userModules?.masters?.hasOwnProperty('state')) && 
                      <li><Link to="/states">{props.t("State")}</Link></li>
                    }
            
                    {
                      (userModules?.masters?.hasOwnProperty('cities')) && 
                      <li><Link to="/cities">{props.t("Cities")}</Link></li>
                    }

                    {
                      (userModules?.masters?.hasOwnProperty('stations')) && 
                      <li><Link to="/stations">{props.t("Stations")}</Link></li>
                    }

                    {
                      (userModules?.masters?.hasOwnProperty('zones')) && 
                      <li><Link to="/zones">{props.t("Zones")}</Link></li>
                    }

                    {
                      (userModules?.masters?.hasOwnProperty('source')) && 
                      <li><Link to="/source">{props.t("Source")}</Link></li>
                    }

                    {
                      (userModules?.masters?.hasOwnProperty('qalifications')) && 
                      <li><Link to="/qalifications">{props.t("Qualifications")}</Link></li>
                    }

                    {
                      (userModules?.masters?.hasOwnProperty('correction_template')) && 
                      <li><Link to="/correction-template">{props.t("Correction Template")}</Link></li>
                    }
                    {
                      (userModules?.masters?.hasOwnProperty('locations')) && 
                      <li><Link to="/locations">{props.t("Locations")}</Link></li>
                    }
                  </ul>
                </li>
              }

              {
                (userModules?.hasOwnProperty('job_master')) &&
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-barcode"></i>
                    <span>{props.t("Job Master")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {
                      (userModules?.job_master?.hasOwnProperty('shade_guide')) && 
                      <li><Link to="/Job/shade-guide">{props.t("Shade Guide")}</Link></li>
                    }
                    {
                      (userModules?.job_master?.hasOwnProperty('shade')) && 
                      <li><Link to="/Job/shade">{props.t("Shade")}</Link></li>
                    }
                  </ul>
                </li>
              }

              {
                (userModules?.hasOwnProperty('product_master')) &&
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-cart-alt"></i>
                    <span>{props.t("Product Master")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li><Link to="/product/category">{props.t("Category")}</Link></li>
                    <li><Link to="/product/type">{props.t("Type")}</Link></li>
                    <li><Link to="/products">{props.t("Products")}</Link></li>
                    <li><Link to="/product/warranty">{props.t("Warranty")}</Link></li>
                    {/* <li><Link to="/product/group">{props.t("Group")}</Link></li> */}
                  </ul>
                </li>
              }

              {
                (userModules?.hasOwnProperty('employee_master')) &&
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-user-circle"></i>
                    <span>{props.t("Employee Master")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    {
                      (userModules?.employee_master?.hasOwnProperty('employees')) && 
                      <li><Link to="/employees">{props.t("Employees")}</Link></li>
                    }
                  </ul>
                </li>
              }

              {
                (userModules?.hasOwnProperty('client_master')) &&
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bxs-group"></i>
                    <span>{props.t("Client Master")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li><Link to="/client/parents">{props.t("Parents")}</Link></li>
                    <li><Link to="/clients">{props.t("Clients")}</Link></li>
                    <li><Link to="/clients/blocked">{props.t("Blocked Clients")}</Link></li>
                    {/* <li><Link to="/client/priority">{props.t("Priority")}</Link></li> */}
                    {/* <li><Link to="/client/parents">{props.t("Invoice Capping")}</Link></li> */}
                  </ul>
                </li>
              }

              {
                !isAdmin && <li className="menu-title">{props.t("Activity")}</li>
              }

              {
                (userModules?.hasOwnProperty('orders')) &&
                <li>
                  <Link to="/orders">
                    <i className="bx bx-package"></i>
                    <span>{props.t("Orders")}</span>
                  </Link>
                </li>
              }
              
              {
                (userModules?.hasOwnProperty('challans')) &&
                    <>
                      <li>
                        <Link to="/orderform/create">
                          <i className="bx bx-copy-alt"></i>
                          <span>{props.t("Create OrderForm")}</span>
                        </Link>
                      </li>
                      <li>
                        <Link to="/orderforms">
                          <i className="bx bx-copy-alt"></i>
                          <span>{props.t("OrderForms")}</span>
                        </Link>
                      </li>
                    </>
              }
              {(userModules?.hasOwnProperty('challans')) &&
                    <li>
                      <Link to="/challans">
                        <i className="fab fa-wpforms"></i>
                        <span>{props.t("Challans")}</span>
                      </Link>
                    </li>

                // <li>
                //   <Link to="/#" className="has-arrow ">
                //     <i className="bx bx-book-open"></i>
                //     <span>{props.t("Challans")}</span>
                //   </Link>
                //   <ul className="sub-menu" aria-expanded="false">
                //   </ul>
                // </li>
              }

              {
                (userModules?.hasOwnProperty('invoice')) &&
                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-cart-alt"></i>
                    <span>{props.t("Invoice")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/invoices/create">
                        <span>{props.t("Create Invoice")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/invoices/proforma">
                        <span>{props.t("Proforma Invoices")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/invoices">
                        <span>{props.t("Commercial Invoices")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>
              }

                <li>
                  <Link to="/#" className="has-arrow ">
                    <i className="bx bx-cart-alt"></i>
                    <span>{props.t("Accounts")}</span>
                  </Link>
                  <ul className="sub-menu" aria-expanded="false">
                    <li>
                      <Link to="/payments">
                        <span>{props.t("Payments")}</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/duecollection">
                        <span>{props.t("Due Collection")}</span>
                      </Link>
                    </li>
                  </ul>
                </li>

              {
                !isAdmin && (
                  <>
                    <li className="menu-title">{props.t("Reports")}</li>
                    <li>
                      <Link to="/processing">
                        <i className="far fa-circle"></i>
                        <span>{props.t("Processing")}</span>
                      </Link>
                    </li>

                    <li>
                      <Link to="/#" className="has-arrow ">
                        <i className="fas fa-chart-pie"></i>
                        <span>{props.t("Genral Reports")}</span>
                      </Link>
                      <ul>
                        {
                          genralReports?.map((v, i) => (
                            <li key={`gr_${i}`}>
                              <Link to={v.url}>
                                <i className="far fa-circle"></i>
                                <span>{props.t(v.title)}</span>
                              </Link>
                            </li>
                          ))
                        }
                      </ul>
                    </li>

                    <li>
                      <Link to="/#" className="has-arrow ">
                        <i className="mdi mdi-google-analytics"></i>
                        <span>{props.t("Analysis Reports")}</span>
                      </Link>
                      <ul>
                        {
                          analyticReports?.map((v, i) => (
                            <li key={`gr_${i}`}>
                              <Link to={v.url}>
                                <i className="far fa-circle"></i>
                                <span>{props.t(v.title)}</span>
                              </Link>
                            </li>
                          ))
                        }
                      </ul>
                    </li>
                  </>
                )
              }

              

              <li className="menu-title">{props.t("Settings")}</li>
              <li>
                <Link to="/settings/company">
                  <i className="far fa-circle"></i>
                  <span>{props.t("Company Settings")}</span>
                </Link>
              </li>
              <li>
                <Link to="/settings/notifications">
                  <i className="far fa-circle"></i>
                  <span>{props.t("Notifications")}</span>
                </Link>
              </li>

              <li>
                <Link to="/settings/backup">
                  <i className="far fa-circle"></i>
                  <span>{props.t("Backup")}</span>
                </Link>
              </li>

            </ul>
          </div>
        </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
}

export default withRouter(withTranslation()(SidebarContent))
