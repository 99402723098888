import { get, post } from "helpers/api_helper"
import { call, put, takeEvery } from "redux-saga/effects"
import { CREATE_CHALLAN, PRINT_DATA } from './actionTypes'
import { printDataActionSuccess } from './actions'

// Api Calls
const PostCall = ({Obj, url}) => post(url, Obj)

function* actionCreateChallan({ payload: { data, callback } }){
    try {
        const res = yield call(PostCall, data)
        if(res?.status === 200)
            yield callback(res.response)//put(GetAllChallansSuccess(res.response))

    } catch (error) {}
}

function* actionPrintData({payload : {data}}){
    try {
        const res = yield get(`api/challans/printChallan/${data.CN}`);
        if(res?.status === 200){
            yield put(printDataActionSuccess(res.response))
            yield call(data.callback, res.response)
        }
    } catch (error) {}
}



function* ChallanSaga() {
    yield takeEvery(CREATE_CHALLAN, actionCreateChallan)
    yield takeEvery(PRINT_DATA, actionPrintData)
}

export default ChallanSaga