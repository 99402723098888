export const GET_ALL_OPTIONS = 'GET_ALL_OPTIONS'
export const GET_ALL_OPTIONS_SUCCESS = 'GET_ALL_OPTIONS_SUCCESS'
export const GET_ALL_ORDERS = 'GET_ALL_ORDERS'
export const GET_ALL_ORDERS_SUCCESS = 'GET_ALL_ORDERS_SUCCESS'
export const ADD_ORDER = 'ADD_ORDER'
export const ADD_ORDER_SUCCESS = 'ADD_ORDER_SUCCESS'
export const GET_SINGLE_ORDER = 'GET_SINGLE_ORDER'
export const GET_SINGLE_ORDER_SUCCESS = 'GET_SINGLE_ORDER_SUCCESS'
export const GET_JOBCARD = 'GET_JOBCARD'
export const GET_CASENUMBER = 'GET_CASENUMBER'
export const GET_CASENUMBER_SUCCESS = 'GET_CASENUMBER_SUCCESS'
export const GET_ORDER_OPTION = 'GET_ORDER_OPTION'
export const GET_ORDER_OPTION_SUCCESS = 'GET_ORDER_OPTION_SUCCESS'
export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_RX_STATE = 'UPDATE_RX_STATE'
export const EMPTY_ORDERS = 'EMPTY_ORDERS'
