const Teeth = ({ values, isBorder = true, _class = "", isJobcard = false, callback=() => {} }) => {
    const border = isBorder ? 'circle' : ''

    return (
            <>
                <div key={Math.round(Math.random() * 100)} className="d-flex mt-2 mb-1" style={{justifyContent: 'space-evenly'}}>
                    <div key="THT_18" onClick={() => { callback('18') }} className={values.includes('18') ? border: 'nonselth'}>18</div>
                    <div key="THT_17" onClick={() => { callback('17') }} className={values.includes('17') ? border: 'nonselth'}>17</div>
                    <div key="THT_16" onClick={() => { callback('16') }} className={values.includes('16') ? border: 'nonselth'}>16</div>
                    <div key="THT_15" onClick={() => { callback('15') }} className={values.includes('15') ? border: 'nonselth'}>15</div>
                    <div key="THT_14" onClick={() => { callback('14') }} className={values.includes('14') ? border: 'nonselth'}>14</div>
                    <div key="THT_13" onClick={() => { callback('13') }} className={values.includes('13') ? border: 'nonselth'}>13</div>
                    <div key="THT_12" onClick={() => { callback('12') }} className={values.includes('12') ? border: 'nonselth'}>12</div>
                    <div key="THT_11" onClick={() => { callback('11') }} className={values.includes('11') ? border: 'nonselth'}>11</div>
                    <div key="THT_21" onClick={() => { callback('21') }} className={values.includes('21') ? border: 'nonselth'}>21</div>
                    <div key="THT_22" onClick={() => { callback('22') }} className={values.includes('22') ? border: 'nonselth'}>22</div>
                    <div key="THT_23" onClick={() => { callback('23') }} className={values.includes('23') ? border: 'nonselth'}>23</div>
                    <div key="THT_24" onClick={() => { callback('24') }} className={values.includes('24') ? border: 'nonselth'}>24</div>
                    <div key="THT_25" onClick={() => { callback('25') }} className={values.includes('25') ? border: 'nonselth'}>25</div>
                    <div key="THT_26" onClick={() => { callback('26') }} className={values.includes('26') ? border: 'nonselth'}>26</div>
                    <div key="THT_27" onClick={() => { callback('27') }} className={values.includes('27') ? border: 'nonselth'}>27</div>
                    <div key="THT_28" onClick={() => { callback('28') }} className={values.includes('28') ? border: 'nonselth'}>28</div>
                </div>
                <div key={Math.round(Math.random() * 100)} className="d-flex mt-1 mb-2" style={{justifyContent: 'space-evenly'}}>
                    <div key="THT_48" onClick={() => { callback('48')}} className={values.includes('48') ? border: 'nonselth'}>48</div>
                    <div key="THT_47" onClick={() => { callback('47')}} className={values.includes('47') ? border: 'nonselth'}>47</div>
                    <div key="THT_46" onClick={() => { callback('46')}} className={values.includes('46') ? border: 'nonselth'}>46</div>
                    <div key="THT_45" onClick={() => { callback('45')}} className={values.includes('45') ? border: 'nonselth'}>45</div>
                    <div key="THT_44" onClick={() => { callback('44')}} className={values.includes('44') ? border: 'nonselth'}>44</div>
                    <div key="THT_43" onClick={() => { callback('43')}} className={values.includes('43') ? border: 'nonselth'}>43</div>
                    <div key="THT_42" onClick={() => { callback('42')}} className={values.includes('42') ? border: 'nonselth'}>42</div>
                    <div key="THT_41" onClick={() => { callback('41')}} className={values.includes('41') ? border: 'nonselth'}>41</div>
                    <div key="THT_31" onClick={() => { callback('31')}} className={values.includes('31') ? border: 'nonselth'}>31</div>
                    <div key="THT_32" onClick={() => { callback('32')}} className={values.includes('32') ? border: 'nonselth'}>32</div>
                    <div key="THT_33" onClick={() => { callback('33')}} className={values.includes('33') ? border: 'nonselth'}>33</div>
                    <div key="THT_34" onClick={() => { callback('34')}} className={values.includes('34') ? border: 'nonselth'}>34</div>
                    <div key="THT_35" onClick={() => { callback('35')}} className={values.includes('35') ? border: 'nonselth'}>35</div>
                    <div key="THT_36" onClick={() => { callback('36')}} className={values.includes('36') ? border: 'nonselth'}>36</div>
                    <div key="THT_37" onClick={() => { callback('37')}} className={values.includes('37') ? border: 'nonselth'}>37</div>
                    <div key="THT_38" onClick={() => { callback('38')}} className={values.includes('38') ? border: 'nonselth'}>38</div>
                </div>
            </>
    )
}

export default Teeth