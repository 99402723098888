import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import { get } from 'helpers/api_helper'
import { userModuleData } from 'helpers/basic_helper'
import { CLIENT_URL } from "helpers/url_helper"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row
} from "reactstrap"

const isType = 'client'

const Blocked = () => {
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [error, setError] = useState(false);

    const getData = async() => {
        setIsLoading(true)
        const res = await get(`${CLIENT_URL}/blocked`);
        if(res.status == 200){
            setDataRows(res.response?.map((v, i) => {
                v['sr'] = i+1
                return v
            }) || [])
        }

        setIsLoading(false)
        if(res === undefined){
            setError(true)
        }
    }
    
    useEffect(() => {
        getData()
    }, [])

    const handleStatus = async(ID, status) => {
        setIsLoading(ID)
        const res = await get(`${CLIENT_URL}/unblock/${ID}/${status}`);
        if(res.status) {
            setIsLoading(false)
            getData()
        }
    }

    // table Column Data
    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        },{
            accessorKey: 'clientname',
            header: 'Client Name'
        },{
            accessorKey: 'code',
            header: 'Client Code'
        },{
            accessorKey: 'mobile',
            header: 'Mobile'
        },{
            accessorKey: 'state_title',
            header: 'State'
        },{
            accessorKey: 'country_title',
            header: 'Country'
        },{
            accessorKey: 'city_title',
            header: 'City'
        },{
            accessorKey: 'station_title',
            header: 'Station'
        }
    ];

    useEffect(async() => {
        const data =  await userModuleData('client_master', 'blocked_client')
        setIsActions(data)
        if(data && (data?.cub)){
            data.isBlocking = true;
            setIsActionColumn(true)
        }
    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | {isType}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        pageTitle="Masters"
                        title="Masters"
                        breadcrumbItem="Clients"
                    />

                    <Row>
                        <Col xl="12">
                            {
                                <Mdatatable
                                    enableRowAction={isActionColumn}
                                    userModules={isActions}
                                    title={`All ${isType}`}
                                    columns={datatableData}
                                    data={dataRows}
                                    Other={{
                                        isUnblock: true,
                                        isBlocking:isLoading,
                                        handleStatus:handleStatus,
                                        handleRemove: false
                                    }}
                                    handleAddButton={() => {
                                        setEditableObj(null)
                                        isOpen(true)
                                    }}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Blocked
