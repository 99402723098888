import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row,
    Card,
    CardBody
} from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import { get } from "helpers/api_helper"
import { customFormatDate } from 'helpers/basic_helper'
import { GETORDERFORMS_URL } from 'helpers/url_helper'
import "react-datepicker/dist/react-datepicker.css"
import { useDispatch, useSelector } from "react-redux"
import ClientDropdown from "utils/client_dropdown";
import Datatable from "components/Datatables/datatable";
import Select from 'react-select';
import Flatpickr from "react-flatpickr"
import { sortDateFilters } from 'constants/layout'
import Timezone from "utils/timezone";

const isType = 'orderform'
const Orderform = ({isCall = false}) => {
    const {loading} =  useSelector(state => state.Challans);
    const [challans, setChallans] = useState([]);
    const [challansLoad, setChallansLoad] = useState(false);
    const [selectedRangeValue, setSelectedRangeValue] = useState(null);
    const [sizePerPage, setSizePerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [filters, setFilters] = useState({
        'search': null,
        'client': null,
        'bydate': null,
    });

    const fetch = async(params = false) => {
        setChallansLoad(true)
        const filteredParams = Object.fromEntries(
            Object.entries(params).filter(([_, v]) => v != null && v !== "")
        );
        const queryString = new URLSearchParams(filteredParams).toString();

        const res = await get(`${GETORDERFORMS_URL}?${queryString}`);
        if (res.status == 200) {
            setChallansLoad(false)
            setChallans(res.response)
        }
    }

    useEffect(async () => {
        fetch({
            page: 1,
            sizePerPage: 10
        })
    }, [])

    const printPdf = (orderform) => {
        const CN = orderform.orderform_number;
        window.open(`/orderform/print?_orderform=${CN}`, '_blank');
    };

    const filterOrderHandler =(data = null) => {
        if(data?.page){
            setPage(data?.page)
            setSizePerPage(data?.sizePerPage)
            setFilters(p => ({...p, searchTerm: data?.searchTerm}))
        }

        fetch({
            page: data?.page || page,
            sizePerPage: data?.sizePerPage || sizePerPage,
            searchTerm: data?.searchTerm || '',
            search: filters?.search || '',
            client: filters?.client?.value || '',
            _startdate: filters?.bydate ? filters?.bydate[0] : '',
            _enddate: filters?.bydate ? filters?.bydate[1] : '',
        })
    }

    /**
     * DataTable with Condition based row formatting
     */
    let newDatatableData = [
        {
            dataField: 'sr_no',
            text: '#',
            sort: true
        }, {
            dataField: 'orderform_number',
            text: 'OrderForm #',
            formatter: (cell, row,  rowIndex, extraData) => {
                return <a title="Click to View Orderform" href={`/orderform/print?_orderform=${row.orderform_number}`} target="_blank" rel="noreferrer">
                    # {row.orderform_number} <br /> <span className={`ms-2 badge badge-soft-warning font-size-12`}>{customFormatDate(row?.orderform_date, 'DD MMM, YY')}</span>
                </a>
            }
        }, {
            dataField: 'order_id',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => <span className={`text-success font-size-14`}>{row.order_id}</span>,
            text: 'Order #'
        }, {
            dataField: 'clientname',
            text: 'Client #'
        }, {
            dataField: 'order_date',
            text: 'Order Date',
            formatter: (cell, row,  rowIndex, extraData) => customFormatDate(row?.order_date, 'DD MMM, YY')
        }, {
            dataField: 'due_date',
            text: 'Due Date',
            formatter: (cell, row,  rowIndex, extraData) => customFormatDate(row?.due_date, 'DD MMM, YY')
        }
    ];

    if(isCall){
        return <Datatable
            keyField={'id'}
            title={`All Orderforms`}
            isAdd={false}
            isSearch={true}
            showTableOnly={isCall}
            columns={newDatatableData}
            rows={challans?.rows || []}
            rowsLength={challans?.total_size || 0}
            loading={challansLoad}
            ssr={filterOrderHandler}
        />
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>{isType} | KS-Lab</title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />
                    
                    <Row>
                        <Col xl='12'>
                            <Card>
                                <CardBody>
                                    <Row>
                                        <Col xl="3">
                                            <label>Search by OrderForm or Modal Number</label>
                                            <input type="number" className="form-control" onChange={(e) => setFilters(prev => ({...prev, search: e.target.value}))} placeholder="Please Search using Order or Modal Number" />
                                        </Col>
                                        <Col xl="2">
                                            <ClientDropdown callback={(e) => setFilters(prev => ({...prev, client: e})) } />
                                        </Col>
                                        <Col xl={2}>
                                            <label>Day wise Filter</label>
                                            <Select
                                                onChange={(e) => {
                                                    setSelectedRangeValue(e)
                                                    handleFilterChange(e.value)
                                                }}
                                                isClearable={true}
                                                value={selectedRangeValue || '' }
                                                options={sortDateFilters || []}
                                                name='clients'
                                                required={false}
                                                placeholder={'Select Filter'}
                                            />
                                        </Col>
                                        <Col xl={4}>
                                            <label>Date Range</label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                onChange={
                                                    (e) => {
                                                        const dates = e.map(dateString => Timezone(dateString));
                                                        setFilters(prev => ({...prev, bydate: dates}))
                                                    }
                                                }
                                            />
                                        </Col>
                                        <Col className="text-end">
                                            <button className="btn btn-primary mt-4" onClick={filterOrderHandler}>Filter</button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    
                    {/* Datatables */}
                    <Datatable
                        keyField={'id'}
                        title={`All Orderforms`}
                        isAdd={false}
                        isSearch={true}
                        columns={newDatatableData}
                        rows={challans?.rows || []}
                        rowsLength={challans?.total_size || 0}
                        loading={challansLoad}
                        ssr={filterOrderHandler}
                    />

                </Container>
            </div>
        </React.Fragment>
    )
}

export default Orderform
