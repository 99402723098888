import { call, takeEvery, put } from "redux-saga/effects"
import { get, post } from "helpers/api_helper"

import {
    GET_ALL_OPTIONS, 
    GET_ALL_ORDERS,
    ADD_ORDER,
    GET_SINGLE_ORDER,
    GET_JOBCARD,
    GET_CASENUMBER,
    GET_ORDER_OPTION,
} from './actionTypes'

import {
    getAllOptionsSuccess,
    getOrdersSuccess,
    GetCaseNumberSuccess,
    orderOptionsSuccess
} from "./actions"

// Api Calls
const getAllRows = url => get(url) 
const addOrderCall = ({Obj, url}) => post(url, Obj)

// Action Calls
function* actionGetAllOptions({ payload: { url, isType } }) {
    try {
        const res = yield call(getAllRows, url)
        if(res?.response)
            yield put(getAllOptionsSuccess({data:res.response, isType:isType}))

    } catch (error) {}
}

// Action Calls
function* actionGetAllOrders({ payload: { url, isType } }) {
    try {
        const res = yield call(getAllRows, url)
        if(res?.response)
            yield put(getOrdersSuccess({data: res.response}))

    } catch (error) {}
}

// Add Order Action Calls
function* actionAddOrder({ payload: { Obj, url, isType, callback } }) {
    try {
        const res = yield call(addOrderCall, {Obj, url})
        if(res?.response)
            yield callback()

    } catch (error) {}
}

// Add Order Action Calls
function* actionGetSingleOrder({ payload: { data, cb}}) {
    try {
        const res = yield call(getAllRows, data.url)
        if(res?.response)
            yield cb(res.response)

    } catch (error) {}
}

// Get Jobcard Action Calls
function* actionGetJobCard({ payload: { data, cbJobcard}}) {
    try {
        const res = yield call(getAllRows, data.url)
        if(res?.response)
            yield cbJobcard(res.response)

    } catch (error) {}
}


function* actionClientData({payload: {url}}){
    try{
        const res = yield call(getAllRows, url)
        if(res.status == 200){
            yield put(GetCaseNumberSuccess({data:res.response}))
        }
    }catch (error) {}
}

function* actionOrderOptions({payload: {url}}){
    try{
        const res = yield call(getAllRows, url)
        if(res.status == 200){
            yield put(orderOptionsSuccess({data:res.response}))
        }
    }catch (error) {}
}

function* OrderSaga() {
    yield takeEvery(ADD_ORDER, actionAddOrder)
    yield takeEvery(GET_ALL_OPTIONS, actionGetAllOptions)
    yield takeEvery(GET_ALL_ORDERS, actionGetAllOrders)
    yield takeEvery(GET_SINGLE_ORDER, actionGetSingleOrder)
    yield takeEvery(GET_JOBCARD, actionGetJobCard)
    yield takeEvery(GET_CASENUMBER, actionClientData)
    yield takeEvery(GET_ORDER_OPTION, actionOrderOptions)
}
  
export default OrderSaga
  