// utils/numberToWords.js
const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
const teens = ["Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
const tens = ["", "Ten", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
const thousands = ["", "Thousand", "Lakh", "Crore"];

function numberToWords(num) {
  if (num === 0) return "Zero";

  let numStr = num.toString();
  let [integerPart, decimalPart] = numStr.split('.');

  let words = convertIntegerPartToWords(parseInt(integerPart)) + (decimalPart ? " and " + convertDecimalPartToWords(decimalPart) + " paise only" : " only");

  return words.trim();
}

function convertIntegerPartToWords(num) {
  if (num === 0) return "";

  if (num < 10) return units[num];
  if (num < 20) return teens[num - 11];
  if (num < 100) return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? " " + units[num % 10] : "");

  if (num < 1000) return units[Math.floor(num / 100)] + " Hundred " + (num % 100 !== 0 ? convertIntegerPartToWords(num % 100) : "");

  let words = "";
  let i = 0;
  let parts = [];

  while (num > 0) {
    let part = num % (i === 0 ? 1000 : 100);
    if (part > 0) {
      parts.push(convertIntegerPartToWords(part) + " " + thousands[i]);
    }
    num = Math.floor(num / (i === 0 ? 1000 : 100));
    i++;
  }

  words = parts.reverse().join(" ");

  return words.trim();
}

function convertDecimalPartToWords(decimalPart) {
  if (decimalPart.length > 2) {
    decimalPart = decimalPart.substring(0, 2); // Take only up to two decimal places
  }

  return convertIntegerPartToWords(parseInt(decimalPart));
}

export default numberToWords;
