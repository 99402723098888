import {
    GET_EMPLOYEE_ALL_ROWS,
    GET_EMPLOYEE_ALL_ROWS_SUCCESS,
    ADD_EMPLOYEE_RECORD,
    ADD_EMPLOYEE_RECORD_SUCCESS,
    REMOVE_EMPLOYEE_RECORD,
    UPDATE_EMPLOYEE_RECORD,
    PUSH_EMPLOYEE_NEW_RECORD
} from './actionType'

const INIT_STATE = {
    employees: [],
    loading: false,
}
  
const EmployeeR = (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_EMPLOYEE_ALL_ROWS:{
            return state
        }
        case ADD_EMPLOYEE_RECORD:
        case REMOVE_EMPLOYEE_RECORD:
            return {
              ...state,
              loading: true
            }
        case ADD_EMPLOYEE_RECORD_SUCCESS:
            return {
              ...state,
              loading: false,
            }
        case PUSH_EMPLOYEE_NEW_RECORD:
            return {
                ...state,
                loading: false,
                employees:[...state.employees, action.payload.data.data]
            }
        case GET_EMPLOYEE_ALL_ROWS_SUCCESS:
            return {
                ...state,
                employees: action.payload.data
            }
        case UPDATE_EMPLOYEE_RECORD:
            const emp = state.employees.map(item => {
                if (action.payload.isType == 'status' && item.id == action.payload.data.id){
                    item.status = action.payload.data.status.toString()
                }else{
                    if (item.id == action.payload.data.id){
                        item.id = action.payload.data.id
                        item.code = action.payload.data.code
                        item.designation = action.payload.data.designation
                        item.email = action.payload.data.email
                        item.firstname = action.payload.data.firstname
                        item.gender = action.payload.data.gender
                        item.lastname = action.payload.data.lastname
                        item.location = action.payload.data.location
                        item.middlename = action.payload.data.middlename
                        item.mobile = action.payload.data.mobile
                        item.status = action.payload.data.status
                    }
                }
                return item;
            })
            
            return {
                ...state,
                loading: false,
                employees: emp
            }
        default:
            return state
    }
}

export default EmployeeR