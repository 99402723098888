import React from 'react'
import {
    Row,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Button
} from "reactstrap"
import { Link } from "react-router-dom";
import { useSelector } from "react-redux"


const PrintModel = ({ isOpen = false, Viewtype = 'challan', closeModel}) => {
    let allOrderunits = 0
    let allOrderPrice = 0
    const {printdata} =  useSelector(state => state.Invoices);
        
    return (
        <Modal
            isOpen={isOpen}
            role="dialog"
            autoFocus={true}
            centered
            size="xl"
            data-toggle="modal"
            toggle={() => closeModel(!isOpen)} >
            <div className="modal-content">
                <ModalHeader
                    toggle={() => closeModel(!isOpen)}
                >
                    {`Invoice - `} <span className={`badge badge-pill badge-soft-${printdata?.invoice?.status == 1 ? "danger":(printdata?.invoice?.type == 'new'  ? 'success' : printdata?.invoice?.type == 'redo' ? 'danger' : 'warning')} font-size-18`}>{printdata?.invoice?.invoice_number} {printdata?.invoice?.status == 1 && '- Cancelled' }
                    </span>
                </ModalHeader>
                <ModalBody>
                    <Row>
                        <div style={{border: '1px solid #000', height: '100%'}}>
                            <table style={{width: '100%'}}>
                                <thead>
                                <tr style={{borderBottom: '1px solid #000'}}>
                                    <th width={150}>Item</th>
                                    <th className='text-center'>Products</th>
                                    <th>Units</th>
                                    <th width={120} className='text-center'>Total</th>
                                </tr>
                                </thead>
                                <tbody>
                                    {
                                        printdata?.orders.map((v, i) => {
                                            let singleOrderunits = 0
                                            let singleOrderPrice = 0

                                            const innertable = <table style={{width: '100%'}} border={0}>
                                                <thead>
                                                    <tr>
                                                        <th width={360}>Product</th>
                                                        <th width={60}>Units</th>
                                                        <th width={150}>Rate</th>
                                                        <th width={170}>Total</th>
                                                        <th width={170}>Discount (%)</th>
                                                        <th width={160}>After discount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        v?.products.map((pv, i) => {
                                                            const total = (parseFloat(pv.unit) * parseFloat(pv.unitprice))
                                                            const discount_amount = total - (parseFloat(pv.discount)/100 * 100)

                                                            singleOrderunits += parseFloat(pv.unit)
                                                            singleOrderPrice += discount_amount

                                                            return <tr key={`op_${i}`}>
                                                                <td>{pv.product}</td>
                                                                <td style={{textAlign: 'center'}}>{pv.unit}</td>
                                                                <td>₹ {parseFloat(pv.unitprice).toFixed(2)}</td>
                                                                <td>₹ {total.toFixed(2)}</td>
                                                                <td>{parseFloat(pv.discount).toFixed(2)}</td>
                                                                <td>₹ {discount_amount.toFixed(2)}</td>
                                                            </tr>
                                                        })
                                                    }
                                                </tbody>
                                            </table>

                                            allOrderunits += singleOrderunits
                                            allOrderPrice += singleOrderPrice

                                            return <tr key={`o_${i}`} style={{borderBottom: '1px solid #000'}}>
                                                <td>Order #{v.order_number} <br />
                                                 DATE #{v.order_date}</td>
                                                <td>{innertable}</td>
                                                <td>{singleOrderunits}</td>
                                                <td className='text-center'>₹ {singleOrderPrice.toFixed(2)}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>

                            
                                <table className='m-2 table table-bordered' style={{width: '35%', float:'right'}}>
                                    <tr>
                                        <th>UNITS</th>
                                        <th>{allOrderunits}</th>
                                    </tr>
                                    <tr>
                                        <th>SUB TOTAL</th>
                                        <th>₹ {allOrderPrice.toFixed(2)}</th>
                                    </tr>
                                    <tr>
                                        <th>GST - ({printdata?.invoice?.igst > 0 ? 'IGST' : 'CGST/SGST'}) %</th>
                                        <th>{printdata?.invoice.igst > 0 ? printdata?.invoice.igst : parseFloat(printdata?.invoice?.cgst) + parseFloat(printdata?.invoice?.sgst) }</th>
                                    </tr>
                                    <tr>
                                        <th>TOTAL</th>
                                        <th>₹ {printdata?.invoice.invoice_total}</th>
                                    </tr>
                                </table>
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Link
                        to={`/invoice/print?cno=${printdata?.invoice?.invoice_number}`}
                        onClick={() => {
                            localStorage.setItem('printinvoice', JSON.stringify(printdata))
                        }}
                        target="_blank"
                        className="btn btn-success  me-2"
                    >
                        <i className="fa fa-print" />
                    </Link>
                    <Button
                        type="button"
                        color="secondary"
                        onClick={() => closeModel(!isOpen)}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </div>
        </Modal>
    )
}

export default PrintModel;