import React, {useEffect, useState} from "react"
import { Col, Card, CardBody, Row } from "reactstrap"
import { get } from 'helpers/api_helper'

const FMYTDWidget = () => {
  const [reportType, setReportType] = useState("ftd")
  const [data, setData] = useState(null)

  useEffect(async() => {
    setData(null)
    const res = await get(`api/org/termdeposits/${reportType}`)
    if(res.status == 200){
      setData(res.response)
    }
  }, [reportType])

  const reports = [
    {
      icon: "bx bx-copy-alt",
      title: "New Orders",
      value: data ? data?.orders?.new.toString() : <i className="fas fa-spinner fa-spin"/>,
      color: "success"
    },
    {
      icon: "bx bx-archive-in",
      title: "Redo Orders",
      value: data ? data?.orders?.redo.toString() : <i className="fas fa-spinner fa-spin"/>,
      color: "success"
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Correction Orders",
      value: data ? data?.orders?.corr.toString() : <i className="fas fa-spinner fa-spin"/>,
      color: "warning"
    },
    {
      icon: "bx bx-purchase-tag-alt",
      title: "Invoices",
      value: data ? data?.invoice?.total.toString() : <i className="fas fa-spinner fa-spin"/>,
      color: "warning"
    },
    {
      icon: "bx bx-copy-alt",
      title: "Challans",
      value: data ? data?.challan.toString() : <i className="fas fa-spinner fa-spin"/>,
      color: "success"
    },
    {
      icon: "bx bx-archive-in",
      title: "Revenue",
      value: data ? data?.invoice?.amount : <i className="fas fa-spinner fa-spin" />,
      color: "success"
    }
  ];
  
  return (
    <React.Fragment>
      <Card className="pb-2 pt-1">
        <CardBody>
          <Row className="mb-2">
            <Col>
              <h3>{reportType.toUpperCase()} Report</h3>
            </Col>
            <Col md="5">
              <select onChange={(e) => setReportType(e.target.value)} className="form-control" name="fmytd">
                <option value="ftd" key="ftd">FTD</option>
                <option value="mtd" key="mtd">MTD</option>
                <option value="ytd" key="ytd">YTD</option>
              </select>
            </Col>
          </Row>

          <Row className="pb-3 pt-3">
            {reports.map((report, key) => (
              <Col md="4" key={key}>
                    <div className="d-flex align-items-center mb-2">
                      <h5 className="font-size-14 mb-0">{report.title}</h5>
                    </div>
                    <div className="text-muted">
                      <h4>
                        {report.value || 0 }
                      </h4>
                    </div>
              </Col>
            ))}
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}

export default FMYTDWidget
