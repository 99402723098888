import {
    GET_EMPLOYEE_ALL_ROWS,
    GET_EMPLOYEE_SINGLE_ROW,
    GET_EMPLOYEE_ALL_ROWS_SUCCESS,
    ADD_EMPLOYEE_RECORD,
    ADD_EMPLOYEE_RECORD_SUCCESS,
    REMOVE_EMPLOYEE_RECORD,
    UPDATE_EMPLOYEE_RECORD,
    PUSH_EMPLOYEE_NEW_RECORD,
    GET_CASENUMBER, 
    GET_CASENUMBER_SUCCESS
} from './actionType'

export const getEmployeeAllRows = (data, history = false, cb = false) => ({
    type: GET_EMPLOYEE_ALL_ROWS,
    payload: {data, history, cb},
})

export const pushEmployeeNewRecord = (data) => ({
    type: PUSH_EMPLOYEE_NEW_RECORD,
    payload: {data},
})

export const getEmployeeAllRowsSuccess = payload => ({
    type: GET_EMPLOYEE_ALL_ROWS_SUCCESS,
    payload: payload,
})

export const getEmployeeSingleRowSuccess = payload => ({
    type: GET_EMPLOYEE_SINGLE_ROW,
    payload: payload,
})

export const addEmployeeRecord = (data, history, addupdate_callback) => ({
    type: ADD_EMPLOYEE_RECORD,
    payload: {data, history, addupdate_callback}
})

export const addEmployeeRecordSuccess = () => ({
    type: ADD_EMPLOYEE_RECORD_SUCCESS,
    payload: {}
})

export const removeEmployeeRecord = (data, cb) => ({
    type: REMOVE_EMPLOYEE_RECORD,
    payload: {data,cb}
})

export const updateEmployeeRecord = payload => ({
    type: UPDATE_EMPLOYEE_RECORD,
    payload: payload
})