import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useDispatch, useSelector } from "react-redux";
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
// import Datatable from "components/Datatables/datatable";
import { get, post } from 'helpers/api_helper';
import { getDate } from 'helpers/basic_helper';
import { BULK_INVOICES, ORDER_URL } from 'helpers/url_helper';
import { useHistory } from 'react-router-dom';
import { clientOptions, getOrders, resetOrders } from "store/actions";
import FormComponent from "./FormComponent";
import "flatpickr/dist/themes/material_red.css"
import Flatpickr from "react-flatpickr"
import Timezone from "utils/timezone";
import Toaster from 'components/toster'
import { customFormatDate } from 'helpers/basic_helper'
import Select from 'react-select';

const isType = 'invoice'

const CreateInvoices = (props) => {
    const dispatch = useDispatch()
    const history = useHistory();
    const { loading, orders } = useSelector(state => state.Orders);
    const { DDOptions } = useSelector(state => state.Master);
    const [orderNumbers, setOrderNumbers] = useState([]);
    const [clientId, setClientId] = useState(null);
    const [invoiceDate, setInvoiceDate] = useState('');
    const [modelErrorMessage, setModelErrorMessage] = useState("");
    const [showData, setShowData] = useState(false);
    const [isInvoiceModel, setIsInvoiceModel] = useState(false);
    const [invoiceData, setInvoiceData] = useState([]);
    const [clientModalLoading, setClientModalLoading] = useState(false);
    const [dateRange, setDateRange] = useState(false);
    const [isDateRange, setIsDateRange] = useState(false);

    const handleGetOrderButton = () => {
        setClientModalLoading(true)
        if(dateRange.length){
            setIsDateRange(true);
            dispatch(getOrders({url: `${ORDER_URL}?client=${clientId?.value ? clientId?.value : ''}&_invoice=1&_startdate=${dateRange[0]}&_enddate=${dateRange[1]}`}))
        }else{
            dispatch(getOrders({url: `${ORDER_URL}?client=${clientId ? clientId?.value : ''}&_invoice=1`}))
        }
    }

    useEffect(() => {
        dispatch(resetOrders());
    }, [history]);

    useEffect(() => {
        if(orders.length > 0 || orders?.totalRows > 0) {
            const check = orders.totalRows
    
            setModelErrorMessage(false)
            setClientModalLoading(false)
    
            if(check !== undefined){
                setShowData(true)
            }else{
                setShowData(false)
                if(typeof orders === 'string')
                    setModelErrorMessage("No Orders or Challans Found.!")
            }
        }else{
            setShowData(false)
            setInvoiceData([])
        }
    }, [orders])

    useEffect(() => {
        if(invoiceData?.orders?.length > 0) {
            setIsInvoiceModel(true)
        }
    }, [invoiceData])

    /**
     * Check All Checkbox`s 
     */
    const isCheckboxAllCall = (e) => {
        if(e.target.checked){
            let temp = orders?.rows.map( v => v.id )
            setOrderNumbers(temp)
        }else{
            setOrderNumbers([])
        }
    }
    
    /**
     * Check Single Checkbox 
     */
    const isCheckboxCall = (orderNumber, isChecked) => {
        if (isChecked) {
            setOrderNumbers(e => [...e, orderNumber]);
        } else {
            setOrderNumbers(orderNumbers.filter(item => item !== orderNumber));
        }
    }
    

    // table Column Data
    let tableColumns = [
        {
            dataField: 'id',
            text: <input type="checkbox" onChange={(e) => isCheckboxAllCall(e)} />,
            sort: false,
            formatExtraData: orderNumbers,
            formatter: (cell, row,  rowIndex, extraData) => <input type="checkbox" checked={ orderNumbers?.includes(row.id)} onChange={(e) => isCheckboxCall(row.id, e.target.checked)}/>
        },
        {
            dataField: 'type',
            text: '',
            sort: false,
            formatter: (cell, row,  rowIndex, extraData) => {
                switch (row['type']) {
                    case 'new':
                        return <i title="New Order" className="fas fa-record-vinyl text-success" />
                    case 'redo':
                        return <i title="Redo Order" className="fas fa-record-vinyl text-warning" />
                }
            }
        },{
            dataField: 'order_number',
            text: 'Order No',
            sort: true
        },{
            dataField: 'order_date',
            text: 'Order Date',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => getDate(row.order_date)
        },{
            dataField: 'client',
            text: 'Client',
            sort: true,
            formatter: (cell, row,  rowIndex, extraData) => {
                return <strong>{row.client} ({row.client_code})</strong>
            }
        },{
            dataField: 'patient',
            text: 'Patient',
            sort: true
        },{
            dataField: 'case_no',
            text: 'Case No',
            sort: true
        },{
            dataField: 'units',
            text: 'Units',
            sort: false,
            formatter: (cell, row,  rowIndex, extraData) => <b>{parseInt(row.units)}</b>
        },{
            dataField: 'order_value',
            text: 'Total Order Value',
            sort: false,
            formatter: (cell, row,  rowIndex, extraData) => <b>{parseFloat(row.order_value).toFixed(2)}</b>
        }
    ];

    useEffect(() => {
        // Load Clients
        dispatch(clientOptions({type: 'invoice'}))
        localStorage.removeItem('printinvoice')
    },[])

    // const changeClient = () => dispatch(resetOrders());

    const handleCreateInvoice = async () => {

        if(!orderNumbers.length){
            Toaster('warning', {title:'Please select orders', message:'Please Select Orders to Create Proforma invoice'})
            return;
        }

        const qry = {'orders': orderNumbers, 'invoice_date': invoiceDate};
        if(isDateRange){
            qry.is_daterange = true
        }

        // const res = await post(`${qry.is_daterange ? BULK_INVOICES : `${ORDER_URL}/get`}`, qry)
        const res = await post(BULK_INVOICES, qry)
        if(res.status == 200){
            setOrderNumbers([])
            setClientId(null)
            setInvoiceData('')
            setDateRange(false)
            history.push('/invoices/proforma');
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | {isType}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Masters"
                        breadcrumbItem={`Create ${isType}s`}
                    />

                    <Row>
                        <Col xl="12">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <Col md={3}>
                                            <label>Select Client or Doctor</label>
                                            <Select
                                                styles={{
                                                    menu: provided => ({ ...provided, zIndex: 9999 })
                                                }}
                                                onChange={(e) => {
                                                    setClientId(e)
                                                }}
                                                isClearable={true}
                                                value={clientId}
                                                options={DDOptions?.clients}
                                                name={'client'}
                                                required={false}
                                                placeholder={'Select Client'}
                                            />
                                        </Col>
                                        <Col md={3}>
                                            <label>Date Range</label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "d-m-Y"
                                                }}
                                                onChange={
                                                    (e) => {
                                                        const dates = e.map(dateString => Timezone(dateString));
                                                        setDateRange(dates)
                                                    }
                                                }
                                            />
                                        </Col>

                                        <Col md={2}>
                                            <label>Invoice Date</label>
                                            <Flatpickr
                                                className="form-control d-block"
                                                placeholder="DD-MM-YYYY"
                                                options={{
                                                    dateFormat: "d-m-Y"
                                                }}
                                                onChange={
                                                    (e) => {
                                                        setInvoiceDate(customFormatDate(e[0], 'YYYY-MM-DD'))
                                                    }
                                                }
                                            />
                                        </Col>
                                        <Col md={2}>
                                            <button style={{marginTop: '26px'}} className="btn btn-primary w-100" onClick={() => {
                                                if(invoiceDate != '') {
                                                    handleGetOrderButton()
                                                } else {
                                                    setModelErrorMessage("Please, Invoice Date");
                                                }
                                            }}>{clientModalLoading && <i className="fa fa-spinner fa-spin" />} {" "} GET ORDERS</button>
                                        </Col>
                                    </div>
                                    { <div className="text-danger mt-1">{modelErrorMessage}</div> }
                                </CardBody>
                            </Card>
                            <div className="col-xs-12">
                            </div>
                        </Col>
                    </Row>

                    {
                        showData ?
                        <Row>
                            <Col xl="12">
                                <Card>
                                    <CardBody>
                                        <button className="btn btn-primary" onClick={() => handleCreateInvoice()}>Create</button>

                                        <div className="table-responsive">
                                            <div className="react-bootstrap-table">
                                                <table className="table table-striped table-bordered table align-middle table-nowrap">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <input type="checkbox" checked={ orderNumbers?.length > 0 } onChange={(e) => isCheckboxAllCall(e)} />
                                                            </th>
                                                            <th>Order No</th>
                                                            <th>Order Date</th>
                                                            <th>Orderform Date</th>
                                                            <th>Client</th>
                                                            <th>Patient</th>
                                                            <th>Case No</th>
                                                            <th>Units</th>
                                                            <th> Order Value</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            orders && orders?.rows?.map((v, i) => {
                                                                return <tr key={`CIOL_${i+1}`}>
                                                                    <td><input type="checkbox" checked={ orderNumbers?.includes(v.id)} onChange={(e) => isCheckboxCall(v.id, e.target.checked)} /></td>
                                                                    <td>{v.order_number}</td>
                                                                    <td>{v.order_date}</td>
                                                                    <td>{v.orderformdate}</td>
                                                                    <td>{v.client}</td>
                                                                    <td>{v.patient}</td>
                                                                    <td>{v.case_no}</td>
                                                                    <td>{v.units}</td>
                                                                    <td>{v.order_value}</td>
                                                                </tr>
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </CardBody>
                                </Card>
                                {/* <Datatable
                                    keyField={'id'}
                                    title= {
                                        <span>
                                            {JSON.parse(clientId)?.label} <i title="Change client or doctor" role="button" className="ms-2 fas fa-pencil-alt" onClick={changeClient} />
                                        </span>
                                    }
                                    handleAddButton={handleCreateInvoice}
                                    columns={tableColumns}
                                    rows={orders?.rows}
                                    loading={ loading }
                                    isDisabled={orderNumbers.length ? false : true}
                                    btnTitle="Create"
                                    isCheckbox={isCheckboxCall}
                                /> */}
                            </Col>
                        </Row> : ''
                    }
                </Container>
            </div>

            <FormComponent
                title="Create Invoice"
                isOpen={isInvoiceModel}
                Position="right"
                invoiceData={invoiceData}
                onDrawerClose={(invoice_number) => {
                    setIsInvoiceModel(false)
                    setShowData(false)
                    setInvoiceData(null)
                    setClientId(null)
                    // if(localStorage.getItem('printinvoice'))
                    //     window.open(`/invoice/print?invoice_number=${invoice_number}`);
                }}
                invoiceDate={invoiceDate}
            />
        </React.Fragment>
    )
}

export default CreateInvoices
