import React from "react"
import PropTypes from 'prop-types'
import { Link } from "react-router-dom"
import { Row, Col, BreadcrumbItem } from "reactstrap"

const Breadcrumb = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
          <h4 className="mb-0 font-size-18">{ !props.pageTitle ? props.breadcrumbItem : props.pageTitle}</h4>
          <div className="page-title-right">
            <ol className="breadcrumb m-0">
              <BreadcrumbItem>
                <Link to="#">{props.title}</Link>
              </BreadcrumbItem>
              {
                Array.isArray(props.breadcrumbItem) ?
                  props.breadcrumbItem.map((z, i) =>
                    <BreadcrumbItem key={`BC_${i}`}>
                      <Link to="#">{z}</Link>
                    </BreadcrumbItem>
                  )
                  :
                  <BreadcrumbItem active>
                    <Link to="#">{props.breadcrumbItem}</Link>
                  </BreadcrumbItem>
              }
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  )
}

Breadcrumb.propTypes = {
  breadcrumbItem: PropTypes.any,
  title: PropTypes.string
}

export default Breadcrumb
