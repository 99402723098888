import {
    ADD_ORDER,
    ADD_ORDER_SUCCESS,
    EMPTY_ORDERS,
    GET_ALL_OPTIONS,
    GET_ALL_OPTIONS_SUCCESS,
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_SUCCESS,
    GET_CASENUMBER,
    GET_CASENUMBER_SUCCESS,
    GET_JOBCARD,
    GET_ORDER_OPTION,
    GET_ORDER_OPTION_SUCCESS,
    GET_SINGLE_ORDER,
    GET_SINGLE_ORDER_SUCCESS,
    UPDATE_RX_STATE,
    UPDATE_STATE
} from './actionTypes'

export const updateState = data => ({
    type: UPDATE_STATE,
    payload: data
})

export const resetOrders = () => ({
    type: EMPTY_ORDERS
})

export const updateRxState = data => ({
    type: UPDATE_RX_STATE,
    payload: data
})

export const getAllOptions = data => ({
    type: GET_ALL_OPTIONS,
    payload: data
})

export const getAllOptionsSuccess = data => ({
    type: GET_ALL_OPTIONS_SUCCESS,
    payload: data
})

export const getOrders = data => ({
    type: GET_ALL_ORDERS,
    payload: data
})

export const orderOptions = data => ({
    type: GET_ORDER_OPTION,
    payload: data
})

export const orderOptionsSuccess = data => ({
    type: GET_ORDER_OPTION_SUCCESS,
    payload: data
})

export const getOrdersSuccess = data => ({
    type: GET_ALL_ORDERS_SUCCESS,
    payload: data
})

export const getSingleOrder = (data, history, cb) => ({
    type: GET_SINGLE_ORDER,
    payload: {data, history, cb}
})

export const getSingleOrderSuccess = data => ({
    type: GET_SINGLE_ORDER_SUCCESS,
    payload: data
})

export const addOrder = (data) => {
    return {
        type: ADD_ORDER,
        payload: data
    }
}

export const addOrderSuccess = () => ({
    type: ADD_ORDER_SUCCESS,
    payload: {}
})

export const getJobCard = (data, cbJobcard) => {
    return {
        type: GET_JOBCARD,
        payload: {data, cbJobcard}
    }
}

export const GetCaseNumber = payload => ({
    type: GET_CASENUMBER,
    payload: payload
})

export const GetCaseNumberSuccess = payload => ({
    type: GET_CASENUMBER_SUCCESS,
    payload: payload
})
