import React, {useRef, useEffect} from "react"
import { Carousel } from "react-responsive-carousel"
import "react-responsive-carousel/lib/styles/carousel.min.css"
import { Col } from "reactstrap"

const CarouselPage = () => {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <video
      style={{ maxWidth: "100%", width: "100%", height:"100%" }}
      playsInline
      loop
      muted
      alt="All the devices"
      src="/play_login_page.mp4"
      ref={videoEl}
      className="video_on_login"
    />
  )
}
export default CarouselPage
