import Breadcrumbs from "components/Common/Breadcrumb";
import Mdatatable from "components/Datatables/Mdatatables";
import { get } from "helpers/api_helper";
import { DDClients_URL, DUES_URL } from 'helpers/url_helper';
import React, { useEffect, useState } from "react";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import MetaTags from "react-meta-tags";
import { Card, CardBody, Container } from "reactstrap";

// const inr = <i class="fas fa-rupee-sign" style={{fontSize: '14px'}}></i>

let INR = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'INR',
});


/**
 * DataTable with Condition based row formating
 */
let datatableData = [
    {
        accessorKey: 'id',
        header: 'Id'
    }, {
        accessorKey: 'invoice_number',
        header: 'Invoice Number'
    },{
        accessorKey: 'invoice_date',
        header: 'Date'
    },{
        accessorKey: 'invoice_total',
        header: 'Grand Total'
    },{
        accessorKey: 'paid_amount',
        header: 'Paid Total'
    },{
        accessorKey: 'blc_amount',
        header: 'Due'
    }
];

const isType = 'Invoices'
const PayModule = () => {
    const [totalAmount, setTotalAmount] = useState(0)
    const [payAmount, setPayAmount] = useState(0)
    const [blcAmount, setBlcAmount] = useState(0)
    const [clientsDD, setClientsDD] = useState(null)
    const [clientId, setClientId] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [payObj, setPayObj] = useState({
        amount: 0,
        date: new Date(),
        payment_mode: 'cash',
        note: '',
        reference_no: '',
        check_no: '',
        bankname: '',
        ifsc_code: '',
        check_date: ''
    });

    useEffect(async() => {
        const res = await get(DDClients_URL);
        if(res.status == 200){
            setClientsDD(res?.response)
        }
    }, [])

    useEffect(async() => {
        if(clientId){
            const res = await get(`${DUES_URL}/${clientId}`);
            if(res.status == 200){
                setDataRows(res?.response)
                const totalAmount = res?.response.filter(v => v.invoice_total).reduce((acc, v) => parseFloat(acc) + parseFloat(v.invoice_total.replace(',', '')), 0);
                setTotalAmount(totalAmount.toFixed(2))
            }
        }
    }, [clientId])
    
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{isType} | KS-Lab</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Masters"
                        breadcrumbItem={isType}
                    />

                    
                    <div className="row">
                        <div className="col-8">
                            <Card>
                                <CardBody>
                                    <div className="row">
                                        <div className="col-12">
                                            <select className="form-control" onChange={(e) => setClientId(e.target.value)} style={{width: '320px', float: 'right'}}>
                                            <option key={'s0'} value="">---</option>
                                                {
                                                    clientsDD?.clients?.map((v, i) => <option key={i} value={v.value}>{v.label}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>

                                    {/* Datatable */}
                                    {
                                        <Mdatatable
                                            // enableRowAction={isActionColumn}
                                            // userModules={isActions}
                                            title={`${isType}`}
                                            columns={datatableData}
                                            data={dataRows}
                                            // handleEdit={handleEdit}
                                            // handleRemove={handleRemove}
                                            // isEdit={isEdit}
                                            // handleAddButton={() => {
                                            //     setEditableObj(null)
                                            //     isOpen(true)
                                            // }}
                                        />
                                    }
                                    
                                    
                                </CardBody>
                            </Card>
                        </div>

                        <div className="col-4">
                            <Card>
                                <CardBody>
                                    <label>Date</label>
                                    <DatePicker
                                        className="form-control mb-2"
                                        selected={selectedDate}
                                        onChange={() => {
                                            if (date >= new Date()) {
                                                setSelectedDate(date);
                                                setPayObj({...payObj, "date": date})
                                            }
                                        }}
                                        minDate={new Date()}
                                        dateFormat="dd/MM/yyyy"
                                    />
                                    
                                    <br />
                                    <label>Amount to be Pay</label>
                                    <input type="number" className="form-control" onKeyUp={(e) => {
                                        const amt = parseFloat(e.target.value)
                                        if(amt > totalAmount){
                                            e.target.value = totalAmount
                                            return;
                                        }
                                        setBlcAmount(totalAmount - amt)
                                    }}/>
                    
                                    <br />
                                    <label>Payment Mode</label>
                                    <select className="form-control" onChange={(e) => {
                                        setPayObj({...payObj, "payment_mode": e.target.value})
                                    }}>
                                        <option value="cash">Cash</option>
                                        <option value="online">Online</option>
                                        <option value="bank">Bank Trasfer</option>
                                        <option value="check">Check</option>
                                    </select>

                                    {
                                        (payObj.payment_mode === '') && (
                                            <>
                                                <label>Payment Mode</label>
                                                <select className="form-control" onChange={(e) => {
                                                    setPayObj({...payObj, "payment_mode": e.target.value})
                                                }}>
                                                    <option value="cash">Cash</option>
                                                    <option value="online">Online</option>
                                                    <option value="bank">Bank Trasfer</option>
                                                    <option value="check">Check</option>
                                                </select>
                                            </>
                                        )
                                    }

                                    <br />
                                    <label>Note</label>
                                    <input type="text" className="form-control" onChange={(e) => setPayObj({...payObj, note: e.target.value})}/>

                                    <br />
                                    
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        Total Amount <b style={{textAlign: 'right', fontSize: '16px'}}>{INR.format(totalAmount)}</b>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <span>Balance Amount</span><br />
                                        <b style={{display:'block', textAlign: 'right', fontSize: '16px'}}>{INR.format((blcAmount))}</b>
                                    </div>
                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                        <span>Paying</span><br />
                                        <b style={{display:'block', textAlign: 'right', fontSize: '26px'}}>{INR.format((totalAmount - blcAmount))}</b>
                                    </div>

                                    <button className="btn btn-primary w-100">Submit</button>
                                    
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                    
                </Container>
            </div>
        </React.Fragment>
    )
}

export default PayModule
