import React from "react"
import MetaTags from "react-meta-tags"
import {
    Container,
    Row,
    Col
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Profile from './components/profile'
import InvoiceSettings from './components/invoice'

const Companysettings = () => {
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Settings | Company</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Settings"
                        breadcrumbItem="Company"
                    />

                    <Row>
                        <Profile />
                        <InvoiceSettings />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Companysettings