import { call, takeEvery, put } from "redux-saga/effects"
import { get } from "helpers/api_helper"

import { GET_ALL_INVOICES, INVOICE_PRINT_DATA } from './actionTypes'
import { GetInvoiceSuccess, printInvoiceDataActionSuccess } from "./actions"

// Api Calls
const GetCall = url => get(url) 

/***
 * Get All Challans List
 */
function* actionGetAllInvoices({ payload: { url } }) {
    try {
        const res = yield call(GetCall, url)
        if(res?.status === 200)
            yield put(GetInvoiceSuccess(res.response))

    } catch (error) {}
}

function* actionPrintInvoiceDdata({ payload: { IN, callback } }) {
    try {
        const res = yield get(`/api/invoice/invoicePrintData/${IN}`)
        if(res?.status === 200){
            yield callback(res?.response)
            yield put(printInvoiceDataActionSuccess(res.response))
        }
    } catch (error) {}
}

function* ChallanSaga() {
    yield takeEvery(GET_ALL_INVOICES, actionGetAllInvoices)
    yield takeEvery(INVOICE_PRINT_DATA, actionPrintInvoiceDdata)
}
  
export default ChallanSaga
  