import { call, put, takeEvery } from "redux-saga/effects"

// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes"
import { apiError } from "./actions"

//Include Both Helper File with needed methods
import {
  LoginUserAPI,
} from "helpers/api_call_service"
import { encryptData } from "services/encription_service"

function* loginUser({ payload: { user, history } }) {
  try {
    const temp = yield call(LoginUserAPI, {
      username: user.username,
      password: user.password
    })
  
    const res = temp.response;

    
    localStorage.setItem("token", res.access_token)
    localStorage.setItem("authUser", JSON.stringify(res.data))
    localStorage.setItem("basefileurl", res.access_token)
    localStorage.setItem("subcription", res.data.subcription)
    localStorage.setItem("reminder", res.data.reminder)
    localStorage.setItem("_top_bar", res.data.reminder <= 45 ? true : false)
    localStorage.setItem("userdetails", encryptData(res.data.permission))

    history.push("/dashboard")
  } catch (error) {
    yield put(apiError(error.response))
  }
}

function* logoutUser({ payload: { history } }) {
  try {
    localStorage.clear()
    history.push("/login")
  } catch (error) {
    yield put(apiError(error))
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser)
  yield takeEvery(LOGOUT_USER, logoutUser)
}

export default authSaga
