import { post } from 'helpers/api_helper'
import { SMS_CONFIG } from 'helpers/url_helper'
import { useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Label,
    Modal,
    Row
} from "reactstrap"

const SmsSettings = () => {
    const [isOpen, setIsOpen] = useState();
    const [data, setData] = useState({
        api_key: null,
        service: null,
    });
    
    const saveCreds = async() => {
        const res = await post(SMS_CONFIG, postObject);
        if(res.status == 200){}
    }

    return (
        <Col>
            <Card>
                <CardBody>
                    <h4>SMS Settings</h4>

                    <Row>
                        <Col>
                            <Row className="mt-2">
                                <strong>SMS Configration</strong>

                                <Col>
                                    <Label>Service Provider</Label>
                                    <select className="form-control" defaultValue="">
                                        <option value="">Select Service Provider</option>
                                        <option value="cssms">CSSMS</option>
                                        <option value="msg91">MSG91</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Label>Api Key</Label>
                                    <input className="form-control" type="text" onChange={e => {
                                        setData(prev => ({...prev, }))
                                    }}/>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                    <Col>
                                        <button style={{float:'right'}} className="btn btn-primary" onClick={() => saveCreds()}> Save </button>
                                    </Col>
                                </Row>

                            <Row className="mt-4">
                                <strong>Test Configration</strong>
                                <Col>
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Put Your phone Number where you will received a test message from your sms configuration settings"/>
                                        <button className="btn btn-primary" type="button" onClick={() => alert('')}>Test</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row>
                                <strong className="mt-2 mb-2">SMS Templates</strong>
                                <Col>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Template</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

            <Modal
                size="md"
                isOpen={isOpen}
                toggle={() => setIsOpen(false)}
                centered={true}
            >
                <div className="modal-body">
                    
                </div>
                <div className="modal-footer">
                    <button 
                        type="button"
                        className="btn btn-secondary"
                        onClick={() => setIsOpen(false)} >Close</button>
                    <button 
                        type="button"
                        className={`btn btn-success`}
                        onClick={() => {
                            setIsOpen(false);
                        }} >Save</button>
                </div>
            </Modal>

        </Col>
    )
}

export default SmsSettings;