import CustomForm from 'components/customForm';
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';

const FormComponent = ({ fileds, Position, isOpen, onDrawerClose, prifix="", title, width = 50, submitForm, editableObj, loading, callback = () => {} }) => {
    return (
        <Drawer
            open={isOpen}
            onClose={onDrawerClose}
            direction={Position}
            className="masterForm"
            style={{minWidth:`${width}%`}}
        >
            <>
                <div className="rightbar-title px-3 py-4">
                    <span className="right-bar-toggle float-end cursor-pointer" title="close" onClick={onDrawerClose}><i className="mdi mdi-close noti-icon"></i></span>
                    <h5 className="m-0">{title}</h5>
                </div>
                <hr className="my-0"></hr>
                <div className='col-md-12' style={{padding: '15px', height: '90vh', overflow: 'auto'}}>
                    <CustomForm isOpen={isOpen} callback={callback} loading={loading} fileds={fileds} fileupload={false} isType={ (editableObj?.id || editableObj?.cid) ? true : false } submitForm={submitForm}/>
                </div>
            </>
        </Drawer>
    );
}

export default FormComponent;