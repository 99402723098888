import React, {useEffect, useState} from 'react';
import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { getDate } from 'helpers/basic_helper';
import { post } from 'helpers/api_helper'

const FormComponent = ({ Position, isOpen, onDrawerClose, title, invoiceDate, invoiceData, width = 100 }) => {
    const [GSTType, setGSTType] = useState('igst')
    const [GST, setGST] = useState(0)

    const onClickHandler = (e) => {
        const hiddenElement = e.currentTarget.nextSibling;
        hiddenElement.className.indexOf("collapse show") > -1 ? hiddenElement.classList.remove("show") : hiddenElement.classList.add("show");
    };

    let total = 0

    const genrateInvoice = async() => {
        const Obj = {
            "orders": invoiceData?.orders.filter(v => v.id).map(v => v.id),
            "invoice_date": invoiceDate,
            "tax_type": GSTType,
            "tax_per": GST,
            "tax_amount": parseFloat(total) + ((parseFloat(total) * GST)/100),
            "subtotal": total,
            "invoice_type": 'new',
            "other_charges": 0,
            "discount": 0,
            "total": (parseFloat(total) + ((parseFloat(total) * GST)/100)).toFixed(2)
        }

        const res = await post('/api/invoice', Obj);
        if(res.status === 200){
            localStorage.setItem('printinvoice', JSON.stringify(res.response))
            onDrawerClose(res?.response?.invoice?.invoice_number)
        }
    }

    useEffect(() => localStorage.removeItem('printinvoice'), [])

    return (
        <Drawer
            open={isOpen}
            onClose={onDrawerClose}
            direction={Position}
            style={{
                width: `${width}%`,
                overflowY: 'scroll'
            }}
        >
            <div style={{width: '99%'}}>
                <div className="rightbar-title px-3 py-4">
                    <span className="right-bar-toggle float-end cursor-pointer" title="close" onClick={onDrawerClose}><i className="mdi mdi-close noti-icon"></i></span>
                    <h5 className="m-0">{title}</h5>
                </div>
                <hr className="my-0"></hr>
                <div className='row' style={{padding: '15px 0px', overflow: 'auto'}}>
                    <div className='col-md-12' style={{textAlign: 'center'}}>
                        <h4>Tax Invoice</h4>
                    </div>
                </div>

                <div className='header row' style={{padding: '0px 20px'}}>
                    <div className='col-md-6'>
                        <h4>D-Lab Company</h4>
                        <div>Invoice Date # <b>{invoiceDate}</b></div>
                        <p>Invoice # </p>
                        
                        <address>
                            Harishchandra Jariwala Mansion 1 St Floor,<br/>
                            Banganga Road Near Khandoba Mandir,<br/>
                            Walkeshwar 400006.
                        </address>
                        Email: <b>accounts@dentalbotique.com</b>
                    </div>
                    <div className='col-md-6'>
                        <div style={{width: '320px', textAlign: 'right', position: 'absolute', right: '15px'}}>
                            <h5>To : {invoiceData?.client?.clientname}</h5>
                            <address>
                            {invoiceData?.client?.address}
                            </address>
                            {
                                invoiceData?.client?.email && 
                                <>Email: <b>{invoiceData?.client?.email}</b></>
                            }
                        </div>
                    </div>
                </div>

                <div className='row p-3' style={{height: '30vh', overflowY: 'scroll'}}>
                    <div className='col-md-12'>
                        <table className="table table-condensed table-striped">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Order Number</th>
                                    <th>Order Date</th>
                                    <th>Patient Name</th>
                                    <th>Units</th>
                                    <th>Subtotal</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    invoiceData?.orders?.map((v, i) => {
                                        total += parseFloat(v.order_value)
                                        return (
                                            <>
                                                <tr key={i} onClick={(e) => onClickHandler(e)}>
                                                    <td>{i+1}</td>
                                                    <td>{v.order_number}</td>
                                                    <td>{getDate(v.order_date, 'DD-MM-yyyy')}</td>
                                                    <td className="text-success">{v.patient_name}</td>
                                                    <td>{v.units}</td>
                                                    <td className="text-success">{parseFloat(v.order_value).toFixed(2)}</td>
                                                </tr>
                                                <tr className="collapse">
                                                    <td colSpan={6}>
                                                        <h4>Products</h4>
                                                        <table className='table table-bordered'>
                                                            <thead>
                                                                <td>#</td>
                                                                <td>Product</td>
                                                                <td>Teeth</td>
                                                                <td>Units</td>
                                                                <td>Rate</td>
                                                                <td>Discount</td>
                                                                <td>Subtotal</td>
                                                            </thead>
                                                            <tbody>
                                                                {
                                                                    v.products.map((v, i) => {
                                                                        return (
                                                                            <tr key={`TRP_${i}`}>
                                                                                <td>{i+1}</td>
                                                                                <td>{v.product}</td>
                                                                                <td>{v.teeth}</td>
                                                                                <td>{v.unit}</td>
                                                                                <td>{v.unit_price}</td>
                                                                                <td>{v.dicount}</td>
                                                                                <td>{parseFloat(v.total_amount).toFixed(2)}</td>
                                                                            </tr>
                                                                        )
                                                                    })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className='row p-3'>
                    <div className='col-md-8' />
                    <div className='col-md-4'>
                        <table className='table table-bordered'>
                            <tbody>
                                <tr>
                                    <td><b>Subtotal</b></td>
                                    <td>{parseFloat(total).toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td><b>Other Changers</b></td>
                                    <td>00</td>
                                </tr>
                                <tr>
                                    <td colSpan={2}>
                                        <div>
                                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                <h5>GST</h5>
                                                <span><input name="gst" onClick={(e) => setGSTType('igst')} type="radio" defaultChecked={true} /> IGST  | <input onClick={(e) => setGSTType('cgst and sgst')} name="gst" type="radio" /> CGST / SGST</span>
                                            </div>
                                            <input className='form-control' value={GST} onChange={(e) => {
                                                if(e.target.value >= 0 && e.target.value <= 35)
                                                    setGST(e.target.value)
                                                else{
                                                    alert('Enter valid GST value')
                                                }

                                            }}/>
                                            <br />
                                            <span><b>{GSTType.toUpperCase()}</b> value of <b>{GST}%</b> is <b>{((parseFloat(total) * GST)/100).toFixed(2)} Rupees</b></span>
                                        </div>
                                    </td>
                                </tr>
                                <tr style={{fontSize: '22px'}}>
                                    <td><b>Total</b></td>
                                    <td><b>{
                                        (parseFloat(total) + ((parseFloat(total) * GST)/100)).toFixed(2)
                                    }/-</b></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className='row p-3' style={{marginTop: '-35px'}}>
                    <div className='col-md-8' />
                    <div className='col-md-4'>
                        <button className='w-100 p-3 btn btn-primary' onClick={() => genrateInvoice()}>Genrate</button>
                    </div>
                </div>
            </div>
        </Drawer>
    );
}

export default FormComponent;