import React, { useEffect, useState } from "react"
const enclosures = ['Abutments','Bite','CAD CAM Files','Facebow Articulator','Imp Post','Impression Lower','Impression Upper','Lab Analogue','Model Lower','Model Upper','Photos','Crown Received']

const Trials = ({_enclosures, enclosuresCB}) => {    
    const [activeEnc, setActiveEnc] = useState({})
    const [activeEncInput, setActiveEncInput] = useState([])

    useEffect(() => {
        if(Object.keys(_enclosures).length){
            setActiveEnc(_enclosures)
            setActiveEncInput(Object.keys(_enclosures))
        }
    }, [])

    useEffect(() => {
        if(activeEnc !== undefined)
            enclosuresCB(activeEnc)
    }, [activeEnc])
    
    return ( 
        <div className="row mb-2">
            {
                enclosures.map( (v, i) => {
                    return <div className="col-md-4 mt-3" key={`ENC_${i}`}>
                        <label>
                            <input type="checkbox" defaultChecked={ Object.keys(_enclosures).includes(v) } onClick={
                                (e) => {
                                    if(e.target.checked){
                                        setActiveEnc({...activeEnc, [v]: ''})
                                        setActiveEncInput(prev => [...prev, v])
                                    } else {
                                        delete activeEnc[v]
                                        enclosuresCB(activeEnc)
                                        setActiveEncInput(activeEncInput.filter( k => k != v))
                                    }
                                }
                            } /> {v}
                        </label>
                        <input
                            className="form-control"
                            defaultValue={_enclosures[v]}
                            disabled={!activeEncInput.includes(v)}
                            onChange={(e) => {
                                setActiveEnc(prev => ({...prev, [v]: e.target.value}))
                            }} />
                    </div>
                })
            }
        </div>
    )
}

export default Trials