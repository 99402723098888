import Mdatatable from "components/Datatables/Mdatatables";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
    Col,
    Container,
    Row
} from "reactstrap";
import FormComponent from "../FormComponent";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import Notifications from "components/Notifications";
import DeleteConfirmationModel from "components/delete-confirmation-model";
import { getCode, userModuleData } from 'helpers/basic_helper';
import { GetHelper, SubmitHelper } from '../CallHelper';
const isType = 'department'

const Department = () => {
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDeleteModel, setIsDeleteModel] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [error, setError] = useState(false)

    /**
     * Get All Rows
     * @param {row_id} ID 
     * @method {get} GET 
     */
    const getData = async(ID = false) => {
        !ID ? setIsLoading(!isLoading) : false
        const res = await GetHelper(`${process.env.REACT_APP_DEPARTMENT}`, ID)

        if(ID){
            setEditableObj(...res)
            isOpen(true)
            setIsEdit(false)
        }else{
            setDataRows(res)
        }
        
        setIsLoading(false)
        if(res === undefined){
            setError(true)
        }
    }

    useEffect(() => getData(), [])
    
    /**
     * Submit or Update data Api Call
     * @param {Form Data} data 
     * @method {post} POST 
     */
    const submitForm = async(data) => {
        const checkCode = dataRows.filter(v => (v.code).toUpperCase() == (data.target.code.value).toUpperCase())
        if(data?.target?.id?.value =='' && checkCode.length > 0) {
            Notifications('please enter unique code.!', 'error')
            return;
        }

        setIsLoading(true)
        const res = await SubmitHelper(`${process.env.REACT_APP_DEPARTMENT}`, {
            "title": data.target.title.value,
            "code": data.target.code.value
        }, data?.target?.id?.value || null);
        
        if(res){
            Notifications("You have successfully added a department!")
            getData()
            isOpen(false)
            setIsLoading(false)
        }
    }

    /**
     * Get Single row using url params ?q=?
     * @param {Row_id} q 
     * @method {get} GET 
     */
    const handleEdit = q => {
        if(q > 0){
            setIsEdit(q)
            getData(q)
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {del} DELETE 
     */
    const handleRemove = async(q, status, code) => {
        if(q > 0) {
            setIsDeleteModel({url: process.env.REACT_APP_DEPARTMENT, q, status, code})
        }
    }

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            accessorKey: 'id',
            header: '#'
        }, {
            accessorKey: 'title',
            header: 'Department'
        }, {
            accessorKey: 'code',
            header: 'Code'
        }
    ]

    /**
     * HTML Form Columns 
     */
    let htmlFormFields = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : '',
            col:12,
            searchable: false
        },{
            name: "title",
            label: "Department",
            placeholder: "Department Name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.title : '',
            col:12
        },{
            name: "code",
            label: "Code",
            placeholder: "Code",
            required: true,
            type: 'text',
            value: editableObj? editableObj.code : getCode("department", dataRows?.length),
            col: 12
        }
    ]

    const hideModel = () => {
        setIsDeleteModel(false)
        getData()
    }
    
    useEffect(async() => {
        const data =  await userModuleData('masters', 'department')
        setIsActions(data)
        if(data && (data?.edit || data?.delete)){
            setIsActionColumn(true)
        }
    }, [])


    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title> Dashboard | {isType} </title> </MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem="Department" />
                    <Row>
                        <Col xl="12">
                            {
                                <Mdatatable
                                    enableRowAction={isActionColumn}
                                    userModules={isActions}
                                    title={`All ${isType}`}
                                    columns={datatableData}
                                    data={dataRows}
                                    handleEdit={handleEdit}
                                    handleRemove={handleRemove}
                                    isEdit={isEdit}
                                    handleAddButton={() => {
                                        setEditableObj(null)
                                        isOpen(true)
                                    }}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Datatable */}
            {
                open && 
                <FormComponent
                title={`${editableObj ? 'Edit' : 'Add'} Department`}
                Position={"right"}
                isOpen={open}
                fileds={htmlFormFields}
                loading={isLoading}
                editableObj={editableObj}
                onDrawerClose={(e) => {
                    setEditableObj(null)
                    setIsEdit(false)
                    isOpen(!open)
                }}
                submitForm={submitForm}
                />
            }
            {/* END Datatable */}

            {
                isDeleteModel && <DeleteConfirmationModel isOpen={true} changeState={hideModel} code={isDeleteModel} />
            }

        </React.Fragment>
    )
}

export default Department
