import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Modal,
    Row
} from "reactstrap"

import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import Notifications from "components/Notifications"
import { get, post } from 'helpers/api_helper'
import { getCode, userModuleData } from 'helpers/basic_helper'
import { WHATSAPP_SHAREADDRESS_URL, CLIENT_URL } from "helpers/url_helper"
import { useDispatch, useSelector } from 'react-redux'
import { addRecord, getAllRows, removeRecord } from 'store/actions'
import FormComponent from "../FormComponent"

const isType = 'client'
const Clients = () => {
    const dispatch = useDispatch()
    const { loading, clients } = useSelector(state => state.Master);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isDelete, setIsDelete] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [DDPMaster, setDDPMaster] = useState([])
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [city, setCity] = useState('')
    const [clientProductModel, setClientProductModel] = useState(null)
    const [finalPrice, setFinalPrice] = useState(0)
    const [isBlocking, setIsBlocking] = useState(false)
    const [isProduct, setIsProduct] = useState(false)
    const [isOepnProductModal, setIsOepnProductModal] = useState(false)
    const [finalpriceBtn, setFinalpriceBtn] = useState(false)
    const [searchProduct, setSearchProduct] = useState(false);
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [error, setError] = useState(false);
    const [wpModalOpen, setWPModalOpen] = useState(false);
    const [wpCall, setWPCall] = useState(false);
    const [wpNumber, setWPNumber] = useState('');

    const getData = async() => {
        setDataRows([])
        const data =  await userModuleData('client_master', 'client')
        data.addressShare = true;
        setIsActions(data)
        console.log({data})
        if(data && (data?.edit || data?.delete || data?.cub || data?.pd)){
            setIsActionColumn(true)
        }
        const res = await get('/api/clients/options')
        if(res.status === 200) {
            setDDPMaster(res.response)
            dispatch(getAllRows({url:CLIENT_URL, isSingle: false, isType: isType}, null))
        }
    }

    useEffect(() => {
        setSearchProduct(clientProductModel)
    }, [clientProductModel])

    useEffect(() => {
        setDataRows(clients)
    },[clients])

    useEffect(() => getData(), [])
    
    // Open RightSide Panel
    const submitForm = (data) => {
        const checkCode = dataRows.filter(v => (v.code).toUpperCase() == (data.target.code.value).toUpperCase())
        if(data?.target?.id?.value =='' && checkCode.length > 0) {
            Notifications('please enter unique code.!', 'error')
            return;
        }

        const Obj = {
            "id": data.target.id.value || "",
            "org": 1,
            "clientname": data.target.clientname.value,
            "code": data.target.code.value,
            "parent": data.target.parent.value,
            "category": data.target.category.value,
            "qualification": data.target.qualification.value,
            "mobile": data.target.mobile.value,
            "whatsapp": data.target.whatsapp.value,
            "landline": data.target.landline.value,
            "assistant": data.target.assistant.value,
            "email": data.target.email.value,
            "country": data.target.country.value,
            "state": data.target.state.value,
            "city": data.target.city.value,
            "area": data.target.area.value,
            "landmark": data.target.landmark.value,
            "pincode": data.target.pincode.value,
            "station": data.target.station.value,
            "address": data.target.address.value,
            "instruction": data.target.instruction.value,
            "source": data.target.source.value,
            "referby": data.target.reference.value,
            "company": data.target.company.value,
            "company_email": data.target.company_email.value,
            "company_contact": data.target.company_contact.value,
            "company_gst": data.target.gst.value,
            "company_pan": data.target.pan.value,
            "company_cin": data.target.cin.value,
            "creadit_days": data.target.credit_days.value,
			'capping_value': 0,
			'capping_limit': 0,
			'currency': "INR",
			'language': "",
            "company_address": data.target.company_address.value,
        }
        
        dispatch(addRecord({isType:isType, Obj:Obj, url: CLIENT_URL+`${data?.target?.id?.value ? '?q='+data?.target?.id?.value : '' }`, id: data?.target?.id?.value}, null, () => {
            getData()
            isOpen(false)
            Notifications('You have successfully added a Client.!')
        }))
    }

    // Update Data , Get Singlw ROw using url params ?q=?
    const handleEdit = q => {
        if(q > 0){
            setIsEdit(q)
            dispatch(getAllRows({url:`${CLIENT_URL}?q=${q}`, isSingle: true}, null, (data) => {
                setIsEdit(false)
                if(typeof data === 'string') {
                    Notifications('No data Found', 'error')
                    return;
                }
                setEditableObj(data)
                isOpen(true)
            }))
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {del} DELETE 
     */
    const handleRemove = (q, status) => {
        if(q > 0){
            setIsDelete(q)
            dispatch(removeRecord({url:`${CLIENT_URL}?q=${q}&status=${status}`, data: {id:q, status:status}, isType: isType}, () => {
                setIsDelete(false)
            }))
        }
    }

    /**
     * Block/Unblock Client By ID
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {get} GET 
     */
    const handleStatus = async(ID, status) => {
        setIsBlocking(ID)
        const res = await get(`${CLIENT_URL}/unblock/${ID}/${status}`);
        if(res.status) {
            getData()
            setIsBlocking(false)
        }
    }

    const handleEditProduct = async(id) => {
        setIsProduct(id)
        const res = await get(`api/clients/getProducts?q=${id}`)
        if(res.status === 200){
            setIsProduct(false)
            setFinalPrice(0)
            setClientProductModel(res.response)
            setIsOepnProductModal(true)
        }else{
            setIsProduct(false)
        }
    }

    const callback = (e, name) => {
        switch(name){
            case 'country':
                setCountry(e.value)
                break;
            case 'state':
                setState(e.value)
                break;
            case 'city':
                setCity(e.value)
                break;
        }
    }

    const updatePrice = async(code, price, client) => {
        setFinalpriceBtn(true)
        const res = await post('/api/clients/finalprice', {productID: code, value: price, client:client})
        if(res.status === 200) {
            setFinalpriceBtn(false)
        }
    }
    
    const handdleFinalPrice = (a, b, p, client) => {
        const delayDebounceFn = setTimeout(() => {
            const finalPrice = p - parseFloat(a)
            setFinalPrice(prev => ({...prev, [b]:  {pre: a, value: finalPrice} }))
            updatePrice(b, parseFloat(a), client)
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }
    
    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        },{
            accessorKey: 'clientname',
            header: 'Client Name'
        },{
            accessorKey: 'code',
            header: 'Client Code'
        },{
            accessorKey: 'mobile',
            header: 'Mobile'
        },{
            accessorKey: 'state_title',
            header: 'State'
        },{
            accessorKey: 'country_title',
            header: 'Country'
        },{
            accessorKey: 'city_title',
            header: 'City'
        },{
            accessorKey: 'station_title',
            header: 'Station'
        }
    ];

    /**
     * HTML Form Columns 
     */
    let htmlFormFields = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.cid : '',
            col:12
        },{
            name: "parent",
            label: "Parent",
            placeholder: "Please Select Parent",
            required: false,
            type: 'select',
            options: DDPMaster?.parent?.map(v => ({label: v.title, value: v.code})),
            value: editableObj? DDPMaster?.parent?.filter(v => v.code ==  editableObj.parent).map(v => ({label: v.title, value: v.code})): false,
            col:3
        },{
            name: "clientname",
            label: "Client Name",
            placeholder: "Please Enter Client Name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.clientname : '',
            col:6
        },{
            name: "code",
            label: "Client Code",
            placeholder: "Please Enter Client Code",
            required: true,
            type: 'text',
            value: editableObj?editableObj.code : getCode("clients", dataRows?.length),
            col:3
        },{
            name: "category",
            label: "Client Priority",
            placeholder: "Please Enter Client Priority",
            required: false,
            type: 'select',
            options: DDPMaster?.category?.map(v => ({label: v.title, value: v.code})),
            value: editableObj? DDPMaster?.category?.filter(v => v.code ==  editableObj.customercateory).map(v => ({label: v.title, value: v.code})): false,
            col:4
        },{
            name: "qualification",
            label: "Qualification",
            placeholder: "Please Enter Qualification",
            required: false,
            type: 'select',
            options: DDPMaster?.qualification?.map(v => ({label: v.title, value: v.code})),
            value: editableObj? DDPMaster?.qualification?.filter(v => v.code ==  editableObj.qualification).map(v => ({label: v.title, value: v.code})): false,
            col:4
        },{
            name: "email",
            label: "Email",
            placeholder: "Please Enter Email Address",
            required: false,
            type: 'email',
            value: editableObj?editableObj.email : '',
            col:4
        },{
            name: "mobile",
            label: "Mobile Number",
            placeholder: "Please Enter Mobile Number",
            required: false,
            type: 'number',
            value: editableObj?editableObj.mobile : '',
            col:3
        },{
            name: "whatsapp",
            label: "Whatsapp",
            placeholder: "Please Enter Whatsapp Number",
            required: false,
            type: 'number',
            value: editableObj?editableObj.whatsappno : '',
            col:3
        },{
            name: "landline",
            label: "Landline Number",
            placeholder: "Please Enter Landline Number",
            required: false,
            type: 'number',
            value: editableObj?editableObj.landlineno : '',
            col:3
        },{
            name: "assistant",
            label: "Assistant Number",
            placeholder: "Please Enter Assistant Number",
            required: false,
            type: 'number',
            value: editableObj?editableObj.assistantno : '',
            col:3
        },{
            name: "country",
            label: "Country",
            placeholder: "Please select Country",
            required: true,
            type: 'select',
            callback: callback,
            options: DDPMaster?.country?.map(v => ({label: v.title, value: v.code})),
            value: editableObj? DDPMaster?.country?.filter(v => v.code ==  editableObj.country).map(v => ({label: v.title, value: v.code})): false,
            col:2
        },{
            name: "state",
            label: "State",
            placeholder: "Please select State",
            required: true,
            type: 'select',
            callback: callback,
            options: DDPMaster?.state?.filter(v => country == v.country).map(v => ({label: v.title, value: v.code})),
            value: editableObj? DDPMaster?.state?.filter(v => v.code == editableObj.state).map(v => ({label: v.title, value: v.code})): false,
            col:2
        },{
            name: "city",
            label: "City",
            placeholder: "Please select city",
            required: true,
            type: 'select',
            callback: callback,
            options: DDPMaster?.city?.filter(v => state == v.state).map(v => ({label: v.title, value: v.code})),
            value: editableObj? DDPMaster?.city?.filter(v => v.code ==  editableObj.city).map(v => ({label: v.title, value: v.code})): false,
            col:2
        },{
            name: "station",
            label: "Station",
            placeholder: "Please Select Station",
            required: false,
            type: 'select',
            options: DDPMaster?.stations?.filter(v => city == v.city).map(v => ({label: v.title, value: v.code})),
            value: editableObj? DDPMaster?.stations?.filter(v => v.code ==  editableObj.station).map(v => ({label: v.title, value: v.code})): false,
            col:2
        },{
            name: "address",
            label: "Address",
            placeholder: "Please Enter Address",
            required: true,
            type: 'text',
            value: editableObj?editableObj.address : '',
            col:4
        },{
            name: "area",
            label: "Area",
            placeholder: "Please Enter Area",
            required: false,
            type: 'text',
            value: editableObj?editableObj.area : '',
            col:4
        },{
            name: "landmark",
            label: "Landmark",
            placeholder: "Please Enter Landmark",
            required: false,
            type: 'text',
            value: editableObj?editableObj.landmark : '',
            col:4
        },{
            name: "pincode",
            label: "Pincode",
            placeholder: "Please Enter Pincode",
            required: true,
            type: 'text',
            value: editableObj?editableObj.pincode : '',
            col:4
        },{
            name: "instruction",
            label: "Standing Instruction",
            placeholder: "Please Enter Standing Instruction",
            required: false,
            type: 'text',
            value: editableObj?editableObj.remark1 : '',
            col:3
        },{
            name: "source",
            label: "Source",
            placeholder: "Please Select Source",
            required: false,
            type: 'select',
            options: DDPMaster?.source?.map(v => ({label: v.title, value: v.id})),
            value: editableObj?editableObj.source : '',
            col:3
        },{
            name: "reference",
            label: "Reference",
            placeholder: "Please Enter reference",
            required: false,
            type: 'text',
            value: editableObj?editableObj.referby : '',
            col:3
        },{
            divider: true,
            name: 'Billing Information'
        },{
            name: "company",
            label: "Company",
            placeholder: "Please Enter Company name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.company : '',
            col:3
        },{
            name: "company_email",
            label: "Company Email Address",
            placeholder: "Please Enter Company Email Address",
            required: false,
            type: 'email',
            value: editableObj?editableObj.company_email : '',
            col:3
        },{
            name: "company_contact",
            label: "Company Contact No",
            placeholder: "Please Enter Company Contact Number",
            required: false,
            type: 'number',
            value: editableObj?editableObj.company_contact : '',
            col:3
        },{
            name: "gst",
            label: "Company GST Number",
            placeholder: "Please Enter Company GST Number",
            required: false,
            type: 'text',
            value: editableObj?editableObj.gst : '',
            col:3
        },{
            name: "pan",
            label: "Company PAN Card Number",
            placeholder: "Please Enter Company PAN Card Number",
            required: false,
            type: 'text',
            value: editableObj?editableObj.pan : '',
            col:3
        },{
            name: "cin",
            label: "Company CIN Number",
            placeholder: "Please Enter Company CIN Number",
            required: false,
            type: 'text',
            value: editableObj?editableObj.cin : '',
            col:3
        },{
            name: "credit_days",
            label: "Credit Days",
            placeholder: "Credit Days",
            required: false,
            type: 'number',
            value: editableObj?editableObj.creadit_days : '',
            col:3
        },{
            name: "company_address",
            label: "Company Address",
            placeholder: "Please Enter Company Address",
            required: false,
            type: 'text',
            value: editableObj?editableObj.company_address : '',
            col:3
        }
    ]

    const handleSearch = (event) => {
        if(event.target.value.length > 3)
            setSearchProduct(clientProductModel.filter(v => (v.title).toLowerCase().includes(event.target.value) ))
            
        if(event.target.value.length == 0)
        setSearchProduct(clientProductModel)
    };

    const addressShare = async() => {
        setWPCall(true);
        const obj = {
            number: wpNumber,
            name: wpModalOpen?.clientname,
            address:wpModalOpen?.address,
            shipping: wpModalOpen?.shipping_address,
            mobile_number: wpModalOpen?.mobile,
            whatsapp_number: wpModalOpen?.whatsappno
        }

        const res = await post(`${WHATSAPP_SHAREADDRESS_URL}`, obj);
        if(res?.status === 200){
            Notifications(`Client Address Send to Whatsapp.!`,'success')
            setWPNumber('');
            setWPCall(false);
            setWPModalOpen(false);
        }else{
            Notifications(`Please, try again.!`, 'error')
            setWPNumber('');
            setWPCall(false);
            setWPModalOpen(false);
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags><title>Dashboard | {isType}</title></MetaTags>
                <Container fluid>
                    <Breadcrumbs pageTitle="Masters" title="Masters" breadcrumbItem="Clients" />
                    <Row>
                        <Col xl="12">
                            {
                                <Mdatatable
                                    enableRowAction={isActionColumn}
                                    userModules={isActions}
                                    title={`All ${isType}`}
                                    columns={datatableData}
                                    data={dataRows}
                                    handleEdit={handleEdit}
                                    handleRemove={handleRemove}
                                    isEdit={isEdit}
                                    isDelete={isDelete}
                                    loading={isLoading}
                                    handleAddButton={() => {
                                        setEditableObj(null)
                                        isOpen(true)
                                    }}
                                    Other={{
                                        isHandleStatus: true,
                                        isHandleEditProduct: true,
                                        isBlocking: isBlocking,
                                        isProduct: isProduct,
                                        handleEditProduct: handleEditProduct,
                                        handleStatus: handleStatus,
                                        addressShare: (data) => {
                                            setWPModalOpen(data); 
                                        }
                                    }}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Datatable */}
            {
                open && 
                <FormComponent
                    title={`${editableObj ? 'Edit' : 'Add'} Client`}
                    Position={"right"}
                    isOpen={open}
                    fileds={htmlFormFields}
                    width={75}
                    callback={callback}
                    loading={loading}
                    editableObj={editableObj}
                    onDrawerClose={() => {
                        setEditableObj(null)
                        isOpen(!open)
                    }}
                    submitForm={submitForm}
                />
            }
            {/* End Datatable */}

            {/* clientProductModel */}
            {
                isOepnProductModal ? 
                <Modal
                    size="lg"
                    isOpen={isOepnProductModal}
                    toggle={() => {
                        setIsOepnProductModal(false)
                        setClientProductModel(null);
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" > Manage Client Products </h5>
                        <button
                            onClick={() => {
                                setIsOepnProductModal(false)
                                setClientProductModel(null);
                            }}
                            type="button"
                            disabled={finalpriceBtn}
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <input type="text" className="form-control mb-2" placeholder="search by product name" onChange={handleSearch} />

                        <div className="col-xs-12" style={{height: '350px', overflowY: 'scroll'}}>
                            <table className="table" border="1" width="100%">
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Product</th>
                                        <th>Unit Price</th>
                                        <th>Discount</th>
                                        <th>Final Price</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        searchProduct?.map((v, i) => 
                                            <tr key={`CPD_${i}`}>
                                                <td>{i+1}</td>
                                                <td>{v.title} ({v.product_id})</td>
                                                <td>{v.unit_price}</td>
                                                <td><input onChange={(e) => handdleFinalPrice(e.target.value, v.product_id, v.unit_price, v.client_id)} style={{width: '60px'}} defaultValue={v.discount} /></td>
                                                <td>{finalPrice[v.product_id]?.value || parseFloat(v.unit_price) - parseFloat(v.discount)}</td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button"
                            disabled={finalpriceBtn}
                            className="btn btn-secondary"
                            onClick={() => {
                                setIsOepnProductModal(false)
                                setClientProductModel(null);
                            }} >{finalpriceBtn ? <i className="fas fa-spinner fa-spin" /> : 'Close'} </button>
                    </div>
                </Modal> : ''
            }
            {
                wpModalOpen ? 
                <Modal
                    size="sm"
                    isOpen={Object.keys(wpModalOpen)?.length > 0 ? true : false }
                    toggle={() => {
                        setWPModalOpen(false);
                        setWPCall(false);
                    }}
                    centered={true}
                >
                    <div className="modal-header">
                        <h5 className="modal-title mt-0" > Share Client Address </h5>
                        <button
                            onClick={() => {
                                setWPModalOpen(false);
                                setWPCall(false);
                            }}
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col-md-12">
                                <label>Please Enter Whatsapp Number</label>
                                <input style={{marginBottom: '6px'}} className="form-control" defaultValue={""} onChange={(e) => {
                                    setWPNumber(e.target.value)
                                }} />
                                <span className="text-danger">Do not include country code i.e: +91 or 91</span>
                            </div>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button 
                            type="button"
                            disabled={wpCall}
                            className="btn btn-secondary"
                            onClick={() => {
                                setWPModalOpen(false)
                            }} >{'Close'} </button>
                        <button 
                            type="button"
                            disabled={wpCall}
                            className="btn btn-primary"
                            onClick={() => {
                                if(wpNumber){
                                    addressShare()
                                }else{
                                    alert('Please enter whatsapp number');
                                }
                            }} >{wpCall ? <i className="fas fa-spinner fa-spin" /> : 'Send'} </button>
                    </div>
                </Modal> : ''
            }
        </React.Fragment>
    )
}

export default Clients
