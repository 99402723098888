import { AvField, AvForm } from "availity-reactstrap-validation";
import React from "react";
import MetaTags from 'react-meta-tags';
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import { loginUser } from "../../store/actions";
// import images
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";

import { useDispatch, useSelector } from 'react-redux';
import CarouselPage from "../../components/CarouselPage";

const Login = props => {
  const dispatch = useDispatch()
  const { error, loading } = useSelector(state => state.Login)
  const submitLogin = (e, v) => dispatch(loginUser(v, props.history))
  
  return (
    <React.Fragment>
      <div>
        <MetaTags>
          <title>Login | keep Smile</title>
        </MetaTags>
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col xl={9}>
              <CarouselPage />
            </Col>
            <Col xl={3} style={{ background: "#f4f4f4"}}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mt-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="72"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="70"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p className="text-muted">
                          Sign in to continue to Dashboard.
                        </p>
                      </div>

                      <div className="mt-4">
                        <AvForm
                          className="form-horizontal"
                          onValidSubmit={(e, v) => {
                            submitLogin(e, v)
                          }}
                        >
                          <div className="mb-3">
                            <AvField
                              name="username"
                              label="Username or Email"
                              className="form-control"
                              placeholder="Enter username or email"
                              type="text"
                              value=""
                              required
                            />
                          </div>
                          <div className="mb-3">
                            <div className="float-end">
                              <Link
                                to="auth-recoverpw-2"
                                className="text-muted"
                              >
                                Forgot password?
                              </Link>
                            </div>
                            <AvField
                              name="password"
                              label="Password"
                              className="form-control"
                              placeholder="Enter password"
                              type="password"
                              value=""
                              required
                            />
                          </div>

                          {
                            error?.data &&
                            <b className="text-danger">{error?.data.message}</b> 
                          }
                          
                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >{loading && <i className="fas fa-spinner fa-spin me-2"/>} Log In</button>
                          </div>
                        </AvForm>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} keepsmile. Crafted with{" "}
                        <i className="mdi mdi-heart text-danger"></i> by
                        UMI
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
