import { call, takeEvery, put } from "redux-saga/effects"
import { del, get, post } from "helpers/api_helper"

import {
    ADD_EMPLOYEE_RECORD,
    GET_EMPLOYEE_ALL_ROWS,
    REMOVE_EMPLOYEE_RECORD
} from './actionType'

import {
    getEmployeeAllRowsSuccess,
    updateEmployeeRecord,
    pushEmployeeNewRecord
} from "./actions"

const getAllRows = ({url}) => get(url)
const addCall = ({url, Obj}) => post(url, Obj)
const removeCall = ({ url }) => del(url)

function* actionEmployeeGetAllRows({ payload: { data, history, cb } }) {
    try {
      const res = yield call(getAllRows, data)
      if(res?.response){  
        if(data.isSingle){
            yield call(cb, res.response)
        }else{
            yield put(getEmployeeAllRowsSuccess({data:res.response, isType:data.isType}))
        }
      }
    } catch (error) {}
}

function* actionEmployeeAddRecord({payload: {data, history, addupdate_callback}}){
    try{
        const res = yield call(addCall, data)
        if(res?.status == 200){
            data?.id ? yield put(updateEmployeeRecord({isType: data.isType, data:res.response, isUpdate: true})): yield put(pushEmployeeNewRecord({isType: data.isType, data:res.response}))
            yield call(addupdate_callback)
        }
    }catch (error) {}
}

function* actionEmployeeRemoveRecord({payload: {data, cb}}){
    try{
        const res = yield call(removeCall, data)
        if(res.status == 200){
            yield call(cb, data)
            yield put(updateEmployeeRecord({data:data.data, isType:'status'}))
        }
    }catch (error) {}
}

function* EmployeeSaga() {
    yield takeEvery(GET_EMPLOYEE_ALL_ROWS, actionEmployeeGetAllRows)
    yield takeEvery(ADD_EMPLOYEE_RECORD, actionEmployeeAddRecord)
    yield takeEvery(REMOVE_EMPLOYEE_RECORD, actionEmployeeRemoveRecord)
}
  
export default EmployeeSaga
  