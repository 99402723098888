import React, { useState } from 'react';
import { Modal } from "reactstrap"
import { uploadfile } from 'helpers/api_helper'
import { RX_UPLOAD } from 'helpers/url_helper'

import { updateRxState } from "store/actions"
import { useDispatch } from "react-redux";

const FileUploader = ({data, callback}) => {
    const dispatch = useDispatch()
    const [file, setFile] = useState()
    const [isUploading, setIsUploading] = useState(false)

    const acceptedFiles = async() => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('order_number', data.ORNO);
        formData.append('id', data.ID);
        setIsUploading(!isUploading)

        const res = await uploadfile(RX_UPLOAD, formData)
        if(res.status == 200){
            setIsUploading(false)
            callback()    
            dispatch(updateRxState(res.response, data.id))
        }
    } 
    
    return (
        <Modal
            size="md"
            isOpen={data?.isOpen}
            centered={true}
        >
            <div className="modal-header">
                <b>RX Upload</b>
            </div>
            <div className="modal-body">
                <input type="file" onChange={(e) => setFile(e.target.files[0])}/>
            </div>
            <div className="modal-footer">
                <button 
                    type="button"
                    title="close modal"
                    className="btn btn-secondary"
                    disabled={isUploading}
                    onClick={callback} >Close</button>
                    
                <button title="Upload file" className='btn btn-primary' onClick={acceptedFiles} disabled={isUploading}> {isUploading ? <i className='fas fa-spinner fa-spin' /> : '' } Upload</button>
            </div>
        </Modal>
    );
};

export default FileUploader;