import { get, post } from 'helpers/api_helper';

const preFixUrl = 'api/selectors'

export const getBranches = async() => {
    const res = await get(`${preFixUrl}/getBranchs`);

    if(res.status === 200) {
        return res.response;
    }
    return 'all'
}

export const getOptions = async(branch, sufix, other) => {

    const endPoint = {
        'clients': 'getclients',
        'categories': 'getproductcategories',
        'productTypes': 'getproducttypes',
        'products': 'getproducts',
        'cities': 'getCities'
    }

    let postData = {
        "branch": branch
    }

    if(other != null) {
        postData = {...postData, ...other}
    }

    const res = await post(`${preFixUrl}/${endPoint[sufix]}`, postData);

    if(res.status === 200) {
        return res.response;
    }
    return 'all'
}