import { AvField, AvForm } from "availity-reactstrap-validation";
import { getCode, userRole } from 'helpers/basic_helper';
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import Drawer from 'react-modern-drawer';
import 'react-modern-drawer/dist/index.css';
import { GetHelper } from '../../Masters/CallHelper';

const Form = ({ Position, isOpen, onDrawerClose, title, width = 50, submitForm, editableObj, DDMasters, length = 0}) => {
    const [date, setDate] = useState({
        dob:new Date(),
        doj:new Date()
    });

    const [roles, setRoles] = useState([])

    const [data, setData] = useState({
        'id': "",
        'firstname' : '',
        'middlename' : '',
        'lastname' : '',
        'gender' : '',
        'code' : '',
        'image' : '',
        'mobile' : '',
        'phone' : '',
        'dob' : '',
        'doj' : '',
        'email' : '',
        'role' : '',
        'shift' : '',
        'shiftgroup' : '',
        'designation' : '',
        'department' : '',
        'lab_department' : '',
        'location' : '',
        'pan' : '',
        'uid' : '',
        'address1' : '',
        'address2' : ''
    });

    useEffect(() => {
        if(editableObj != null){
            setData({
                'id': editableObj ? editableObj?.id : "",
                'firstname' : editableObj ? editableObj.firstname : '',
                'middlename' : editableObj ? editableObj.middlename : '',
                'lastname' : editableObj ? editableObj.lastname : '',
                'gender' : editableObj ? editableObj.gender : '',
                'code' : editableObj ? editableObj.code : '',
                'image' : editableObj ? editableObj.image : '',
                'mobile' : editableObj ? editableObj.mobile : '',
                'phone' : editableObj ? editableObj.phone : '',
                'dob' : (editableObj && editableObj?.dob) ? new Date(editableObj.dob) : '',
                'doj' : (editableObj && editableObj?.doj) ? new Date(editableObj.doj) : ' ',
                'email' : editableObj ? editableObj.email : '',
                'role' : editableObj ? editableObj.role : '',
                'shift' : editableObj ? editableObj.shift : '',
                'shiftgroup' : editableObj ? editableObj.shiftgroup : '',
                'designation' : editableObj ? editableObj.designation : '',
                'department' : editableObj ? editableObj.department : '',
                'lab_department' : editableObj ? editableObj.lab_department : '',
                'location' : editableObj ? editableObj.location : '',
                'pan' : editableObj ? editableObj.pan : '',
                'uid' : editableObj ? editableObj.uid : '',
                'address1' : editableObj ? editableObj.address1 : '',
                'address2' : editableObj ? editableObj.address2 : ''
            })
        }
    }, [editableObj])
    
    useEffect(async() => {
        const getRoles = await GetHelper(`${process.env.REACT_APP_ROLE}`)
        setRoles(getRoles.map(item => ({
            value: item.id,
            label: item.title
        })))
    }, [])

    //FormFields
    let fields = [
        {
            name: "id",
            label: "",
            placeholder: "",
            required: false,
            type: 'hidden',
            value: editableObj? editableObj.id : '',
            col:12
        },{
            name: "branch",
            label: "Branch",
            placeholder: "Please Select Branch",
            required: true,
            type: 'select',
            value: editableObj? editableObj.branch : '',
            options: DDMasters.branches?.map(v => ({label: v.title, value: v.value})),
            col:12
        },{
            name: "firstname",
            label: "First Name",
            placeholder: "Please Enter First Name",
            required: true,
            type: 'text',
            value: editableObj? editableObj.firstname : '',
            col:4
        },{
            name: "middlename",
            label: "Middle Name",
            placeholder: "Please Enter Middle Name",
            required: false,
            type: 'text',
            value: editableObj?editableObj.middlename:'',
            col: 4
        },{
            name: "lastname",
            label: "Last Name",
            placeholder: "Please Enter Product Last Name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.lastname : '',
            col:4
        },{
            name: "gender",
            label: "Gender",
            placeholder: "Please Select Gender",
            required: true,
            type: 'select',
            value: editableObj?editableObj.gender : {value: 1, label: 'Male'},
            col:4,
            options: [
                {value: null, label: '---'},
                {value: 1, label: 'Male'},
                {value: 2, label: 'Female'}
            ]
        },{
            name: "dob",
            label: "Date of Birth",
            placeholder: "Please Select Date of Birth",
            required: true,
            type: 'date',
            value: (editableObj && editableObj?.dob) ? new Date(editableObj.dob) : '',
            col:4
        },{
            name: "code",
            label: "Employee Code",
            placeholder: "Please Enter Employee Code",
            required: false,
            type: 'text',
            value: editableObj?editableObj.code : getCode("employee", length),
            col:4
        },{
            name: "mobile",
            label: "Mobile Number",
            placeholder: "Please Enter Mobile Number",
            required: false,
            type: 'number',
            value: editableObj?editableObj.mobile : '',
            col:4
        },{
            name: "phone",
            label: "Phone Number",
            placeholder: "Please Enter Phone Number",
            required: false,
            type: 'number',
            value: editableObj?editableObj.phone : '',
            col:4
        },{
            name: "email",
            label: "Email Address",
            placeholder: "Please Enter Email Address",
            required: false,
            type: 'text',
            value: editableObj?editableObj.email : '',
            col:4
        },{
            name: "doj",
            label: "Date of Join",
            placeholder: "Please Select Date of Join",
            required: false,
            type: 'date',
            value: (editableObj && editableObj?.doj) ?new Date(editableObj.doj) : '',
            col:6
        },{
            name: "role",
            label: "Employee Role",
            placeholder: "Please Select Employee Role",
            required: true,
            type: 'select',
            value: editableObj?editableObj.role : '',
            col:6,
            options: roles.filter(v => v.label != "Superadmin" )
        },{
        //     name: "shift",
        //     label: "Shift",
        //     placeholder: "Please Select Employee Shift",
        //     required: true,
        //     type: 'select',
        //     value: editableObj?editableObj.shift : '',
        //     col:6,
        //     options: [
        //         {value: 1, label: "Full Day"},
        //         {value: 2, label: "Custom"}
        //     ]
        // },{
        //     name: "shiftgroup",
        //     label: "Shift Group",
        //     placeholder: "Please Select Employee Shift Group",
        //     required: true,
        //     type: 'select',
        //     value: editableObj?editableObj.shiftgroup : '',
        //     col:6,
        //     options: [
        //         {value: 1, label: "Group 1"},
        //         {value: 2, label: "Group 2"}
        //     ]
        // },{
            name: "department",
            label: "Department",
            placeholder: "Please Select Employee Department",
            required: true,
            type: (userRole() == 'Superadmin') ? 'hidden' : 'select',
            value: editableObj?editableObj.department : '',
            col:6,
            options: DDMasters.department?.map(v => ({label: v.title, value: v.code}))
        },{
            name: "designation",
            label: "designation",
            placeholder: "Please Select Employee designation",
            required: true,
            type: (userRole() == 'Superadmin') ? 'hidden' : 'select',
            value: editableObj?editableObj.designation : '',
            col:6,
            options: DDMasters.designation?.map(v => ({label: v.title, value: v.code}))
        },{
            name: "lab_department",
            label: "Lab Department",
            placeholder: "Please Select Lab Department",
            required: true,
            type: (userRole() == 'Superadmin') ? 'hidden' : 'select',
            value: editableObj?editableObj.lab_department : null,
            col:6,
            options: DDMasters.lab_department?.map(v => ({label: v.title, value: v.code}))
        },{
        //     name: "location",
        //     label: "Location",
        //     placeholder: "Please Select Employee Location",
        //     required: true,
        //     type: 'select',
        //     value: editableObj?editableObj.location : '',
        //     col:6,
        //     options: DDMasters.locations?.map(v => ({label: v.title, value: v.code}))
        // },{
            name: "pan",
            label: "Pan Number",
            placeholder: "Please Enter Pan Number",
            required: false,
            type: 'text',
            value: editableObj?editableObj.pan : '',
            col:6
        },{
            name: "uid",
            label: "UID",
            placeholder: "Please Enter UID",
            required: false,
            type: 'text',
            value: editableObj?editableObj.uid : '',
            col:6
        },{
            name: "address1",
            label: "Cueernt Address",
            placeholder: "Please Enter Current Address",
            required: false,
            type: 'textarea',
            value: editableObj?editableObj.address1 : '',
            col:6
        },{
            name: "address2",
            label: "Permanent Address",
            placeholder: "Please Enter Permanent Address",
            required: false,
            type: 'textarea',
            value: editableObj?editableObj.address2 : '',
            col:6
        }
    ];

    return (
        <Drawer
            open={isOpen}
            onClose={onDrawerClose}
            direction={Position}
            style={{
                width: `${width}%`
            }}
        >
            <>
                <div className="rightbar-title px-3 py-4">
                    <span className="right-bar-toggle float-end cursor-pointer" title="close" onClick={onDrawerClose}><i className="mdi mdi-close noti-icon"></i></span>
                    <h5 className="m-0">{title}</h5>
                </div>
                <hr className="my-0"></hr>
                <div className='col-md-12' style={{padding: '15px', height: '90vh', overflow: 'auto'}}>
                    <AvForm
                        className="form-horizontal"
                        onValidSubmit={() => {
                            submitForm(data)
                        }}
                    >
                        <div className='row'>
                            {/* <div className="col-md-3 mb-3">
                                <Dropzone />
                            </div> */}
                            <div className="col-md-12 mb-3">
                                <div className='row'>
                                    {
                                        fields.map((v, i) => {
                                            if(v.type !== 'hidden'){

                                                return v.type === 'select' ?
                                                <div className={`col-md-${v.col} mb-3`} key={`${i}_div`}>
                                                    <label>{v.label}</label>
                                                    <select
                                                        className={`form-control select2 ${v.class}`}
                                                        name={v.name}
                                                        value={data[v.name] ? data[v.name] : ''}
                                                        onChange={e => setData(c => ({...c, [v.name]: e.target.value }))} >
                                                        <option value=""> --- </option>
                                                        { v.options?.map( (vp, ip) => <option value={vp.value} key={`AT_${i}_${Math.floor(Math.random() * new Date().getTime())}`}> {vp.label} </option>) }
                                                    </select>
                                                </div>
                                                :
                                                <div className={`col-md-${v.col} mb-3`} key={`${i}_div`}>
                                                    {
                                                        v.type === 'date' ?
                                                        <>
                                                            <label>{v.label}</label>
                                                            <DatePicker className={`form-control ${v.class}`} selected={ data[v.name] || v.value } onChange={(x) => {
                                                                setDate(p => ({...p, [v.name]: x}))
                                                                setData(c => ({...c, [v.name]: x}))
                                                            }} />
                                                        </> :
                                                        <AvField
                                                            key={`${i}_input`}
                                                            name={v.name}
                                                            label={v.label}
                                                            className={`form-control ${v.class}`}
                                                            placeholder={v.placeholder}
                                                            type={v.type}
                                                            value={v.value}
                                                            onChange={e => setData(c => ({...c, [v.name]: e.target.value }))}
                                                            required={v.required}
                                                        />
                                                    }
                                                </div>
                                            }

                                            if(v.name === 'id'){
                                                <div className={`col-md-${v.col} mb-3`} key={`${i}_div`}>
                                                    <AvField
                                                        key={`${i}_input`}
                                                        name={v.name}
                                                        label={v.label}
                                                        className={`form-control ${v.class}`}
                                                        placeholder={v.placeholder}
                                                        type={v.type}
                                                        value={v.value}
                                                        onChange={e => setData(c => ({...c, [v.name]: e.target.value }))}
                                                        required={v.required}
                                                    />
                                                </div>
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="mt-3 d-grid">
                            <button
                                className="btn btn-primary btn-block "
                                type="submit"
                            >Submit</button>
                            {/* >{isType == false ? 'Submit' : 'Update'}</button> */}
                        </div>
                    </AvForm>
                </div>
            </>
        </Drawer>
    );
}

export default Form;