import {
    ADD_ORDER,
    ADD_ORDER_SUCCESS,
    EMPTY_ORDERS,
    GET_ALL_OPTIONS,
    GET_ALL_OPTIONS_SUCCESS,
    GET_ALL_ORDERS,
    GET_ALL_ORDERS_SUCCESS,
    GET_CASENUMBER,
    GET_CASENUMBER_SUCCESS,
    GET_JOBCARD,
    GET_ORDER_OPTION,
    GET_ORDER_OPTION_SUCCESS,
    GET_SINGLE_ORDER,
    GET_SINGLE_ORDER_SUCCESS,
    UPDATE_RX_STATE,
    UPDATE_STATE
} from './actionTypes'

const INIT_STATE = {
    categories: [],
    category_types: [],
    product_list: [],
    orders: [],
    singleOrder: [],
    loading: false,
    client:{},
    DDorderOptions:[]
}

const OrdersR = (state = INIT_STATE, action) => {
    const { type, payload } = action

    switch (type) {
        case EMPTY_ORDERS:
            return {
                ...state,
                orders: [],
            }
        case GET_ALL_ORDERS:
            return {
                ...state,
                orders: [],
                loading: true,
            }
        case GET_ALL_OPTIONS:
        case ADD_ORDER:
        case GET_JOBCARD:
        case GET_SINGLE_ORDER:
            return {
                ...state,
                singleOrder: [],
                loading: true,
            }
        case GET_ORDER_OPTION:
            return {
                DDorderOptions: [],
                loading: true,
            }
        case GET_ORDER_OPTION_SUCCESS:
            return {
                ...state,
                loading: false,
                DDorderOptions: payload.data
            }
        case GET_ALL_OPTIONS_SUCCESS:
            const type = payload.isType

            switch (type) {
                case 'types':
                    return {
                        ...state,
                        loading: false,
                        category_types: payload.data
                    }
                case 'product':
                    return {
                        ...state,
                        loading: false,
                        product_list: payload.data
                    }
                default:
                    return {
                        ...state,
                        loading: false,
                        categories: payload.data
                    }
            }
        case ADD_ORDER_SUCCESS:
            return {
                ...state,
                loading: false
            }
        case GET_ALL_ORDERS_SUCCESS:
            return {
                ...state,
                loading: false,
                orders: payload.data || []
            }
        case UPDATE_STATE:GET_ALL_ORDERS
            return {
                ...state,
                loading: false,
                orders: {...state.orders, rows: state.orders.rows.map(v => (payload.id == v.id) ? {...v, status: payload.status} : v)} 
            }
        case UPDATE_RX_STATE:
            return {
                ...state,
                loading: false,
                orders: {...state.orders, rows: state.orders.rows.map(v => (payload.file_id == v.id) ? {...v, rxfile: payload.path} : v)} 
            }
        case GET_SINGLE_ORDER_SUCCESS:
            return {
                ...state,
                loading: false,
                singleOrder: payload
            }
        case GET_CASENUMBER:
            return {
                ...state,
                client: {}
            }
        case GET_CASENUMBER_SUCCESS:
            return {
                ...state,
                loading: false,
                client: action.payload?.data
            }
        default:
            return state
    }
}

export default OrdersR