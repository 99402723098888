import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import "./datatables.scss";

// datatable related plugins
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import moment from "moment";

const MyExportCSV = (props) => {
  const handleClick = () => {
    // eslint-disable-next-line react/prop-types
    props.onExport();
  };
  return <button className="btn" onClick={handleClick}>Export to CSV</button>
};

// eslint-disable-next-line react/prop-types
const Datatables = ({ 
  showTableOnly=false,
  filename= '',
  isSearch = false,
  rowsLength = false,
  handleAddButton,
  title,
  columns,
  rows,
  loading,
  selectField,
  defaultSorted,
  keyField,
  btnTitle = 'Add',
  isAdd = true,
  isTableHead = true,
  isDisabled = false,
  isCheckbox=false,
  ssr = ()=>{} }
) => {

  const [page, setPage] = useState(1);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [searchTerm, setSearchTerm] = useState(''); // State for the search input
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm); // State for the debounced input

  useEffect(() => {
      const handler = setTimeout(() => {
          setDebouncedSearchTerm(searchTerm);
      }, 1000);

      return () => {
          clearTimeout(handler);
      };
  }, [searchTerm]);

  // Effect to log the debounced search term
  useEffect(() => {
    if(searchTerm != '')
      ssr({page, sizePerPage, searchTerm})
  }, [debouncedSearchTerm]);

  const sizePerPageList = [
    { text: '10', value: 10 },
    { text: '25', value: 25 },
    { text: '50', value: 50 },
    { text: '100', value: 100 },
    { text: 'All', value: rowsLength }
  ];

  const pageOptions = {
    sizePerPage: sizePerPage,
    totalSize: rowsLength,
    page: page,
    sizePerPageList: sizePerPageList,
  }

  const handleTableChange = (type, { page, sizePerPage }) => {
    setPage(page);
    setSizePerPage(sizePerPage);
    ssr({page, sizePerPage, searchTerm})
  };

  // if(showTableOnly){
  //   return <div className="table-responsive mt-4">
  //     {
  //       loading ? (
  //           <div className="text-center my-3">
  //                   <span className="">Loading...</span>
  //           </div>
  //       ):
  //       <BootstrapTable
  //         keyField={keyField}
  //         data={rows}
  //         columns={columns}
  //         responsive
  //         bordered={true}
  //         striped={false}
  //         classes="table bg-white align-middle table-nowrap"
  //         headerWrapperClasses={"thead-light"}
  //         remote
  //         sort={defaultSorted}
  //         pagination={paginationFactory(pageOptions)}
  //         onTableChange={handleTableChange}
  //       />
  //     }
  //   </div>
  // }

  return (
    <React.Fragment>
      <Row>
        <Col className="col-12">
          <Card>
            <CardBody>
              {
                isTableHead ?
                <Row>
                  <Col sm="7">
                    <CardTitle className="h2" style={{ marginTop: "10px" }}>
                      {title}
                    </CardTitle>
                  </Col>

                  {selectField}
                  
                  {
                    isSearch && 
                    <Col>
                      <div className="search-box me-2 mb-2 d-inline-block" style={{ width: "100%" }}>
                        <div className="position-relative">
                          <input className="form-control" placeholder={'By Order Number or Modal Number'} onChange={(e) => setSearchTerm(e.target.value.trim())} value={searchTerm} />
                          <i className="bx bx-search-alt search-icon" />
                        </div>
                      </div>
                    </Col>
                  }
                  {
                    isAdd ?
                    <Col md={isSearch ? 2: 5}>
                      <Button
                        disabled={isDisabled}
                        color="primary"
                        style={{ float: "right" }}
                        onClick={handleAddButton}
                        onKeyDown={handleAddButton}
                      >
                        <i className={`fas ${"fa-plus" }`}>{" "}{btnTitle}</i>
                      </Button>
                    </Col> : ''
                  }
                </Row> : ''
              }

              <div className=" mt-4">
                {loading ? (
                    <div className="text-center my-3">
                            <span className="">Loading...</span>
                    </div>
                ):
                (
                  isCheckbox ?
                  <BootstrapTable
                    keyField={keyField}
                    data={rows}
                    columns={columns}
                    responsive
                    bordered={true}
                    striped={true}
                    classes="table align-middle table-nowrap"
                    headerWrapperClasses={"thead-light"}
                    sort={defaultSorted}
                    onTableChange={handleTableChange}
                  />:
                  <BootstrapTable
                    keyField={keyField}
                    data={rows}
                    columns={columns}
                    responsive
                    bordered={true}
                    striped={true}
                    classes="table align-middle table-nowrap"
                    headerWrapperClasses={"thead-light"}
                    remote
                    sort={defaultSorted}
                    pagination={paginationFactory(pageOptions)}
                    onTableChange={handleTableChange}
                  />
                )
              }
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  )
}

export default Datatables
