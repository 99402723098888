import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import Notifications from "components/Notifications"
import DeleteConfirmationModel from "components/delete-confirmation-model"
import { get, post } from 'helpers/api_helper'
import { getCode } from 'helpers/basic_helper'
import { ROLE_PERMISSION, ROLE_PERMISSION_GET, ROLE_PERMISSION_SAVE } from 'helpers/url_helper'
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap"
import ToggleSwitch from '../../../components/Common/ToggleSwitch'
import { GetHelper, SubmitHelper } from '../CallHelper'
import FormComponent from "../FormComponent"

const isType = 'roles'
const Role = () => {
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDeleteModel, setIsDeleteModel] = useState(false)
    const [basePermissions, setBasePermissions] = useState(null)
    const [selectRole, setSelectRole] = useState(false)
    const [checkedState, setCheckedState] = useState({});
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [error, setError] = useState(false);

    const handleChildCheckboxChange = (permission, subPermission, type) => {
        setCheckedState((prevState) => {

            let updatedSubPermissionState = {
                ...prevState[permission]?.[subPermission],
                [type]: !prevState[permission]?.[subPermission]?.[type]
            };

            if(type === 'all'){
                updatedSubPermissionState ={
                    ...prevState[permission]?.[subPermission],
                    all: !prevState[permission]?.[subPermission]?.[type],
                    add: !prevState[permission]?.[subPermission]?.[type],
                    edit: !prevState[permission]?.[subPermission]?.[type],
                    delete: !prevState[permission]?.[subPermission]?.[type]
                }
            }

            const data = {
                ...prevState,
                [permission]: {
                    ...prevState[permission],
                    [subPermission]: updatedSubPermissionState,
                }
            }
            return data;
        });
    };

    const getBasePermissions = async() => {
        const res = await get(`${ROLE_PERMISSION}`)
        if(res.status === 200){
            setBasePermissions(res.response)
        }
    }

    /**
     * Get All Rows
     * @param {row_id} ID
     * @method {get} GET 
     */
    const getData = async(ID = false) => {
        !ID ? setIsLoading(!isLoading) : false
        let res = await GetHelper(`${process.env.REACT_APP_ROLE}`, ID)
        res = res.filter(v => v.title !== 'Superadmin')
        if(ID){
            setEditableObj(...res)
            isOpen(true)
            setIsEdit(false)
        }else{
            setDataRows(res)
        }
        setIsLoading(false)
        if(res === undefined){
            setError(true)
        }

        getBasePermissions()
    }

    useEffect(() => getData(),[])

    /**
     * Submit or Update data Api Call
     * @param {Form Data} data 
     * @method {post} POST 
     */
    const submitForm = async(data) => {
        const checkCode = dataRows.filter(v => (v.code).toUpperCase() == (data.target.code.value).toUpperCase())
        if(data?.target?.id?.value =='' && checkCode.length > 0) {
            Notifications('please enter unique code.!', 'error')
            return;
        }

        const res = await SubmitHelper(`${process.env.REACT_APP_ROLE}`, {
            "title": data.target.title.value,
            "code": data.target.code.value
        }, data?.target?.id?.value || null);

        if(res){
            getData()
            isOpen(false)
            Notifications('You have successfully added a Role.!')
        }
    }
    
    /**
     * Get Single row using url params ?q=?
     * @param {Row_id} q 
     * @method {get} GET 
     */
    const handleEdit = q => {
        if(q > 0){
            setIsEdit(q)
            getData(q)
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {del} DELETE 
     */
    const handleRemove = async(q, status, code) => {
        if(q > 0) {
            setIsDeleteModel({url: process.env.REACT_APP_ROLE, q, status, code})
        }
    }
    const hideModel = () => {
        setIsDeleteModel(false)
        getData()
    }

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        }, {
            accessorKey: 'title',
            header: 'Role'
        }
    ];

    /**
     * HTML Form Columns 
     */
    let htmlFormFields = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj ? editableObj.id : '',
            col: 12
        }, {
            name: "title",
            label: "Role",
            placeholder: "Please Enter Role Title",
            required: true,
            type: 'text',
            value: editableObj ? editableObj.title : '',
            col: 12
        }, {
            name: "code",
            label: "Code",
            placeholder: "Code",
            required: true,
            type: 'text',
            value: editableObj ? editableObj.code : getCode("R", dataRows?.length),
            col: 12
        }
    ]

    const [expandedRows, setExpandedRows] = useState({});

    const handleRowToggle = (permission) => {
        setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [permission]: !prevExpandedRows[permission]
        }));
    };

    const renderPermissionTable = () => {
        const rows = [];
        
        Object.keys(basePermissions)?.forEach((v, i) => {
            const isRowExpanded = expandedRows[v];
            const parentRow = (
                <tr onClick={() => handleRowToggle(v)} key={`RP_${i + 1}`} className="bg-dark text-light">
                    <td width={10}>
                        {isRowExpanded ? '-' : '+'}
                    </td>
                    <td>{v?.toUpperCase().replace('_', ' ')}</td>
                    <td>Add</td>
                    {/* <td>Read</td> */}
                    <td>Edit</td>
                    <td>Delete</td>
                    <td>Other</td>
                    <td>All</td>
                </tr>
            );
            rows.push(parentRow);
            
            if (isRowExpanded) {
                const subRows = Object.keys(basePermissions[v]).map((M, I) => (
                    <tr key={`RP_${i + 1}_${I + 1}`}>
                        <td></td> 
                        <td>{M?.toUpperCase().replace('_', ' ')}</td>
                        <td>
                            <ToggleSwitch
                                checked={checkedState?.[v]?.[M]?.add || false}
                                onChange={() => handleChildCheckboxChange(v, M, 'add')}
                            />
                        </td>
                        {/* <td>
                            <ToggleSwitch
                                checked={checkedState?.[v]?.[M]?.read || false}
                                onChange={() => handleChildCheckboxChange(v, M, 'read')}
                            />
                        </td> */}
                        <td><ToggleSwitch
                            checked={checkedState?.[v]?.[M]?.edit || false}
                            onChange={() => handleChildCheckboxChange(v, M, 'edit')}
                        /></td>
                        <td><ToggleSwitch
                            checked={checkedState?.[v]?.[M]?.delete || false}
                            onChange={() => handleChildCheckboxChange(v, M, 'delete')}
                        /></td>
                        <td>
                            {
                                M == 'client' && 
                                <>
                                    <ToggleSwitch
                                        label="Client Blocking"
                                        checked={checkedState?.[v]?.[M]?.cb || false}
                                        onChange={() => handleChildCheckboxChange(v, M, 'cb')}
                                    /><br />
                                    <ToggleSwitch
                                        label="Product Discount"
                                        checked={checkedState?.[v]?.[M]?.pd || false}
                                        onChange={() => handleChildCheckboxChange(v, M, 'pd')}
                                    />
                                </>
                            }
                            {
                                M == 'blocked_client' && 
                                <>
                                    <ToggleSwitch
                                        label="Client Un-Blocking"
                                        checked={checkedState?.[v]?.[M]?.cub || false}
                                        onChange={() => handleChildCheckboxChange(v, M, 'cub')}
                                    />
                                </>
                            }
                            {
                                M == 'orders' && 
                                <>
                                    <ToggleSwitch
                                        label="Redu Order"
                                        checked={checkedState?.[v]?.[M]?.ro || false}
                                        onChange={() => handleChildCheckboxChange(v, M, 'ro')}
                                    />
                                    <br />
                                    <ToggleSwitch
                                        label="Currection Order"
                                        checked={checkedState?.[v]?.[M]?.co || false}
                                        onChange={() => handleChildCheckboxChange(v, M, 'co')}
                                    />
                                    <br />
                                    <ToggleSwitch
                                        label="Job Card"
                                        checked={checkedState?.[v]?.[M]?.jobcard || false}
                                        onChange={() => handleChildCheckboxChange(v, M, 'jobcard')}
                                    /><br />
                                    <ToggleSwitch
                                        label="lab Slip"
                                        checked={checkedState?.[v]?.[M]?.ls || false}
                                        onChange={() => handleChildCheckboxChange(v, M, 'ls')}
                                    />
                                </>
                            }
                        </td>
                        <td><ToggleSwitch
                            checked={checkedState?.[v]?.[M]?.all || false}
                            onChange={() => handleChildCheckboxChange(v, M, 'all')}
                        /></td>
                    </tr>
                ));
            rows.push(subRows);
            }
        });

        return rows;
    };

    const submitPermissions = async() => {
        const tempP = checkedState
        const Obj = {
            role: selectRole,
            permissions: JSON.stringify(tempP)
        }

        if(!selectRole){
            alert('Please Select Userrole>!');
        }

        const res = await post(ROLE_PERMISSION_SAVE, Obj)
        if(res.status === 200){
            setCheckedState(null)
            setSelectRole(false)
        }
    }

    useEffect(async() => {
        if(selectRole){
            const res = await post(ROLE_PERMISSION_GET, {role: selectRole});
            if(res.status === 200){
                const data = res?.response ? JSON.parse(res?.response?.permissions) :  {};
                if(data)setCheckedState(data)
            }
        }
    }, [selectRole])

    useEffect(async() => {
        // const data =  await userModuleData('masters', 'role')
        const state = JSON.parse(localStorage.getItem('authUser'))
    
        if(state.role == 'Superadmin'){
            const data = {
                edit: true,
                delete: true,
                add: true,
            }
        
            setIsActions(data)
            if(data && (data?.edit || data?.delete)){
                setIsActionColumn(true)
            }
        }

    }, [])

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags><title>Dashboard | {isType}</title></MetaTags>
                <Container fluid><Breadcrumbs title="Masters" breadcrumbItem={isType} />
                    <Row>
                        <Col md="3">
                            <Mdatatable
                                enableRowAction={isActionColumn}
                                userModules={isActions}
                                title={`All ${isType}`}
                                columns={datatableData}
                                data={dataRows}
                                handleEdit={handleEdit}
                                handleRemove={handleRemove}
                                isEdit={isEdit}
                                handleAddButton={() => {
                                    setEditableObj(null)
                                    isOpen(true)
                                }}
                            />
                        </Col>
                        <Col>
                            <Card>
                                <CardBody className="p-2">
                                    <Row>
                                        <Col>
                                            <h5>Access Level</h5>
                                        </Col>
                                        <Col>
                                            <select className="form-control" onChange={(e) => {
                                                setSelectRole(e.target.value)
                                            }}>
                                                <option>---</option>
                                                {
                                                    dataRows?.map((v,i) => <option key={`ROP_${i}`} value={v.code}>{v.title}</option>)
                                                }
                                            </select>
                                        </Col>
                                    </Row>
                                    <br />
                                    <Row>
                                        <Col style={{overflowY: "scroll", height: '65vh'}}>
                                            <table className="table table-bordered">
                                                <tbody>
                                                    {basePermissions && renderPermissionTable()}
                                                    <tr>
                                                        <td colSpan={8} style={{textAlign:'right'}}>
                                                            <button className="btn btn-primary" style={{height:'48px' ,width: '260px'}} onClick={() => submitPermissions()}>Save</button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

            {/* Datatable */}
            {
                open && 
                <FormComponent
                    title={`${editableObj ? 'Edit' : 'Add'} Role`}
                    Position={"right"}
                    isOpen={open}
                    fileds={htmlFormFields}
                    loading={isLoading}
                    editableObj={editableObj}
                    onDrawerClose={() => {
                        setEditableObj(null)
                        isOpen(false)
                    }}
                    submitForm={submitForm}
                />
            }
            {/* End Datatable */}

            { isDeleteModel && <DeleteConfirmationModel isOpen={true} changeState={hideModel} code={isDeleteModel} /> }
        </React.Fragment>
    )
}

export default Role
