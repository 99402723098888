import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import Notifications from "components/Notifications"
import DeleteConfirmationModel from "components/delete-confirmation-model"
import { getCode, userModuleData } from 'helpers/basic_helper'
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row
} from "reactstrap"
import { GetHelper, SubmitHelper } from '../CallHelper'
import FormComponent from "../FormComponent"

const isType = 'countries'
const Countries = props => {
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isEdit, setIsEdit] = useState(false)
    const [isDeleteModel, setIsDeleteModel] = useState(false)
    const [isActions, setIsActions] = useState(false)
    const [isActionColumn, setIsActionColumn] = useState(false)
    const [error, setError] = useState(false)
    
    useEffect(async() => {
        const data =  await userModuleData('masters', 'countries')
        setIsActions(data)
        if(data && (data?.edit || data?.delete)){
            setIsActionColumn(true)
        }
    }, [])
    /**
     * Get All Rows
     * @param {row_id} ID 
     * @method {get} GET 
     */
    const getData = async(ID = false) => {
        setIsLoading(true)
        const res = await GetHelper(`${process.env.REACT_APP_COUNTRY}`, ID)
        if(ID){
            setEditableObj(...res)
            isOpen(true)
            setIsEdit(false)
        }else{
            setDataRows(res)
        }
        setIsLoading(false)

        
        if(res === undefined){
            setError(true)
        }
    }
    useEffect(() => getData(),[])

    /**
     * Submit or Update data Api Call
     * @param {Form Data} data 
     * @method {post} POST 
     */
    const submitForm = async(data) => {
        const checkCode = dataRows.filter(v => (v.code).toUpperCase() == (data.target.code.value).toUpperCase())
        if(data?.target?.id?.value =='' && checkCode.length > 0) {
            Notifications('please enter unique code.!', 'error')
            return;
        }

        const res = await SubmitHelper(`${process.env.REACT_APP_COUNTRY}`, {
            "title": data.target.title.value,
            "code": (data.target.code.value).toUpperCase()
        }, data?.target?.id?.value || null);

        if(res){
            getData()
            isOpen(false)
            Notifications('Country Added Successful.!')
        }
    }

    /**
     * Get Single row using url params ?q=?
     * @param {Row_id} q 
     * @method {get} GET 
     */
    const handleEdit = q => {
        if(q > 0){
            setIsEdit(q)
            getData(q)
        }
    }

    /**
     * Chnage Row Status By ID and condition Based Status passing,
     * @param {row_id} q 
     * @param {row Status} status 
     * @method {del} DELETE 
     */
    const handleRemove = async(q, status, code) => {
        if(q > 0) {
            setIsDeleteModel({url: process.env.REACT_APP_COUNTRY, q, status, code})
        }
    }

    const hideModel = () => {
        setIsDeleteModel(false)
        getData()
    }


    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        }, {
            accessorKey: 'title',
            header: 'Country'
        }, {
            accessorKey: 'code',
            header: 'Code'
        }
    ];

    /**
     * HTML Form Columns 
     */
    let htmlFormFields = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : '',
            col:12
        },{
            name: "title",
            label: "Country Name",
            placeholder: "Country Name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.title : '',
            col:12
        },{
            name: "code",
            label: "Code",
            placeholder: "Code",
            required: true,
            type: 'text',
            value: editableObj?editableObj.code:getCode("country", dataRows?.length),
            col: 12
        }
    ]

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags><title>Dashboard | {isType}</title></MetaTags>
                <Container fluid>
                    <Breadcrumbs title="Masters" breadcrumbItem={isType} />
                    <Row>
                        <Col xl="12">
                            {
                                <Mdatatable
                                    enableRowAction={isActionColumn}
                                    userModules={isActions}
                                    title={`All ${isType}`}
                                    columns={datatableData}
                                    data={dataRows}
                                    handleEdit={handleEdit}
                                    handleRemove={handleRemove}
                                    isEdit={isEdit}
                                    handleAddButton={() => {
                                        setEditableObj(null)
                                        isOpen(true)
                                    }}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
            
            {/* Datatable */}
            {
                open && 
                <FormComponent
                    title={`${editableObj ? 'Edit' : 'Add'} Country`}
                    Position={"right"}
                    isOpen={open}
                    fileds={htmlFormFields}
                    loading={isLoading}
                    editableObj={editableObj}
                    onDrawerClose={(e) => {
                        setEditableObj(null)
                        isOpen(false)
                    }}
                    submitForm={submitForm}
                />
            }
            {/* End Datatable */}

            { isDeleteModel && <DeleteConfirmationModel isOpen={true} changeState={hideModel} code={isDeleteModel} /> }

        </React.Fragment>
    )
}

export default Countries
