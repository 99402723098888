import mark from 'assets/images/mark.png'
import { get, post } from 'helpers/api_helper'
import { TEMPLATE_URL, WHATSAPP, WHATSAPP_TEST_URL } from 'helpers/url_helper'
import { useEffect, useState } from "react"
import {
    Card,
    CardBody,
    Col,
    Label,
    Row
} from "reactstrap"
import Template from "./template"

const WPSettings = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [qrCode, setQrCode] = useState(null);
    const [error, setError] = useState(null);
    const [testNumber, setTestNumber] = useState(0);
    const [postObject, setPostObject] = useState({key:null, session: null, mobile: null});
    const [templateData, setTemplates] = useState(null);

    const saveCreds = async() => {
        const res = await post(WHATSAPP, postObject);
        if(res.status == 200){}
    }

    const getCreds = async() => {
        const res = await get(WHATSAPP);
        if(res.status === 200){
            const data = res.response
            setPostObject({
                key: data?.api_key,
                session:data?.session,
                mobile: data?.mobile_number
            });

            setIsConnected(parseInt(data?.is_connected))

            if(data?.qr?.status !== 409 && data?.is_connected == 0){
                setQrCode(data?.qr?.qr)
            }
        }
    }
    
    useEffect(() => {
        getCreds();
        templates();
    },[])

    const templates = async() => {
        const res = await get(`${TEMPLATE_URL}/3`);
        if(res.status === 200){
            const data = res.response
            if(typeof data !== 'string'){
                setTemplates(data)
            }
        }
    }

    const sendTestMsg = async() => {
        if(testNumber?.length < 10)
            alert('Please Enter valid mobile number.!')
        else{
            const res = await post(WHATSAPP_TEST_URL, {number: testNumber});
            if(res.status == 200){}
        }
    }

    const decodeHTML = (html) => {
        const txt = document.createElement('textarea');
        txt.innerHTML = html;
        return txt.value;
    };
    
    return (
        <Col>
            <Card>
                <CardBody>
                    <h4>Whatsapp Settings </h4>

                    <Row>
                        <Col>
                            <Row className="mt-2">
                                <Col>
                                    {
                                        !isConnected ?
                                        <>
                                            <Label>Api Key</Label>
                                            <input type="text" className="form-control" defaultValue={postObject?.key} onChange={(e) => {
                                                setPostObject(prev => ({...prev, key:e.target.value}))
                                            }}/>
                                            
                                            <Label className="mt-2">Registration / Session Name</Label>
                                            <input type="text" className="form-control" defaultValue={postObject?.session} onChange={(e) => {
                                                setPostObject(prev => ({...prev, session:e.target.value}))
                                            }}/>
                                            
                                            <Label className="mt-2">Whatsapp Number</Label>
                                            <input type="text" className="form-control" defaultValue={postObject?.mobile} onChange={(e) => {
                                                setPostObject(prev => ({...prev, mobile:e.target.value}))
                                            }}/>

                                            <Row className="mt-2">
                                                <Col>
                                                    <button style={{float:'right'}} className="btn btn-primary" onClick={() => saveCreds()}> Save </button>
                                                </Col>
                                            </Row>
                                            <hr />

                                            <Label>Scan QRCode <i onClick={() => getCreds()} title="reload QRCode" role="button" className="mdi mdi-refresh-circle" style={{fontSize: '18px'}} /></Label><br />
                                            <span className="text-danger">{error}</span>
                                            {
                                                !error &&
                                                <div style={{
                                                            border: '1px solid #f9f9f9',
                                                            width: '160px',
                                                            height: '160px',
                                                            margin: '0 auto',
                                                    }}>
                                                {
                                                    !qrCode ? <><i className="fas fa-spinner fa-spin" /> loading QRCode</> : <img src={qrCode} style={{width: '180px'}} />
                                                }
                                                </div>
                                            }
                                            <br />
                                        </> :
                                        <Row>
                                            <Col>
                                                <img src={mark} style={{width: '120px'}} /><br />
                                                <strong>Device Connected.!</strong>
                                            </Col>
                                            <Col>
                                                <table className="table table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th>Session name</th>
                                                            <th>{postObject?.session}</th>
                                                        </tr>
                                                        <tr>
                                                            <th>Mobile Number</th>
                                                            <th>{postObject?.mobile}</th>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Col>
                                        </Row>
                                    }
                                </Col>
                            </Row>
                        
                            <Row className="mt-4">
                                <strong>Test Service</strong>
                                <Col>
                                    <div className="input-group">
                                        <input type="text" className="form-control" onChange={e => setTestNumber(e.target.value)} placeholder="Put Your phone Number where you will received a test message to your whatsapp"/>
                                        <button className="btn btn-primary" type="button" onClick={() => sendTestMsg()}>Test</button>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col>
                            <Row className="mb-2">
                                <Col>
                                    <h4 className="mt-2 mb-2">SMS Templates</h4>
                                </Col>
                                <Col>
                                    <button style={{float: 'right'}} className="btn btn-primary" onClick={() => setIsOpen(true)}> Add Template</button>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Template</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                templateData && templateData.map((v,i) =>{
                                                    return <tr key={`TD_${i}`}>
                                                        <td>{v.id}</td>
                                                        <td>{v.template}</td>
                                                        <td>{ <span dangerouslySetInnerHTML={{ __html: decodeHTML(v.description) }} />}</td>
                                                    </tr>
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Template isOpen={isOpen} callback={() => setIsOpen(false)} type={3}/>
        </Col>
    )
}

export default WPSettings;