import Breadcrumbs from "components/Common/Breadcrumb"
import Mdatatable from "components/Datatables/Mdatatables"
import { get } from "helpers/api_helper"
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import {
    Col,
    Container,
    Row
} from "reactstrap"

const isType = 'Due Collections'
const Payments = () => {
    const [dataRows, setDataRows] = useState([])

    const getData = async() => {
        const res = await get('http://localhost/lab_api/api/finance/duecollections');
        if(res.status == 200){
            setDataRows(res?.response)
        }
    }

    useEffect(() => getData(),[])

    /**
     * DataTable with Condition based row formating
     */
    let datatableData = [
        {
            accessorKey: 'id',
            header: 'Id'
        }, {
            accessorKey: 'invoice_number',
            header: 'Invoice Number'
        },{
            accessorKey: 'clientname',
            header: 'Client'
        },{
            accessorKey: 'invoice_date',
            header: 'Date'
        },{
            accessorKey: 'invoice_subtotal',
            header: 'Subtotal'
        },{
            accessorKey: 'discount',
            header: 'Discount'
        },{
            accessorKey: 'invoice_total',
            header: 'Grand Total'
        },{
            accessorKey: 'paid_amount',
            header: 'Paid Total'
        },{
            accessorKey: 'blc_amount',
            header: 'Due'
        }
    ];
    

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>{isType}| KS-Lab</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Masters"
                        breadcrumbItem={isType}
                    />

                    <Row>
                        <Col xl="12">
                            {
                                <Mdatatable
                                    // enableRowAction={isActionColumn}
                                    // userModules={isActions}
                                    title={`${isType}`}
                                    columns={datatableData}
                                    data={dataRows}
                                    // handleEdit={handleEdit}
                                    // handleRemove={handleRemove}
                                    // isEdit={isEdit}
                                    // handleAddButton={() => {
                                    //     setEditableObj(null)
                                    //     isOpen(true)
                                    // }}
                                />
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Payments
