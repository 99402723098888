import { get } from 'helpers/api_helper';
import { NumberToWordsConverter, customFormatDate, teethsTable } from 'helpers/basic_helper';
import React, { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import MetaTags from "react-meta-tags";
import {
    Col,
    Row
} from "reactstrap";
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';

const loadingStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)'
}

const OrderformPdfPrint = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const [printdata, setPrintdata] = React.useState(null);

    useEffect(async() => {
        const data = await get(`api/orderform/print/${urlParams.get('_orderform')}`);
        setPrintdata(data?.response)  
    }, [])

    if(printdata && printdata?.orderform?.orderform_number != urlParams.get('_orderform')) {
        window.close();
    }

    const printPage = () => {
        // savePdf();
        let printContents = document.getElementById('printablediv').innerHTML;
        document.body.innerHTML = printContents;
        window.print();
    }
    window.addEventListener('afterprint', () => { window.close() });

    // const  savePdf = () => {
    //     const input = document.getElementById('printablediv');

    //     // Get the computed style of the div to determine its actual dimensions
    //     const computedStyle = window.getComputedStyle(input);
    //     const divWidth = parseFloat(computedStyle.width); // Get the width in pixels
    //     const divHeight = parseFloat(computedStyle.height); // Get the height in pixels

    //     html2canvas(input, { width: divWidth, height: divHeight })
    //     .then((canvas) => {
    //         const imgData = canvas.toDataURL('image/png');
    //         const pdf = new jsPDF({
    //         orientation: 'portrait',
    //         unit: 'mm',
    //         format: 'a4'
    //         });
            
    //         const pdfWidth = pdf.internal.pageSize.getWidth();
    //         const pdfHeight = pdf.internal.pageSize.getHeight();
        
    //         const imgWidth = pdfWidth; // Adjust scale as needed (0.75 is just an example)
    //         const imgHeight = canvas.height * imgWidth / canvas.width;
        
    //         pdf.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
    //         pdf.save('download.pdf');
        
    //         const blob = pdf.output('blob');
    //         const formData = new FormData();
    //         formData.append('file', blob, 'download.pdf');     
  
    //       // Example: Sending the PDF to a server using fetch
    //       fetch('https://example.com/upload', {
    //         method: 'POST',
    //         body: formData
    //       })
    //       .then(response => {
    //         // Handle success
    //         console.log('PDF sent to server successfully.');
    //       })
    //       .catch(error => {
    //         // Handle error
    //         console.error('Error sending PDF to server:', error);
    //       });
    //     })
    //     .catch(error => {
    //       console.error('Error generating PDF:', error);
    //     });
    // }

    return (
        <React.Fragment>
            <div>
                <MetaTags> <title>Orderform | KS-Lab</title> </MetaTags>
                {
                    printdata !== null ?
                    <>
                        <div style={{width: '100%', textAlign:'center'}}>
                            <button onClick={() => printPage()} className='btn btn-primary' type="button" style={{margin: '26px auto', width: '120px', height: '60px'}}><i className='fas fa-print me-2'></i>Print</button>
                        </div>
                        <div id="printablediv">
                            <div className="print-container" style={{ margin:' 0 auto'}}>
                                <Row>
                                    <Col sm={2} >
                                        <img src="https://dglab.keepsmiles.in/api/uploads/241002/241002_print.png" style={{width: '92px', marginRight: '16px', float: 'left'}} />
                                    </Col>
                                    <Col sm={5}>
                                        <b>{printdata?.org?.title}</b>
                                        <address style={{width: '260px', marginBottom: '0'}}>{printdata?.org?.address}</address>
                                    </Col>
                                    <Col>
                                        <span>phone: {printdata?.org?.mobile}</span><br />
                                        <span>Email: {printdata?.org?.email}</span><br />
                                        <span>Web: {printdata?.org?.website.replace('https://', 'www.')}</span><br />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="text-center">
                                        <h3>ORDERFORM</h3>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <b>Dr. {printdata?.client?.clientname}</b>
                                        <address style={{width: '290px', marginBottom: '0'}}>{printdata?.client?.address}</address>
                                        <span>phone: <b>{printdata?.client?.phone}</b></span><br />
                                    </Col>
                                    <Col className='text-end'>
                                        <p>Doc #. <b>{printdata?.orderform?.orderform_number}</b> <br />
                                        Date <b>{customFormatDate(printdata?.orderform?.orderform_date, "DD/MM/YYYY")}</b></p>
                                    </Col>
                                </Row>
                                <br />
                                <Row style={{marginBottom: '-18px'}}>
                                    <Col>
                                        <div className="table-responsive">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style={{padding: '6px', width: "20px"}}>#</th>
                                                        <th style={{padding: '6px'}}>Order #</th>
                                                        <th style={{padding: '6px'}}>Order Date</th>
                                                        <th style={{padding: '6px'}}>Patient</th>
                                                        <th style={{padding: '6px'}}>Product</th>
                                                        <th style={{padding: '6px'}}>Teeth #</th>
                                                        <th style={{padding: '6px'}} className="text-end">units</th>
                                                        <th style={{padding: '6px', width: "60px"}} className="text-end">Rate/Item</th>
                                                        <th style={{padding: '6px'}} className="text-end">Total Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        printdata?.order.map((v, i) => {
                                                            return v.products.map((pro, pkey) => {
                                                                if(v.doctor){
                                                                    return <>
                                                                        <tr key={`PK_${pkey}`}>
                                                                            <td style={{padding: '6px'}}></td>
                                                                            <td colSpan={8}><strong>Requested by {v.doctor}</strong></td>
                                                                        </tr>
                                                                        <tr key={`PK_${pkey}`}>
                                                                            <td style={{padding: '6px'}}>{i + 1}</td>
                                                                            <td style={{padding: '6px'}}>{pro?.order_id}</td>
                                                                            <td style={{padding: '6px'}}>{customFormatDate(v?.order_date, 'DD MMM ,YY')}</td>
                                                                            <td style={{padding: '6px'}}>{v?.patient_name}</td>
                                                                            <td style={{padding: '6px'}}>{pro.product_id}</td>
                                                                            <td style={{padding: '0', width: '132px'}}>{teethsTable(JSON.parse(pro.teeth))}</td>
                                                                            <td className="text-end" style={{padding: '6px'}}>{pro.unit}</td>
                                                                            <td className="text-end" style={{padding: '6px'}}>{pro.unit_price}</td>
                                                                            <td className="text-end" style={{padding: '6px'}}>{parseFloat(pro.unit_price) * parseFloat(pro.unit)}</td>
                                                                        </tr>
                                                                    </>
                                                                }

                                                                return <tr key={`PK_${pkey}`}>
                                                                    <td style={{padding: '6px'}}>{i + 1}</td>
                                                                    <td style={{padding: '6px'}}>{pro?.order_id}</td>
                                                                    <td style={{padding: '6px'}}>{customFormatDate(v?.order_date, 'DD MMM ,YY')}</td>
                                                                    <td style={{padding: '6px'}}>{v?.patient_name}</td>
                                                                    <td style={{padding: '6px'}}>{pro.product_id}</td>
                                                                    <td style={{padding: '0', width: '132px'}}>{teethsTable(JSON.parse(pro.teeth))}</td>
                                                                    <td className="text-end" style={{padding: '6px'}}>{pro.unit}</td>
                                                                    <td className="text-end" style={{padding: '6px'}}>{pro.unit_price}</td>
                                                                    <td className="text-end" style={{padding: '6px'}}>{parseFloat(pro.unit_price) * parseFloat(pro.unit)}</td>
                                                                </tr>
                                                            })
                                                        })
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='pt-1'>
                                    <Col><strong>INR <NumberToWordsConverter number={printdata?.orderTotal} /></strong></Col>
                                    <Col className='text-end'>
                                        <span className="border-0 text-end p-0 pt-1"><b style={{marginRight: '8px'}}>Total</b><b className="m-0"> {printdata?.orderTotal.toLocaleString()}.00</b></span>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col>Delivery By <br />Delivery Boy </Col>
                                    <Col className='text-end'>Received By</Col>
                                    <Col className='text-end'>Authorised Signatory</Col>
                                </Row>
                            </div>
                        </div>
                    </> :
                    <div style={loadingStyle}>
                        <img src="https://dglab.keepsmiles.in/api/uploads/241002/241002_print.png" style={{width: '92px', marginRight: '16px', float: 'left'}} /><br /><br /><br /><br />
                        <span>Loading Data...</span>
                    </div>
                }
            </div>
        </React.Fragment>
    )
}

export default OrderformPdfPrint
