import {
    CREATE_CHALLAN,
    CREATE_CHALLAN_SUCCESS,
    PRINT_DATA,
    PRINT_DATA_SUCCESS
} from './actionTypes'

export const CreateChallan = data => ({
    type: CREATE_CHALLAN,
    payload: data
})

export const CreateChallanSuccess = data => ({
    type: CREATE_CHALLAN_SUCCESS,
    payload: data
})

export const printDataAction = data => {
    return {
        type: PRINT_DATA,
        payload: {data}
    }
}

export const printDataActionSuccess = data => {
    return {
        type: PRINT_DATA_SUCCESS,
        payload: data
    }
}

