import React, {useEffect, useState} from "react"
import { Col, Card, CardBody, Row } from "reactstrap"
import { DashboardReportTypes } from 'constants/layout'
import { get } from 'helpers/api_helper'

const TopProducts = () => {
    const [type, setType] = useState(DashboardReportTypes[0])
    const [data, setData] = useState(null)
  
    useEffect(async() => {
      setData(null)
      const res = await get(`api/org/topproducts/${type.value}`)
      if(res.status == 200){
        setData(res.response)
      }
    }, [type])
    
    return (
        <Row>
            <Col md="12">
                <Card>
                    <CardBody>
                        <Row className="mb-2">
                            <Col>
                                <h3>{type.title} Top Products</h3>
                            </Col>
                            <Col md="3">
                                <select onChange={(e) => setType(JSON.parse(e.target.value))} className="form-control" name="fmytd">
                                    {
                                        DashboardReportTypes?.map((v,i) => <option value={JSON.stringify(v)} key={`TPD_${i}${v.value}`}>{v.title}</option>)
                                    }
                                </select>
                            </Col>
                        </Row>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Product Code</th>
                                    <th>Product</th>
                                    <th>Product type</th>
                                    <th>Product Category</th>
                                    <th>Total Units</th>
                                    <th>Total Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {   
                                    data?.map((v,i) =>
                                        <tr key={i}>
                                            <td>{ i + 1 }</td>
                                            <td>{v.product_id}</td>
                                            <td>{v.title}</td>
                                            <td>{v.type}</td>
                                            <td>{v.category}</td>
                                            <td>{v.units}</td>
                                            <td>{v.total}</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default TopProducts