export const GET_ALL_ROWS = 'GET_ALL_ROWS'
export const GET_SINGLE_ROW = 'GET_SINGLE_ROW'
export const GET_ALL_ROWS_SUCCESS = 'GET_ALL_ROWS_SUCCESS'
export const ADD_RECORD = 'ADD_RECORD'
export const ADD_RECORD_SUCCESS = 'ADD_RECORD_SUCCESS'
export const REMOVE_RECORD = 'REMOVE_RECORD'
export const EDIT_RECORD = 'EDIT_RECORD'
export const UPDATE_RECORD = 'UPDATE_RECORD'
export const PUSH_NEW_RECORD = 'PUSH_NEW_RECORD'
// Load Clients Options
export const CLIENT_OPTIONS = "CLIENT_OPTIONS"
export const CLIENT_OPTIONS_SUCCESS = "CLIENT_OPTIONS_SUCCESS"
export const MASTER_OPTIONS = "MASTER_OPTIONS"
export const MASTER_OPTIONS_SUCCESS = "MASTER_OPTIONS_SUCCESS"
export const PRODUCT_OPTIONS = 'PRODUCT_OPTIONS'
export const PRODUCT_OPTIONS_SUCCESS = 'PRODUCT_OPTIONS_SUCCESS'
export const PRODUCT_STATUS_UPDATE = 'PRODUCT_STATUS_UPDATE'
