import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import {
    Col,
    Container,
    Row
} from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

//i18n
import { withTranslation } from "react-i18next";

import Datatable from "components/Datatables/datatable";
import { useDispatch, useSelector } from 'react-redux';

import { COMPANY_URL } from "helpers/url_helper";

import FormComponent from "../FormComponent";

import { addRecord, getAllRows, removeRecord } from 'store/actions';

const isType = 'company'

const Company = props => {
    const dispatch = useDispatch()
    const { loading, companies } = useSelector(state => state.Master);
    const [open, isOpen] = useState(false)
    const [editableObj, setEditableObj] = useState(null)
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [isDelete, setIsDelete] = useState(false)

    useEffect(() => {
        setDataRows(curr => companies)
    },[companies])

    useEffect(() => {
        dispatch(getAllRows({url:COMPANY_URL, isSingle: false, isType: isType}, props.history))
    },[])
    
    const handleAddButton = () => {
        setEditableObj(null)
        isOpen(true)
    }

    const addupdate_callback = () => {
        isOpen(false)
    }

    // Open RightSide Panel
    const submitForm = (data) => {
        const Obj = {
            "id": data.target.id.value || null,
            "title": data.target.title.value,
            "mobile": data.target.mobile.value,
            "tel": data.target.tel.value,
            "email": data.target.email.value,
            "fax": "",
            "pan": data.target.pan.value,
            "gst": data.target.gst.value,
            "cin": data.target.cin.value,
            "website": data.target.website.value,
            "address": data.target.address.value
        }
        
        dispatch(addRecord({isType: isType, Obj:Obj, url: COMPANY_URL+`${data?.target?.id?.value ? '?q='+data?.target?.id?.value : '' }`, id: data?.target?.id?.value}, props.history, addupdate_callback))
    }

    // Update Data , Get Singlw ROw using url params ?q=?
    const handleEdit = data => {
        const cb = (data) => {
            getData()
            setEditableObj(data)
            isOpen(true)
            setIsLoading(false)
        }
    
        if(data > 0){
            setEditableObj({ id: data })
            setIsLoading(true)
            dispatch(getAllRows({url:`${COMPANY_URL}?q=${data}`, isSingle: true}, props.history, cb))
        }
    }

    // Remove Data
    const handleRemove = (data, status) => {
        const cb = (v) => {
            setIsDelete(false)
        }
        if(data > 0){
            setIsDelete(true)
            setEditableObj({ id: data })
            dispatch(removeRecord({url:`${COMPANY_URL}?q=${data}&status=${status}`, data: {id:data, status:status}, isType: isType}, cb))
        }
    }

    // table Column Data
    let company_columns = [
        {
            dataField: 'sr',
            text: 'Id',
            sort: true,
            sortFunc: (a, b, order, dataField) => {
                if (order === 'asc') return b - a;
                return a - b;
            }
        }, {
            dataField: 'title',
            text: 'Company',
            sort: true
        }, {
            dataField: 'mobile',
            text: 'Mobile',
            sort: true
        }, {
            dataField: 'tel',
            text: 'Telephone',
            sort: true
        }, {
            dataField: 'email',
            text: 'Email',
            sort: true
        }, {
            dataField: 'website',
            text: 'Website',
            sort: true
        }, {
            dataField: 'address',
            formatter: (cell, row) => {
                return <p className="addText" title={row.address}>{row.address}</p>
            },
            text: 'Address'
        }, {
            dataField: 'actions',
            text: "Actions",
            isDummyField: true,
            formatExtraData: [isLoading, isDelete],
            style: { width: '10em' },
            formatter: (cell, row,  rowIndex, extraData) => {
                return <>
                    <Link
                    to="#"
                    className="btn btn-outline-secondary btn-sm edit"
                    onClick={() => handleEdit(row.id)} 
                    title="Edit" >
                        <i className={`fas ${isLoading && editableObj.id == row.id ? 'fa-spinner fa-spin' : 'fa-pencil-alt'}`} />
                    </Link>
                    {" "}
                    <Link
                    to="#"
                    className={`btn btn-outline-${row.status == 0 ? "danger" : "success"} btn-sm edit`}
                    onClick={() => handleRemove(row.id, row.status == 1? 0 : 1)} 
                    title={row.status == 0 ?'De-active' : 'Active'} >
                        <i className={`fas ${isDelete && editableObj.id == row.id ? 'fa-spinner fa-spin' : (`fas fa-${row.          status == 0 ? 'trash-alt' : 'check'}`)}`} />
                    </Link>
                </>
            }
        }
    ];
    
    // formFileds
    let formFileds = [
        {
            name: "id",
            required: false,
            type: 'hidden',
            value: editableObj?editableObj.id : '',
            col:12
        },{
            name: "title",
            label: "Company Name",
            placeholder: "Company Name",
            required: true,
            type: 'text',
            value: editableObj?editableObj.title : '',
            col:12
        },{
            name: "mobile",
            label: "MobileNumber",
            placeholder: "MobileNumber",
            required: true,
            type: 'text',
            value: editableObj?editableObj.mobile:'',
            col: 6
        },{
            name: "tel",
            label: "TelephoneNumber",
            placeholder: "TelephoneNumber",
            required: false,
            type: 'text',
            value: editableObj?editableObj.tel:'',
            col: 6
        },{
            name: "email",
            label: "Email",
            placeholder: "Email Address",
            required: true,
            type: 'text',
            value: editableObj?editableObj.email:'',
            col: 6
        },{
            name: "pan",
            label: "Pancard Number",
            placeholder: "PanCard Number",
            required: false,
            type: 'text',
            value: editableObj?editableObj.pan:'',
            col: 6
        },{
            name: "gst",
            label: "GST Number",
            placeholder: "GST Number",
            required: false,
            type: 'text',
            value: editableObj?editableObj.gst:'',
            col: 6
        },{
            name: "cin",
            label: "CIN Number",
            placeholder: "CIN Number",
            required: false,
            type: 'text',
            value: editableObj?editableObj.cin:'',
            col: 6
        },{
            name: "website",
            label: "Website URL",
            placeholder: "Website URL",
            required: false,
            type: 'text',
            value: editableObj?editableObj.website:'',
            col: 6
        },{
            name: "address",
            label: "Address",
            placeholder: "Address",
            required: true,
            type: 'textarea',
            value: editableObj?editableObj.address:'',
            col: 12
        }
    
    ]
    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags>
                    <title>Dashboard | {isType}</title>
                </MetaTags>
                <Container fluid>
                    {/* Render Breadcrumb */}
                    <Breadcrumbs
                        title="Masters"
                        breadcrumbItem="Company"
                    />

                    <Row>
                        <Col xl="12">
                            <Datatable
                                module="masters"
                                subModule="company"
                                title={`All ${isType}`}
                                handleAddButton={handleAddButton}
                                columns={company_columns}
                                rows={dataRows}
                                defaultSort={{ dataField: 'sr', order: 'asc' }}
                                keyField="sr"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            
            <FormComponent
                title={`${editableObj ? 'Edit' : 'Add'} Company`}
                Position={"right"}
                isOpen={open}
                fileds={formFileds}
                loading={loading}
                editableObj={editableObj}
                onDrawerClose={(e) => {
                    isOpen(e => false)
                    setEditableObj(e => [])
                }}
                submitForm={submitForm}
            />

        </React.Fragment>
    )
}

Company.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Company)
