import { convertToHTML } from 'draft-convert';
import { post } from 'helpers/api_helper';
import { TEMPLATE_URL } from "helpers/url_helper";
import { useEffect, useState } from "react";
import { Editor } from 'react-draft-wysiwyg';
import {
    Col,
    Modal,
    Row
} from "reactstrap";
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const Template = ({isOpen = false, type=1, callback}) => {
    const [templateData, setTemplateData] = useState({
        title: null,
        subject: null,
        description: null,
        type: type
    });
    const [editorData, setEditorData] = useState(null)

    useEffect(() => {
        setTemplateData(prev => ({
            ...prev,
            description: editorData ? convertToHTML(editorData?.getCurrentContent()) : ""
        }))
    }, [editorData])

    const saveData = async () => {
        const res = await post(TEMPLATE_URL, templateData);
        if(res.status == 200){}
    }

    return (
        <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => callback()}
        centered={true}
        >
            <div className="modal-body">
                <div className="form-group">
                    <Row>
                        <Col>
                            <label>Template Title</label>
                            <input type="text" className="form-control" onChange={e => setTemplateData(prev => ({...prev, title: e.target.value}))} />
                        </Col>
                    </Row>
                    {
                        type === 1 && 
                        <Row className="mt-4">
                            <Col>
                                <label>Subject</label>
                                <input type="text" className="form-control" onChange={e => setTemplateData(prev => ({...prev, subject: e.target.value}))} />
                            </Col>
                        </Row>
                    }
                    <Row className="mt-4">
                        <Col>
                            <label>Template Body</label>
                            <Editor 
                                toolbarHidden={type !== 1 ? true : false}
                                onEditorStateChange={setEditorData} 
                                wrapperClassName="wrapper-class"
                                editorClassName="editor-class"
                                toolbarClassName="toolbar-class"
                            />
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="modal-footer">
                <button 
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => callback()} >Close</button>
                <button 
                    type="button"
                    className={`btn btn-success`}
                    onClick={() => saveData()} >Save</button>
            </div>
        </Modal>

    )
}

export default Template