import PropTypes from "prop-types"
import React from "react"
import MetaTags from "react-meta-tags"
import { Col, Container, Row } from "reactstrap"

//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb"

// Pages Components
import TopProducts from "modules/components/top-products"
import TopViews from "modules/components/top-views"
import CardUser from '../components/card-user'
import FMYTDWidget from '../components/fmytd'
import MiniWidget from '../components/mini-widget'
import ChartJsCard from "./charts/chart"

//i18n
import { withTranslation } from "react-i18next"

const Dashboard = () => {
  const isAdmin = JSON.parse(localStorage.getItem('authUser'))

  return (
    <React.Fragment>
    <div className="page-content p-t-2">
        <MetaTags><title>D-Lab | Dashboard</title></MetaTags>
        
        <Container fluid>
          {/* Render Breadcrumb */}
          <Breadcrumbs title="Dashboards" breadcrumbItem="Dashboards" />

          {/* Card User */}
          <CardUser />

          {
            isAdmin.user_role != 'owner' &&
            <>
              <Row>
                <Col md="12">
                  <MiniWidget />
                </Col>
                <Col md="4">
                  {/*mimi widgets */}
                  <FMYTDWidget />
                  <TopViews />
                </Col>

                {/* Chart js */}
                <Col>
                  <ChartJsCard type="Order" />
                  <ChartJsCard type="Invoice" />
                </Col>
              </Row>
              <Row>
                <Col md="12">
                  <TopProducts />
                </Col>
              </Row>
            </>
          }

        </Container>
    </div>
    </React.Fragment>
  )
}

Dashboard.propTypes = {
  t: PropTypes.any,
  chartsData: PropTypes.any,
  onGetChartsData: PropTypes.func,
}

export default withTranslation()(Dashboard)
