import { get, post } from 'helpers/api_helper'
import { PROCESS_URL } from 'helpers/url_helper'
import React, { useEffect, useState } from "react"
import MetaTags from "react-meta-tags"
import BarcodeScannerComponent from "react-qr-barcode-scanner"
import { Link } from 'react-router-dom'
import {
    Card,
    CardBody,
    Col,
    Container,
    Row
} from "reactstrap"
const isType = 'processing'

const errStyleObj = {
    width: '78%',
    background: 'red',
    marginLeft: '5px',
    color: '#fff',
    padding: '0px 8px',
    borderBottomLeftRadius: '10px',
    borderBottomRightRadius: '10px'
}

const Processing = () => {
    const [dataRows, setDataRows] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState(false)
    const [isStream, setIsStream] = useState(true)
    const [dashboard, setDashboard] = useState(null)
    const [data, setData] = useState(null);
    const [dashboardData, setDashboardData] = useState(null);
    const [inputData, setinputData] = useState('');

    /**
     * Get All Rows
     * @param {row_id} ID
     * @method {get} GET
     */
    const getData = async() => {
        setIsLoading(true)

        const res = await get(`${PROCESS_URL}/departments`)
        if(res.status == 200) {
            setDataRows(res?.response)
        }
        setIsLoading(false)
        if(res === undefined){
            setError(true)
        }
    }

    useEffect(() => getData(),[])

    useEffect(() => {
        setIsStream(false)
    }, data)

    const handleDepartmentpageData = async(department) => {
        if(department){
            setDashboard(department);
            const res = await get(`${PROCESS_URL}/getAllcases/${department?.code}`);
            if(res.status == 200) {
                setDashboardData(res.response);
            }
        }
    }

    useEffect(() => {
        if(dashboard?.code){
            const intervalId = setInterval(() => {
                handleDepartmentpageData(dashboard);
            }, 15000); // 10000 milliseconds = 10 seconds
            return () => clearInterval(intervalId);
        }
    }, [dashboard]);

    const handleSubmit = async() => {
        const obj = {
            "case": inputData,
            "department": dashboard.code
        }

        const res = await post(`${PROCESS_URL}/addcase`, obj);
        if(res.message === 'invalid case number.!'){
            setError(true)
        }

        if(res.status == 200){
            setError(false)
            setInputData('')
        }
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <MetaTags> <title>Dashboard | {isType}</title> </MetaTags>
                <header id="page-topbar" style={{left: '0px'}}>
                    <div className="navbar-header">
                        <div className="d-flex">
                            <h1 style={{ marginLeft: '18px' }}>{dashboard?.title}</h1>
                        </div>
                        <div className="d-flex">
                            <div className="dropdown d-inline-block">
                                <Link type="button" style={{top: '15px'}} className="btn header-item noti-icon"><i className="bx bx-log-out"></i></Link>
                            </div>
                        </div>
                    </div>
                </header>
                <Container fluid>
                    {
                        !dashboard && 
                        <Row>
                            {
                                dataRows.length && dataRows.map((v, i) =>
                                    <div key={`LP_${i}`} className="col-md-3 col-sm-12 col-lg-3">
                                        <div className="mini-stats-wid card">
                                            <div className="card-body">
                                                <div className="d-flex">
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <p className="text-muted fw-medium" style={{fontSize: '18px'}}>{v.title}</p>
                                                            <button type="button" className="btn btn-dark" style={{position: 'absolute', top: '10px', right: '10px'}}>{v.counts?.total} </button>
                                                        </div>

                                                        <div>
                                                            <button type="button" className="btn-label btn btn-warning" style={{width: '49%'}}>
                                                                {v.counts?.pending} <i className="bx bx-loader label-icon" style={{ border:'none'}}></i>
                                                            </button> {' '}
                                                            <button type="button" className="btn-label btn btn-success" style={{width: '49%'}}>
                                                                {v.counts?.done} <i className="bx bx-check-double  label-icon" style={{backgroundColor: 'none'}}></i>
                                                            </button>
                                                        </div>
                                                        <button onClick={()=> handleDepartmentpageData(v)} type="button" className="btn btn-dark mt-3" style={{width: '100%'}}>
                                                            GoTo Dashboard <i className="bx bx-right-arrow label-icon" style={{backgroundColor: 'none'}}></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </Row>
                    }
                    
                    {
                        dashboard && 
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <div className="h5 mb-2 card-title">Please Enter CaseNumber</div>
                                        <div className="hstack gap-3">
                                            <input value={inputData } onChange={(e) => setinputData(e.target.value)} placeholder="Please Enter CaseNumber..." aria-label="Please Enter CaseNumber..." type="text" className="form-control me-auto" />
                                            <button type="button" className="btn btn-secondary" onClick={handleSubmit}>Submit</button>
                                            <div className="vr"></div>
                                            <button type="button" className="btn btn-outline-danger">Reset</button>
                                        </div>
                                        {
                                            error && <div style={errStyleObj}>Invalid case Number</div>
                                        }
                                    {
                                        // isStream && 
                                        <BarcodeScannerComponent
                                            onUpdate={(err, result) => {
                                                if (result) setData(result.text);
                                                else setData(null);
                                            }}
                                        />
                                    }
                                    </CardBody>
                                </Card>

                                </Col>
                            <Col>
                            
                                <Card>
                                    <CardBody>
                                        <table className='table table-bordered'>
                                            <thead>
                                                <tr>
                                                    <th>#</th>
                                                    <th>Case Number</th>
                                                    <th>In Time</th>
                                                    <th>Out Time</th>
                                                    <th>Total Time</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    dashboardData?.map((v, i) => 
                                                        <tr key={`LPR_${i}`}>
                                                            <td>{i+1}</td>
                                                            <td>{v.tryno}</td>
                                                            <td>{v.in_datetime}</td>
                                                            <td style={{ background:`${!v.out_datetime ? 'red' : '' }`}}>{v.out_datetime}</td>
                                                            <td>{v.time_difference}</td>
                                                        </tr>
                                                    )
                                                }
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    }
                </Container>
            </div>
        </React.Fragment>
    )
}

export default Processing
