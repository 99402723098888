import moment from "moment";
import { toWords } from "number-to-words";
import { decryptData } from "services/encription_service";
import numberToWords from 'helpers/numtowords'

export const getDate = (value) => moment(value).format(process.env.REACT_APP_DATEFORMAT);
export const customFormatDate = (value, format) => moment(value).format(format);

export const getCode = (prifix, maxCount) => {
    const codes = {
        "department": "DEP",
        "designation": "DES",
        "labdepartment": "LAB",
        "country": "CNT",
        "states": "STA",
        "cities": "CIT",
        "stations": "STN",
        "zones": "ZON",
        "source": "SRC",
        "qualification": "QUA",
        "currency": "CUR",
        "correction_template": "COR",
        "role": "ROL",
        "location": "LOC",
        "shadeguide": "SHG",
        "shade": "SHD",
        "productcategory": "PRC",
        "warranty": "WAR",
        "productgroup": "PRG",
        "producttype": "PRT",
        "parent_client": "PAR",
        "client_category": "CLI",
        "clients": "CLT",
        "employee": "EMP",
        "products": "PRO"
    };

    return `${codes[prifix]}${(maxCount+1).toString().padStart(5, '0')}`
}

export const NumberToWordsConverter = ({ number }) => {
    return numberToWords(number);
    const words = toWords(number).replace(/[, -]/g, " ");
    const capitalizedWords = words.charAt(0).toUpperCase() + words.slice(1);
    return capitalizedWords;
};

export const userModuleData = async(module, subModule) => {
    const state = JSON.parse(localStorage.getItem('authUser'))
    if(state.user_role == 'master'){
        return {
            edit: true,
            delete: true,
        }
    }else{
        const modules = await decryptData(localStorage.getItem('userdetails'))
        const MD = JSON.parse(JSON.parse(modules))
        return MD?.[module]?.[subModule]
    }
}

export const userRole = () => {
    const state = JSON.parse(localStorage.getItem('authUser'))
    return state.role;
}
    
export const teethsTable = (originalArray) => {
    // const originalArray = [11,12,13,14,25,26,27,42,43,47,48];
    
    const getArrayForRange = (start, end) => {
        return originalArray.filter(value => {
            const intValue = parseInt(value);
            return intValue >= start && intValue <= end;
        }).sort((a, b) => parseInt(a) - parseInt(b));
    };

    const array1 = getArrayForRange(11, 18);
    const array2 = getArrayForRange(21, 28);
    const array4 = getArrayForRange(31, 38);
    const array3 = getArrayForRange(41, 48);

    return (
        <table style={{ borderCollapse: 'collapse', width:'100%', textAlign: 'center' }}>
            <tr style={{borderBottom: '1px solid #000'}}>
                <td style={{ height:'16px', fontSize: '10px', border: 'none', width: '49%', textAlign: 'right', borderRight: '1px solid #000', paddingRight: '2px' }}>{array1.reverse().join(',')} </td>
                <td style={{ height:'16px', fontSize: '10px', border: 'none', paddingLeft: '5px', textAlign: 'left' }}>{array2.join(',')} </td>
            </tr>
            <tr>
                <td style={{ height:'16px', fontSize: '10px', minHeight: '50%', border: 'none', width: '49%', textAlign: 'right', borderRight: '1px solid #000', paddingRight: '2px' }}>{array3.reverse().join(',')} </td>
                <td style={{ height:'16px', fontSize: '10px', border: 'none', paddingLeft: '5px', textAlign: 'left' }}>{array4.join(',')} </td>
            </tr>
        </table>
    );
};

export const currencyConvert = (value) => {
    return value.toLocaleString('en-IN', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    });
}